import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";
import WORK_ORDER_TYPE from "constants/work-order-type";

import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const PageViewOvertime = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", tab: "EMPLOYEE_DIRECTORY" });
	const [data, setData] = useState(convertPaginationTableData());

	const formik = useFormik({
		initialValues: {
			employeeName: "",
			employeeId: "",
			status: "",
			startDate: "",
			endDate: ""
		},
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Human Resources", path: pathnames.humanResources.overtimeManagement },
			{ label: "Overtime Management", path: pathnames.humanResources.overtimeManagement + "?tab=EMPLOYEE_DIRECTORY" },
			{ label: `${formik.values.employeeName}`, path: pathnames.humanResources.viewOvertime + id }
		];

		return data;
	}, [formik.values, id]);

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	useBreadcrumb({ breadCrumb });

	// prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		const params = { ...queryParams.current, size: 10, "employee-id": uniqueId };
		
		if (formik.values.startDate) {
			params["startDate"] = dayjs(formik.values.startDate).format(DATE_TIME.YYYY_MM_DD);
		}
		
		if (formik.values.endDate) {
			params["endDate"] = dayjs(formik.values.endDate).format(DATE_TIME.YYYY_MM_DD);
		}
		
		memoSearchParams.current(params);

		try {
			response = await api.get.overtime.getWorkOrderOvertime(params);
        } catch (error) {
            serveLayoutRequestErrors(error);
        }
 
        if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
        }
    }, [formik.values.startDate, formik.values.endDate]);

	const onHandleGetAccumulatedOvertime = useCallback(async (uniqueId) => {
		let response = null;

		const params = { "employee-id": uniqueId };

		try {
			response = await api.get.overtime.getAccumulatedOvertime(params);
		} catch (error) {
            serveLayoutRequestErrors(error);
        }

		if (response) {
			memoSetFormValues({
				employeeId: response?.employeeId,
				employeeName: response?.fullName,
				accumulatedOvertime: response?.otText
			})
		}
	}, [memoSetFormValues]);

	// prettier-ignore
	const onHandleViewOvertime = useCallback((obj) => {
		switch (data.content[obj.rowIndex].workOrderType) {
			case WORK_ORDER_TYPE.PREVENTIVE:
				navigate(pathnames.workOrder.workOrderCreateEdit + WORK_ORDER_TYPE.PREVENTIVE + "/" + data.content[obj.rowIndex].workOrderId);
				break;
			case WORK_ORDER_TYPE.CORRECTIVE:
				navigate(pathnames.workOrder.workOrderCreateEdit + WORK_ORDER_TYPE.CORRECTIVE + "/" + data.content[obj.rowIndex].workOrderId);
				break;
			case WORK_ORDER_TYPE.BREAKDOWN:
				navigate(pathnames.workOrder.workOrderCreateEdit + WORK_ORDER_TYPE.BREAKDOWN + "/" + data.content[obj.rowIndex].workOrderId);
				break;
			case WORK_ORDER_TYPE.PROJECT:
				navigate(pathnames.workOrder.workOrderCreateEdit + WORK_ORDER_TYPE.PROJECT + "/" + data.content[obj.rowIndex].workOrderId);
				break;
			default:
				break;
		}
	}, [data, navigate]);

	// prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetDetails(id);
	}, [onHandleGetDetails, id]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["startDate"] = "";
		queryParams.current["endDate"] = "";

		onHandleGetDetails(id);
	}, [formik, id, onHandleGetDetails]);

	const onHandleSetLastFilter = useCallback(() => {}, []);

	// prettier-ignore
	const handleDateChange = useCallback((name, value) => {
		formik.setFieldValue(name, value);
	}, [formik]);

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "referenceNo",
			label: "Work Order ID",
			options: {
				sort: false
			}
		},
		{
			name: "overallJobDescription",
			label: "Overall Job Description",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => {
					if (value) {
						 return dayjs(value).format(DATE_TIME.CREATED_BY_DATE)
					} else {
						return "-"
					}
				}
			}
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => {
					if (value) {
						 return dayjs(value).format(DATE_TIME.CREATED_BY_DATE)
					} else {
						return "-"
					}
				}
			}
		},
		{
			name: "startDate",
			label: "Start Time",
			options: {
				sort: false,
				sortThirdClickReset: true,
				customBodyRender: (value) => {
					if (value) {
						return dayjs(value).format(DATE_TIME.HH_MM_A);
					} else {
						return "-"
					}
				}
			}
		},
		{
			name: "endDate",
			label: "End Time",
			options: {
				sort: false,
				sortThirdClickReset: true,
				customBodyRender: (value) => {
					if (value) {
						return dayjs(value).format(DATE_TIME.HH_MM_A);
					} else {
						return "-"
					}
				}
			}
		},
		{
			name: "otText",
			label: "Overtime",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleViewOvertime(tableMeta)}>
							<img src={chevronIcon} alt="expand-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleViewOvertime]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetDetails(id);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetDetails(id);
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetDetails, id]);

	const onHandleExportExcel = useCallback(async () => {
		let response = null;
		let fileName = "";
		let contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		let startDate = null;
		let endDate = null;

		if (formik.values.startDate) {
			startDate = dayjs(formik.values.startDate).format(DATE_TIME.YYYY_MM_DD);
		}
		
		if (formik.values.endDate) {
			endDate = dayjs(formik.values.endDate).format(DATE_TIME.YYYY_MM_DD);
		}
	
		try {
			const payload = { "employee-id": id };
        
       		if (startDate) {
       		    payload.startDate = startDate;
       		}
        
        	if (endDate) {
        	    payload.endDate = endDate;
        	}
	
			const transformResponse = (data, headers) => {
				const contentDisposition = headers?.["content-disposition"];
				if (contentDisposition) {
					const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
					if (filenameMatch && filenameMatch[1]) {
						fileName = filenameMatch[1].replace(/['"]/g, '');
					}
				}
				
				contentType = headers?.["content-type"] || contentType;
				
				return data;
			};
	
			response = await api.post.overtime.excelWorkOrderOverTime(payload, { responseType: "arraybuffer", transformResponse });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}
	
		if (response) {
			if (!fileName) {
				fileName = "work_order_overtime_report.xlsx";
			} else if (!fileName.endsWith('.xlsx') && !fileName.endsWith('.xls')) {
				fileName += '.xlsx';
			}
	
			const a = document.createElement("a");
			document.body.appendChild(a);
			
			const blob = new Blob([response], { type: contentType });
			const url = window.URL.createObjectURL(blob);
			
			a.href = url;
			a.download = fileName;
			a.click();
	
			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	}, [id, formik.values.startDate, formik.values.endDate]);

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current["startDate"] = dayjs(values["startDate"]).isValid() ? dayjs(values["startDate"]).format("YYYY-MM-DD") : "";
		queryParams.current["endDate"] = dayjs(values["endDate"]).isValid() ? dayjs(values["endDate"]).format("YYYY-MM-DD") : "";

		onHandleGetDetails(id);
	}, [onHandleGetDetails, id]);

	useEffect(() => {
		onHandleGetDetails(id);

		onHandleGetAccumulatedOvertime(id);
	}, [id, onHandleGetDetails, onHandleGetAccumulatedOvertime]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.OVERTIME.GET_WORK_ORDER_OVERTIME);

			cancelRequest(ENDPOINT_PATH.OVERTIME.GET_ACCUMULATED_OVERTIME);
		};
	}, [cancelRequest]);

	return (
		<div className="page-view-overtime">
			<div className="view-overtime">
				<div className="view-overtime__header-container">
					<div className="view-overtime__header">
						<h1 className="view-overtime__title">{formik.values.employeeName}</h1>
						<h1 className="view-overtime__id">{formik.values.employeeId}</h1>
					</div>

					<div className="view-overtime__header--right">
						<h1 className="view-overtime__title">Accumulated Overtime</h1>
						<h1 className="view-overtime__time">{formik.values.accumulatedOvertime}</h1>
					</div>
				</div>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Overall Job Description" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleExport={onHandleExportExcel} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="view-overtime-filter">
						<div className="view-overtime-filter__date-inputs">
							<AppInputDate name="startDate" label="Start Date" value={formik.values.startDate} onChange={handleDateChange} placeholder="Start date" />

							<AppInputDate name="endDate" label="End Date" value={formik.values.endDate} onChange={handleDateChange} placeholder="End date" />
						</div>

						<p className="view-overtime-filter__instruction">Please select a date or a date range of up to 90 days.</p>
					</div>
				</AppTableFilterHeader>

				<div className="view-claims__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>
		</div>
	);
};

export default PageViewOvertime;
