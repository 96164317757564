import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import ROLES from "constants/roles";

import AppTabs from "components/app-tabs";
import AppOTRateView from "components/pages/human-resources/app-ot-rate-view";
import AppOvertimeManagementEmployeeDirectoryTable from "components/pages/human-resources/app-overtime-management-employee-directory-table";

import pencilIcon from "assets/images/pages/human-resources/pencil-signature-icon.svg";
import employeeDirectoryIcon from "assets/images/pages/human-resources/employee-directory-icon.svg";
import pencilActiveIcon from "assets/images/pages/human-resources/pencil-signature-active-icon.svg";
import employeeDirectoryActiveIcon from "assets/images/pages/human-resources/employee-directory-active-icon.svg";

const PageOvertimeManagement = () => {
	const profile = useSelector((state) => state.profile);

	//prettier-ignore
	const overtimeManagementTabs = useMemo(() => [
		{ label: "Employee Directory", icon: employeeDirectoryIcon, activeIcon: employeeDirectoryActiveIcon, name: "EMPLOYEE_DIRECTORY", component: <AppOvertimeManagementEmployeeDirectoryTable />, accessible: profile?.permissions?.[ROLES.CLAIM_TYPE_CONFIG]?.view },
		{ label: "OT Rate", icon: pencilIcon, activeIcon: pencilActiveIcon, name: "OT_RATE", component: <AppOTRateView />, accessible: profile?.permissions?.[ROLES.CLAIM_TYPE_CONFIG]?.view },
	], [profile?.permissions]);

	return (
		<div className="page-overtime-management">
			<div className="overtime-management">
				<div className="overtime-management__header">
					<h1 className="overtime-management__title">Overtime Management</h1>
				</div>

				<div className="overtime-management__body">
					<AppTabs tabs={overtimeManagementTabs} />
				</div>
			</div>
		</div>
	);
};

export default PageOvertimeManagement;
