import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import sanitizeObject from "common/sanitize-object";
import capitalizeCharacter from "common/capitalize-character";
import convertSortingQuery from "common/convert-sorting-query";
import { formatCurrency } from "common/format-currency-pattern";
import convertPaginationTableData from "common/convert-pagination-table-data";
import serveRequestErrors, { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppMobileInput from "components/app-mobile-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppDeliveryOrdersClosedModal from "components/pages/sales/app-delivery-orders-closed-modal";

import exportIcon from "assets/images/export-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const PageDeliveryOrdersViewEdit = () => {
	const { id } = useParams();
	const deliveryOrderClosedRef = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.DELIVERY_ORDER], [profile]);
	const restricted = useMemo(() => accessible?.update, [accessible]);
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const queryParams = useRef({ page: 0 });
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const queryLineParams = useRef({ page: 0, size: 10 });
	const [linesTableData, setLinesTableData] = useState(convertPaginationTableData());
	const [rowsExpanded, setRowsExpanded] = useState([]);

	const initialValues = useMemo(() => {
		const values = {
			city: "",
			title: "",
			state: "",
			status: "",
			picName: "",
			picEmail: "",
			quantity: "",
			postcode: "",
			taxAmount: "",
			taxPercent: "",
			totalAmount: "",
			referenceNo: "",
			customerNote: "",
			customerName: "",
			addressLine1: "",
			addressLine2: "",
			internalNote: "",
			discountAmount: "",
			subtotalAmount: "",
			lastModifiedBy: "",
			lastModifiedDate: "",
			saleOrderReferenceNo: "",
			picPrefixMobileNumber: "",
			picPostfixMobileNumber: "",
			items: []
		};

		return values;
	}, []);

	const validationSchema = yup.object({
		title: yup.string().required(ERRORS.REQUIRED)
	});

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	//prettier-ignore
	const onHandleGetDeliveryOrderItem = useCallback(async (uniqueId) => {
		let response = null;

		const params = sanitizeObject({ ...queryLineParams.current, "item-id": uniqueId, type: "ID" });

		try {
			response = await api.get.deliveryOrders.itemListing(params);
		} catch(error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setLinesTableData(obj);

			return response?.content || [];
		}
	}, []);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.deliveryOrders.deliveryOrder(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const lineItems = await onHandleGetDeliveryOrderItem(uniqueId);

			memoSetFormValues({
				addressLine1: response.addressLine1,
				addressLine2: response.addressLine2,
				city: response.city,
				customerName: response.customerName,
				customerNote: response.customerNote,
				id: response.id,
				internalNote: response.internalNote,
				picEmail: response.picEmail,
				picName: response.picName,
				picPostfixMobileNumber: response.picPostfixMobileNumber,
				picPrefixMobileNumber: response.picPrefixMobileNumber,
				postcode: response.postcode,
				referenceNo: response.referenceNo,
				saleOrderId: response.saleOrderId,
				saleOrderReferenceNo: response.saleOrderReferenceNo,
				state: response.state,
				status: response.status,
				title: response.title,
				lineItems: lineItems,
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE),
			});
		}
	}, [memoSetFormValues, onHandleGetDeliveryOrderItem]);

	const isEdit = useMemo(() => formik.values.status === STATUS.DRAFT, [formik.values.status]);
	const isClosed = useMemo(() => formik.values.status === STATUS.COMPLETED || formik.values.status === STATUS.CANCELLED, [formik.values.status]);
	const title = useMemo(() => (isEdit ? "Edit Delivery Order" : "View Delivery Order"), [isEdit]);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Sales", path: pathnames.sales.quotations },
			{ label: "Delivery Orders", path: pathnames.sales.deliveryOrders }
		];

		if (isEdit) {
			data.push({ label: "Edit Delivery Order " + formik.values.referenceNo, path: pathnames.sales.deliveryOrdersViewEdit + id });
		} else {
			data.push({ label: "View Delivery Order " + formik.values.referenceNo, path: pathnames.sales.deliveryOrdersViewEdit + id });
		}

		return data;
	}, [isEdit, formik.values.referenceNo, id]);

	useBreadcrumb({ breadCrumb });

	const onToggleMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleSetComplete = () => {
		deliveryOrderClosedRef.current.onHandleShow(formik.values.referenceNo, STATUS.COMPLETED);

		setMenuAnchor(null);
	};

	const onHandleSetCancelled = () => {
		deliveryOrderClosedRef.current.onHandleShow(formik.values.referenceNo, STATUS.CANCELLED);

		setMenuAnchor(null);
	};

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.sales.deliveryOrders);
	}, [navigate]);

	//prettier-ignore
	const onHandleCellSelect = useCallback((cell, cellData) => {
		if (cell?.type) return;

		const item = formik.values.lineItems[cellData.rowIndex];

		if (item) setSelectedRowItem(item);
	}, [formik.values.lineItems, setSelectedRowItem]);

	//prettier-ignore
	const onHandleSelectAll = useCallback((isChecked) => {
		formik.setFieldValue('lineItems', formik.values.lineItems.map(item => ({
			...item,
			checkedFlag: isChecked
		})));
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				deliveryOrderId: formik.values.id,
				createFlag: !values?.draft,
				title: formik.values.title,
				internalNote: formik.values.internalNote,
				itemList: formik.values.lineItems.map((item) => ({
					lineItemId: item.id,
					checkedFlag: item.checkedFlag
				}))
			};
			
			response = await api.post.deliveryOrders.updateDeliveryOrder(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (!values?.draft) {
				onHandleBack();
				
				dispatch(promptLayoutAlertMessage({ message: "Delivery Order was created successfully!" }));
			} else {
				dispatch(promptLayoutAlertMessage({ message: "Draft was updated successfully!" }));

				onHandleGetDetails(id);
			}
		}
	}, [dispatch, formik, onHandleBack, onHandleGetDetails, id]);

	const onHandleUpdateDraft = useCallback(() => {
		if (!formik.values.title) {
			formik.setFieldError("title", ERRORS.REQUIRED);
			formik.setFieldTouched("title", true, false);

			return;
		}

		const payload = { ...formik.values, draft: true };

		onHandleSubmit(payload);
	}, [formik, onHandleSubmit]);

	const processLineItems = useCallback((lineItems) => {
		return lineItems.map((item, index) => ({
			number: index + 1,
			id: item.id,
			title: item.title,
			configServiceRefNo: item?.configService?.referenceNo,
			referenceNo: item.referenceNo,
			serviceId: item.serviceId,
			serviceType: item.serviceType,
			description: item.description,
			quantity: item.quantity,
			unitName: item.unitName,
			unitPrice: item.unitPrice,
			lineItemTotalAmount: item.lineItemTotalAmount,
			lineSubItems: item.lineSubItems
		}));
	}, []);

	//prettier-ignore
	const onHandleCheckboxChange = useCallback((itemId, isChecked) => {
		formik.setFieldValue(
			"lineItems",
			formik.values.lineItems.map((item) => (item.id === itemId ? { ...item, checkedFlag: isChecked } : item))
		);
	}, [formik]);

	const onHandleExpandCell = useCallback((index) => {
		setRowsExpanded((prev) => (prev.includes(index) ? [] : [index]));
	}, []);

	const onHandleExportPDF = useCallback(async (action) => {
		let response = null;
		let fileName = "";

		try {
			const payload = { "delivery-order-id": id };

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("inline; filename=")?.[1]?.split(";")?.[0];

				if (fileName) return data;

				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));

					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			response = await api.post.deliveryOrders.generateDeliveryOrderPdf(payload, { transformResponse });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			if (action === "preview") {
				const blob = new Blob([response], { type: 'application/pdf' });
      			const urlPdf = window.URL.createObjectURL(blob);

				window.open(urlPdf, '_blank');

        		setTimeout(() => {
        			window.URL.revokeObjectURL(urlPdf);
        		}, 1000);

				return;
			}

			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	}, [id]);

	const tableColumns = useMemo(() => {
		const commonColumns = [
			{
				name: "title",
				label: "Title",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "serviceId",
				label: "Service ID",
				options: {
					sort: true,
					sortThirdClickReset: true,
					customBodyRender: (value, tableMeta) => {
						const item = formik.values?.lineItems[tableMeta.rowIndex];

						return item?.referenceNo || item?.configService?.referenceNo;
					}
				}
			},
			{
				name: "quantity",
				label: "Quantity",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "unitName",
				label: "Unit",
				options: {
					sort: false
				}
			},
			{
				name: "unitPrice",
				label: "Price Per Unit (MYR)",
				options: {
					sort: true,
					sortThirdClickReset: true,
					setCellHeaderProps: () => ({ className: "table__price" }),
					setCellProps: () => ({ className: "table__price" }),
					customBodyRender: (value, tableMeta) => {
						return formatCurrency(value);
					}
				}
			},
			{
				name: "lineItemTotalAmount",
				label: "Amount (MYR)",
				options: {
					sort: true,
					sortThirdClickReset: true,
					setCellHeaderProps: () => ({ className: "table__price" }),
					setCellProps: () => ({ className: "table__price" }),
					customBodyRender: (value, tableMeta) => {
						return formatCurrency(value);
					}
				}
			}
		];

		if (!isEdit) {
			return [
				{
					name: "number",
					label: "#",
					options: {
						sort: true,
						sortThirdClickReset: true,
						customBodyRender: (value, tableMeta) => {
							const item = formik.values?.lineItems[tableMeta.rowIndex];
							const expandedButtonClassNames = classNames({
								"table__expandable-button": true,
								"table__expandable-button--expanded": rowsExpanded.includes(tableMeta.rowIndex)
							});

							if (item?.lineSubItems?.length) {
								return (
									<div className="table__expandable">
										{value}

										<button type="button" className={expandedButtonClassNames} onClick={() => onHandleExpandCell(tableMeta.rowIndex)}>
											<div className="table__expandable-icon" />
										</button>
									</div>
								);
							}

							return value;
						}
					}
				},
				...commonColumns
			];
		} else {
			return [
				{
					name: "checkbox",
					options: {
						sort: false,
						customHeadRender: () => {
							const allSelected = formik.values.lineItems.length > 0 && formik.values.lineItems.every((item) => item.checkedFlag);

							return (
								<TableCell key="table-key" className="app-table__cell app-table__cell--header">
									<AppCheckbox onClick={(isChecked) => onHandleSelectAll(isChecked)} value={allSelected} />
								</TableCell>
							);
						},
						customBodyRender: (value, tableMeta) => {
							const rowIndex = tableMeta.rowIndex;
							const item = formik.values.lineItems[rowIndex];

							return (
								<Table>
									<TableBody>
										<TableRow>
											<AppCheckbox className="checkbox" onClick={(isChecked) => onHandleCheckboxChange(item.id, isChecked)} value={item.checkedFlag} disabled={item.status === STATUS.COMPLETED} />
										</TableRow>
									</TableBody>
								</Table>
							);
						}
					}
				},
				{
					name: "number",
					label: "#",
					options: {
						sort: true,
						sortThirdClickReset: true,
						customBodyRender: (value, tableMeta) => {
							const item = formik.values?.lineItems[tableMeta.rowIndex];
							const expandedButtonClassNames = classNames({
								"table__expandable-button": true,
								"table__expandable-button--expanded": rowsExpanded.includes(tableMeta.rowIndex)
							});

							if (item?.lineSubItems?.length) {
								return (
									<div className="table__expandable">
										{value}

										<button type="button" className={expandedButtonClassNames} onClick={() => onHandleExpandCell(tableMeta.rowIndex)}>
											<div className="table__expandable-icon" />
										</button>
									</div>
								);
							}

							return value;
						}
					}
				},
				...commonColumns
			];
		}
	}, [formik.values?.lineItems, isEdit, onHandleSelectAll, onHandleCheckboxChange, onHandleExpandCell, rowsExpanded]);

	//prettier-ignore
	const onHandleSubCellSelect = useCallback((rowIndex, index) => {
		const item = formik.values.lineItems[rowIndex].lineSubItems[index];

		if (item) setSelectedRowItem(item);
	}, [formik]);

	//prettier-ignore
	const onHandleRenderExpandedRow = useCallback((rowData, tableMeta) => {
		const items = formik.values.lineItems?.[tableMeta.rowIndex]?.lineSubItems;

		let rowIndex = tableMeta.rowIndex;

		return items?.map((o, i) => {
			return (
				<TableRow key={i} className="table__sub-item" onClick={(o) => onHandleSubCellSelect(rowIndex, i)}>
					{isEdit && <TableCell>{}</TableCell>}

					<TableCell className="table__numbering">{(rowIndex + 1) + "." + (i + 1) + "."}</TableCell>

					<TableCell>{o.title}</TableCell>

					<TableCell>{ }</TableCell>

					<TableCell>{o.quantity}</TableCell>

					<TableCell>{o.unitName}</TableCell>

					<TableCell className="table__price">{formatCurrency(o.unitPrice)}</TableCell>

					<TableCell className="table__price">{formatCurrency(o.lineItemTotalAmount || o.lineSubItemTotalAmount)}</TableCell>
				</TableRow>
			);
		});
	}, [formik.values.lineItems, onHandleSubCellSelect, isEdit]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		rowsExpanded: rowsExpanded,
		expandableRows: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		count: linesTableData.totalElements,
		page: linesTableData.page,
		serverSide: true,
		enableNestedDataAccess:".",
		renderExpandableRow: onHandleRenderExpandedRow,

		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetDetails();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetDetails();
					break;
				default:
					break;
			}
		},	
		onCellClick: onHandleCellSelect,
	}), [linesTableData.page, linesTableData.totalElements, onHandleCellSelect, onHandleGetDetails, onHandleRenderExpandedRow, rowsExpanded]);

	//prettier-ignore
	const SelectedItemDetails = useCallback(({ selectedRowItem, items }) => {
		if (!selectedRowItem) return null;

		const itemsArray = Array.isArray(items) ? items : items?.lineItems || [];
		const selectedIndex = itemsArray.findIndex((item) => item.id === selectedRowItem.id);

		if (selectedIndex === -1) return null;

		const firstItem = selectedIndex === 0;
		const lastItem = selectedIndex === itemsArray.length - 1;

		const onHandleNextItem = () => {
			if (!lastItem) {
				setSelectedRowItem(itemsArray[selectedIndex + 1]);
			}
		};

		const onHandlePrevItem = () => {
			if (!firstItem) {
				setSelectedRowItem(itemsArray[selectedIndex - 1]);
			}
		};

		return (
			<div className="delivery-orders-view-edit__item-table">
				<div className="item-table">
					<div className="item-table__content">
						<div className="item-table__item">
							<p className="item-table__label">Title</p>

							<p className="item-table__value">{selectedRowItem.title}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service ID</p>

							<p className="item-table__value">{selectedRowItem.configService?.referenceNo || selectedRowItem.referenceNo}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service Type</p>

							<p className="item-table__value">{selectedRowItem.serviceType}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Description</p>

							<p className="item-table__value">{selectedRowItem.description}</p>
						</div>
					</div>

					<div className="item-table__pagination">
						<button type="button" className="item-table__button item-table__button--prev" disabled={firstItem} onClick={onHandlePrevItem}>
							<img src={chevronIcon} alt="pagination-prev" />
						</button>

						<p className="item-table__page">
							Item {selectedIndex + 1} of {itemsArray.length}
						</p>

						<button type="button" className="item-table__button item-table__button--next" disabled={lastItem} onClick={onHandleNextItem}>
							<img src={chevronIcon} alt="pagination-next" />
						</button>
					</div>
				</div>
			</div>
		);
	}, [setSelectedRowItem]);

	//prettier-ignore
	const onHandleSetAction = useCallback(async (status) => {
		let response = null;

		const payload = {
			deliveryOrderId: id,
			deliveryOrderStatus: status
		};

		try {
			response = await api.post.deliveryOrders.updateDeliveryOrderStatus(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Delivery Order status was updated successfully!" }));

			onHandleGetDetails(id);
		}
	}, [dispatch, onHandleGetDetails, id]);

	useEffect(() => {
		onHandleGetDetails(id);
	}, [id, onHandleGetDetails]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.DELIVERY_ORDERS.DELIVERY_ORDER);
		};
	}, [cancelRequest]);

	return (
		<div className="page-delivery-orders-view-edit">
			<div className="delivery-orders-view-edit">
				<div className="delivery-orders-view-edit__header">
					<h1 className="delivery-orders-view-edit__title">{title}</h1>

					<div className="delivery-orders-view-edit__header delivery-orders-view-edit__header--column">
						<div className="delivery-orders-view-edit__header-buttons">
							{!isEdit && !isClosed && <AppButton outline className="delivery-orders-view-edit__action-button" type="button" label="Action" icon={chevronIcon} onClick={onToggleMenu} />}

							<AppButton outline type="button" label="Export as PDF" icon={exportIcon} onClick={() => onHandleExportPDF("download")} />
						</div>

						<p className="delivery-orders-view-edit__last-update">
							<span>Last Updated By</span> {formik.values.lastModifiedBy}, {formik.values.lastModifiedDate}
						</p>
					</div>
				</div>

				<form className="delivery-orders-view-edit__form" onSubmit={formik.handleSubmit}>
					<div className="delivery-orders-view-edit__container">
						<div className="delivery-orders-view-edit__wrapper delivery-orders-view-edit__wrapper--divider">
							<p className="delivery-orders-view-edit__label">General Details</p>

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled type="text" name="referenceNo" label="Delivery Order ID" placeholder="Delivery Order ID" value={formik.values.referenceNo} error={formik.errors.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="status" label="Status" placeholder="Status" value={capitalizeCharacter(formik.values.status.split("_").join(" "))} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled type="text" name="saleOrderReferenceNo" label="Sales Order ID" placeholder="Sales Order ID" value={formik.values.saleOrderReferenceNo} error={formik.errors.saleOrderReferenceNo} touched={formik.touched.saleOrderReferenceNo} onChange={formik.handleChange} />
							</div>
						</div>

						<div className="delivery-orders-view-edit__wrapper">
							<p className="delivery-orders-view-edit__label">Customer Details</p>

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled type="text" name="customerName" label="Registered Name" placeholder="Please input customer name" value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="picName" label="PIC Name" placeholder="Please input PIC name" value={formik.values.picName} error={formik.errors.picName} touched={formik.touched.picName} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-view-edit__row">
								<AppMobileInput disabled type="number" name="picPostfixMobileNumber" label="Mobile No." value={formik.values.picPostfixMobileNumber} prefixNo={formik.values.picPrefixMobileNumber} error={formik.errors.picPostfixMobileNumber} touched={formik.touched.picPostfixMobileNumber} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

								<AppInput disabled type="text" name="picEmail" label="Email" placeholder="Please input a No. of days" value={formik.values.picEmail} error={formik.errors.picEmail} touched={formik.touched.picEmail} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled type="text" name="addressLine1" label="Address Line 1" placeholder="Please input address line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="addressLine2" label="Address Line 2" placeholder="Please input address line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled type="text" name="state" label="State" placeholder="Select..." value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="city" label="City" placeholder="Select..." value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
							</div>

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled type="text" name="postcode" label="Postcode" placeholder="Select..." value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					<div className="delivery-orders-view-edit__container">
						<div className="delivery-orders-view-edit__wrapper">
							<AppInput required disabled={!isEdit} type="text" name="title" label="Delivery Order Title" value={formik.values.title} error={formik.errors.title} touched={formik.touched.title} onChange={formik.handleChange} />

							<p className="delivery-orders-view-edit__label">Item Lines {isEdit && <span>*</span>}</p>

							{isEdit && <p className="delivery-orders-view-edit__description">Please select minimum 1 item to create the Delivery Order.</p>}

							<AppTable data={processLineItems(linesTableData.content)} columns={tableColumns} options={tableOptions} />

							<SelectedItemDetails selectedRowItem={selectedRowItem} items={formik.values.lineItems} />

							<div className="delivery-orders-view-edit__row">
								<AppInput disabled={!isEdit} multiline type="textarea" maxLength={1000} name="internalNote" label="Internal Note" placeholder="Enter Internal here" value={formik.values.internalNote} error={formik.errors.internalNote} touched={formik.touched.internalNote} onChange={formik.handleChange} />

								<AppInput disabled multiline type="textarea" maxLength={1000} name="customerNote" label="Customer Note" placeholder="Enter Note here" value={formik.values.customerNote} error={formik.errors.customerNote} touched={formik.touched.customerNote} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					{formik.values.authorisedBy?.fullName && (
						<div className="delivery-orders-view-edit__container delivery-orders-view-edit__container--signature">
							<div className="delivery-orders-view-edit__row delivery-orders-view-edit__row--signature">
								<div className="delivery-orders-view-edit__column">
									<AppInput disabled name="authorisedBy.fullName" label="Authorised By" value={formik.values.authorisedBy?.fullName} touched={formik.touched.authorisedBy?.fullName} onChange={formik.handleChange} />

									<AppInputDragAndDrop disabled name="authorisedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.authorisedBy?.signature} error={formik.errors.authorisedBy?.signature} touched={formik.touched.authorisedBy?.signature} />
								</div>
							</div>
						</div>
					)}

					<div className="delivery-orders-view-edit__button-container">
						<AppButton outline className="delivery-orders-view-edit__cancel-button" type="button" label="Cancel" onClick={onHandleBack} />
							
						<AppButton outline type="button" label="Preview" onClick={() => onHandleExportPDF("preview")}/>

						{isEdit && restricted && (
							<Fragment>
								{!isClosed && <AppButton outline type="button" label="Update Draft" onClick={onHandleUpdateDraft} />}

								<AppButton type="submit" label="Create Delivery Order" disabled={formik.isSubmitting || !formik.dirty} />
							</Fragment>
						)}
					</div>
				</form>
			</div>

			<Menu classes={{ root: "delivery-orders-view-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="menu">
					<button className="menu__label" onClick={onHandleSetComplete}>
						Set As Completed
					</button>

					<button className="menu__label" onClick={onHandleSetCancelled}>
						Set As Cancelled
					</button>
				</div>
			</Menu>

			<AppDeliveryOrdersClosedModal ref={deliveryOrderClosedRef} onConfirm={onHandleSetAction} />
		</div>
	);
};

export default PageDeliveryOrdersViewEdit;
