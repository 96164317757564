import { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import dayjs from "dayjs";
import COMMON from "common";
import PropTypes from "prop-types";
import MuiDataTable from "mui-datatables";

import classNames from "common/class-names";
import getOrdinalNumber from "common/get-ordinal-number";

import DATE_TIME from "constants/date-time";

import AppButton from "components/app-button";
import AppCalendarScheduleFooter from "components/app-calendar-schedule/app-calendar-schedule-footer";

import editIcon from "assets/images/pages/human-resources/edit-icon.svg";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const AppCalendarSchedule = (props, ref) => {
	const formik = useMemo(() => props.formik, [props.formik]);
	const components = useMemo(() => props.components, [props.components]);
	const [monthTitle, setMonthTitle] = useState(new Date().getMonth());
	const [yearTitle, setYearTitle] = useState(new Date().getFullYear());
	const [editData, setEditData] = useState(false);
	const onHandleGetList = useMemo(() => props.onHandleGetList, [props.onHandleGetList]);
	const monthName = COMMON.MONTH_LIST[monthTitle];
	const currentDate = useMemo(() => new Date(), []);
	const expandable = useMemo(() => props.expandable, [props.expandable]);
	const futureDates = useMemo(() => props?.futureDatesLimit && yearTitle === currentDate.getFullYear() && monthTitle === currentDate.getMonth(), [props?.futureDatesLimit, yearTitle, currentDate, monthTitle]);
	const [expandedRows, setExpandedRows] = useState({});
	const duplicatesOnly = useMemo(() => (props?.groupedDate ? Object.keys(props.groupedDate).filter((date) => props.groupedDate[date].length > 1) : []), [props.groupedDate]);

	//prettier-ignore
	const handleRowExpand = useCallback((rowIndex) => {
		setExpandedRows((prev) => ({
			...prev,
			[rowIndex]: !prev[rowIndex]
		}));

		props.handleRowExpand(rowIndex);
	}, [props]);

	//prettier-ignore
	const updateMonthAndYear = useCallback((nextMonth) => {
		let currentMonth = monthTitle;
		let currentYear = yearTitle;

		if (nextMonth > 11) {
			currentMonth = nextMonth - 12;
			currentYear = yearTitle + 1;

			setMonthTitle(nextMonth - 12);

			setYearTitle(yearTitle + 1);
		} else if (nextMonth < 0) {
			currentMonth = nextMonth + 12;
			currentYear = yearTitle - 1;

			setMonthTitle(nextMonth + 12);

			setYearTitle(yearTitle - 1);
		} else if (typeof nextMonth === 'number' && !Number.isNaN(nextMonth)) {
			currentMonth = nextMonth;

			setMonthTitle(nextMonth);
		} else {
			setMonthTitle(currentMonth);
		}

		if (onHandleGetList) {
			const nextDate = dayjs(`${currentYear}-${currentMonth + 1}-01`).format(DATE_TIME.YYYY_MM_DD);

			onHandleGetList(nextDate);
		}
	}, [monthTitle, onHandleGetList, yearTitle]);

	const handleNextPage = useCallback(() => {
		const nextMonth = monthTitle + 1;

		updateMonthAndYear(nextMonth);
	}, [monthTitle, updateMonthAndYear]);

	const handlePrevPage = useCallback(() => {
		const prevMonth = monthTitle - 1;

		updateMonthAndYear(prevMonth);
	}, [monthTitle, updateMonthAndYear]);

	const generateMonthDates = useCallback(() => {
		const dates = [];
		const firstDayOfMonth = new Date(yearTitle, monthTitle, 1);
		const lastDayOfMonth = futureDates ? new Date(yearTitle, monthTitle, currentDate.getDate()) : new Date(yearTitle, monthTitle + 1, 0);

		for (let date = firstDayOfMonth; date <= lastDayOfMonth; date.setDate(date.getDate() + 1)) {
			dates.push({
				date: date.toLocaleString("sv", { year: "numeric", month: "2-digit", day: "2-digit" })
			});
		}

		return dates;
	}, [currentDate, futureDates, monthTitle, yearTitle]);

	const data = useMemo(() => {
		const allDates = generateMonthDates();

		return allDates.map((dateObj) => {
			const day = parseInt(dateObj.date.split("-")[2], 10);
			const dataForDate = props.data.find((item) => item?.date === dateObj?.date || item?.seq === day);

			return dataForDate ? { ...dateObj, ...dataForDate } : dateObj;
		});
	}, [generateMonthDates, props.data]);

	//prettier-ignore
	const editableDate = useCallback(() => {		
		props?.editableDate();
	}, [props]);

	//prettier-ignore
	const onHandleFormik = useCallback((obj) => {
		const { name, value } = obj;

		formik.setFieldValue(name, value);
	}, [formik]);

	//prettier-ignore
	const onHandleSetEditing = useCallback((status) => {
		setEditData(status);

		if (status === true) props.onHandleSetEditing(status);
		else props.onHandleSetEditing(status, dayjs(new Date(yearTitle, monthTitle, 1)).format(DATE_TIME.YYYY_MM_DD));
	}, [monthTitle, props, yearTitle]);

	//prettier-ignore
	const onHandleSetValue = useCallback((obj) => {
		formik.setValues({ ...obj });

		onHandleSetEditing(true);
	}, [formik, onHandleSetEditing]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ ...obj });

		onHandleSetEditing(false)
	}, [formik, onHandleSetEditing]);

	//prettier-ignore
	const onHandleSubmit = useCallback(() => {
		formik?.handleSubmit();

		onHandleSetEditing(false);
	}, [formik, onHandleSetEditing]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "date",
			label: "Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const currentDate = new Date(value);
					const dayOfWeek = currentDate.toLocaleString("en-GB", { weekday: "long" });
					const suffix = getOrdinalNumber(currentDate.getDate());
					const today = currentDate.toDateString() === new Date().toDateString() ? " (Today)" : "";
					const isExpanded = expandedRows[tableMeta.rowIndex];
					const expandedButtonClassNames = classNames({
						"calendar-schedule__expandable-button": true,
						"calendar-schedule__expandable-button--expanded": isExpanded
					});

					if (expandable) {
						return (
							<div className="calendar-schedule__expandable">
								{`${suffix}, ${dayOfWeek}${today}`}

								{duplicatesOnly?.includes((currentDate.getDate() - 1).toString()) && (
									<button type="button" className={expandedButtonClassNames} onClick={() => handleRowExpand(tableMeta.rowIndex)}>
										<div className="calendar-schedule__expandable-icon" />
									</button>
								)}
							</div>
						);
					}

					return suffix + ", " + dayOfWeek + today;
				}
			}
		}
	], [duplicatesOnly, expandable, expandedRows, handleRowExpand]);

	const columns = useMemo(() => [...tableColumns, ...props.columns], [props.columns, tableColumns]);

	const setRowProps = useCallback((row) => {
		const rowData = row[0]?.props?.children[0] ?? row[0];

		return {
			className: classNames({
				"calendar-row": true,
				"calendar-row--weekend": rowData.includes("Saturday") || rowData.includes("Sunday")
			})
		};
	}, []);

	//prettier-ignore
	const options = useMemo(() => ({
		...props.options,
		print: false,
		filter: false,
		search: false,
		download: false,
		viewColumns: false,
		fixedHeader: false,
		responsive: "standard",
		setRowProps: setRowProps,
		selectableRowsHideCheckboxes: true,
		rowsPerPage: generateMonthDates().length,
		customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => (props.options?.customFooter ? props.options.customFooter(count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) : <AppCalendarScheduleFooter rowsPerPage={rowsPerPage} data={props.data} />)
	}), [props.options, generateMonthDates, setRowProps, props.data]);

	//prettier-ignore
	const HeaderEditableButton = useCallback((obj) => {
		if (!obj.editData) return <AppButton className="calendar-schedule__edit-button" type="button" label="Edit" icon={editIcon} onClick={() => obj.editableDate()} />;

		return (
			<div className="calendar-schedule__edit-buttons">
				<AppButton outline type="button" label="Cancel" onClick={() => obj.onHandleSetEditing(false)} />

				<AppButton type="submit" label="Confirm" />
			</div>
		);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleFormik: onHandleFormik,
		onHandleSetValue: onHandleSetValue,
		updateMonthAndYear: updateMonthAndYear
	}));

	return (
		<div className="app-calendar-schedule">
			<div className="calendar-schedule">
				<div className="calendar-schedule__button-container">
					{!!props.editableDate && <HeaderEditableButton editData={editData} editableDate={editableDate} onHandleSubmit={onHandleSubmit} onHandleSetEditing={onHandleSetEditing} />}

					{!editData && (
						<div className="calendar-schedule__chevron">
							<AppButton outline label="" type="button" className="calendar-schedule__left-chevron" icon={chevronIcon} onClick={handlePrevPage} />

							{!futureDates && <AppButton outline label="" type="button" icon={chevronIcon} onClick={handleNextPage} />}
						</div>
					)}
				</div>

				<h1 className="calendar-schedule__title">
					<span>{monthName}</span> {yearTitle}
				</h1>
			</div>

			<div className="calendar-schedule__table">
				<MuiDataTable columns={columns} data={data} options={options} components={components} />
			</div>
		</div>
	);
};

export default memo(forwardRef(AppCalendarSchedule));

AppCalendarSchedule.propTypes = {
	formik: PropTypes.object,
	options: PropTypes.object,
	components: PropTypes.object,
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	editableDate: PropTypes.func
};
