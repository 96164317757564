const DATE_TIME = {
	HH_MM: "HH:mm",
	DD_MM: "DD-MM",
	H_MM_A: "h:mm a",
	HH_MM_A: "hh:mm A",
	HH_MM_SS: "HH:mm:ss",
	DD_MM_YYYY: "DD-MM-YYYY",
	YYYY_MM_DD: "YYYY-MM-DD",
	DD_MMM_YYYY_DDD: "DD MMM YYYY, ddd",
	DD_MM_YYYY_HH_MM_A: "DD/MM/YYYY HH:MM A",
	YYYY_MM_DD_HH_MM_SS: "YYYY-MM-DD HH:mm:ss",
	LAST_UPDATED_BY_DATE: "DD/MM/YYYY, HH:MM A",
	CREATED_BY_DATE: "DD/MM/YYYY",
};

export default DATE_TIME;
