import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { AxiosContext } from "contexts/with-interceptor-provider";
// import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import classNames from "common/class-names";
import getTotalDays from "common/get-total-days";
import sanitizeObject from "common/sanitize-object";
import capitalizeCharacter from "common/capitalize-character";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";
import WORK_ORDER_TYPE from "constants/work-order-type";

// import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

// import sendIcon from "assets/images/send.svg";
// import addIcon from "assets/images/add-icon.png";
import editIcon from "assets/images/edit-icon.png";

const PageWorkOrder = () => {
	const { orderType } = useParams();
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.WORK_ORDER], [profile]);
	const restricted = useMemo(() => !accessible?.create, [accessible]);
	const [searchParams, setSearchParams] = useSearchParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const memoSearchParams = useRef(setSearchParams);
	const [data, setData] = useState(convertPaginationTableData());
	// const [selectedPending] = useState([]);
	const defaultStatus = useMemo(() => [STATUS.DRAFT, STATUS.PENDING_ASSIGN, STATUS.PENDING_CHECKLIST, STATUS.OPEN, STATUS.IN_PROGRESS, STATUS.PENDING_VERIFY, STATUS.PENDING_ACKNOWLEDGE, STATUS.COMPLETED, STATUS.CANCELLED, STATUS.OVERDUE, STATUS.PENDING_RESCHEDULE, STATUS.RESCHEDULED].join(","), []);
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const workOrderTitle = useMemo(() => capitalizeCharacter(orderType), [orderType]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", "start-date": searchParams.get("start-date") || "", "end-date": searchParams.get("end-date") || "", status: status });
	const previousTypeRef = useRef();

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			params.types = orderType;

			response = await api.get.workOrder.workOrders(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [orderType]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		if (values.startDate && dayjs(values.startDate).isValid()) {
			queryParams.current["start-date"] = dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD);
		}

		if (values.endDate && dayjs(values.endDate).isValid()) {
			queryParams.current["end-date"] = dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD);
		}

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: {
			startDate: "",
			endDate: "",
			status: [STATUS.DRAFT, STATUS.PENDING_ASSIGN, STATUS.PENDING_CHECKLIST, STATUS.OPEN, STATUS.IN_PROGRESS, STATUS.PENDING_VERIFY, STATUS.PENDING_ACKNOWLEDGE, STATUS.COMPLETED, STATUS.CANCELLED, STATUS.OVERDUE, STATUS.PENDING_RESCHEDULE, STATUS.RESCHEDULED]
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	//prettier-ignore
	const intructionClassName = useMemo(() => classNames({
		"work-order-filter__instruction": true,
		"work-order-filter__instruction--error": formik.errors.endDate,
	}), [formik]);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("start-date", queryParams.current["start-date"]);
		formik.setFieldValue("end-date", queryParams.current["end-date"]);
	}, [formik]);

	// const onHandleSelectPending = useCallback((boolean, idNo) => {
	// 	setSelectedPending((prev) => {
	// 		let nextPending = [...prev];

	// 		if (boolean) {
	// 			nextPending.push(idNo);
	// 		} else {
	// 			nextPending = nextPending.filter((id) => id !== idNo);
	// 		}

	// 		return nextPending;
	// 	});
	// }, []);

	//prettier-ignore
	// const onHandleSelectAll = useCallback((boolean) => {
	// 	const ids = data.content.map((item) => item.id);

	// 	setSelectedPending(boolean ? ids : []);
	// }, [data]);

	const onHandleCreateWorkOrder = useCallback(() => {
		navigate(pathnames.workOrder.workOrderCreateEdit + `${orderType}/${PAGE.CREATE}`);
	}, [navigate, orderType]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;
		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";

		onHandleGetList();
	}, [defaultStatus, formik, onHandleGetList]);

	//prettier-ignore
	const onHandleEdit = useCallback((workOrderType, woId) => {
		navigate(pathnames.workOrder.workOrderCreateEdit + `${workOrderType}/${woId}`);
	}, [navigate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Draft", name: STATUS.DRAFT },
			{ label: "Pending Assignation", name: STATUS.PENDING_ASSIGN },
			{ label: "Pending Checklist", name: STATUS.PENDING_CHECKLIST },
			{ label: "Open", name: STATUS.OPEN },
			{ label: "In-Progress", name: STATUS.IN_PROGRESS },
			{ label: "Pending Verify", name: STATUS.PENDING_VERIFY },
			{ label: "Pending Acknowledge", name: STATUS.PENDING_ACKNOWLEDGE },
			{ label: "Completed", name: STATUS.COMPLETED },
			{ label: "Cancelled", name: STATUS.CANCELLED },
			{ label: "Overdue", name: STATUS.OVERDUE },
			{ label: "Pending Rescheduled", name: STATUS.PENDING_RESCHEDULE },
			{ label: "Rescheduled", name: STATUS.RESCHEDULED }
		];

		return data;
	}, []);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter(o => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		// {
		// 	name: "checkbox",
		// 	options: {
		// 		sort: false,
		// 		customHeadRender: () => {
		// 			const selectedAll = data.content.length > 0 && selectedPending.length === data.content.length;

		// 			return (
		// 				<TableCell key="table-key" className="app-table__cell app-table__cell--header">
		// 					<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
		// 				</TableCell>
		// 			);
		// 		},
		// 		customBodyRender: (value, tableMeta) => {
		// 			const rowIndex = tableMeta.rowIndex;
		// 			const tablePendingID = data.content[rowIndex]?.id;
		// 			const selected = selectedPending.includes(tablePendingID);

		// 			return (
		// 				<Table>
		// 					<TableBody>
		// 						<TableRow>
		// 							<TableCell className="app-table__cell">
		// 								<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
		// 							</TableCell>
		// 						</TableRow>
		// 					</TableBody>
		// 				</Table>
		// 			);
		// 		},
		// 	},
		// },
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false,
			},
		},		
		{
			name: "referenceNo",
			label: "Work Order ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "customerDetail.customer.registeredName",
			label: "Registered Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "customerDetail.customerContractSiteName",
			label: "Site Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "woAsset.assetName",
			label: "Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta.rowIndex];

					return rowData.woAsset[0].assetName;
				}
			},
		},
		{
			name: "teamPic.personalInfo.fullName",
			label: "Team PIC",
			options: {
				sort: false,
			},
		},
		{
			name: "scheduledDate",
			label: "Scheduled Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			},
		},
		{
			name: "targetCompletionDate",
			label: "Target Completion Date",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />,
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta.rowIndex];
					const workOrderType = rowData.workOrderType;
					const woId = rowData.id

					return (
						<button type="button" className="table__edit" onClick={() => onHandleEdit(workOrderType, woId)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [data, onHandleEdit]);

	//prettier-ignore
	// const HeaderLinkButton = useCallback((obj) => {
	// 	if (!obj.selectedPending.length)
	// 		return (
	// 			<div className="work-order__create-button">
	// 				<AppButton type="button" label="Create" icon={addIcon} onClick={obj.onCreate} />
	// 			</div>
	// 		);

	// 	return (
	// 		<div className="work-order__header-button">
	// 			<AppButton outline type="button" label="Send for Signature" icon={sendIcon} />
	// 		</div>
	// 	);
	// }, []);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		let page = searchParams.get("page");
		let startDate = dayjs(searchParams.get("start-date"));
		let endDate = dayjs(searchParams.get("end-date"));

		const nextFormValues = { startDate: "", endDate: "", status: [STATUS.DRAFT, STATUS.PENDING_ASSIGN, STATUS.PENDING_CHECKLIST, STATUS.OPEN, STATUS.IN_PROGRESS, STATUS.PENDING_VERIFY, STATUS.PENDING_ACKNOWLEDGE, STATUS.COMPLETED, STATUS.CANCELLED, STATUS.OVERDUE, STATUS.PENDING_RESCHEDULE, STATUS.RESCHEDULED] };
		const changedType = previousTypeRef.current !== orderType;

		if (changedType) {
			previousTypeRef.current = orderType;

			nextFormValues.status = defaultStatus.split(",");

			if (startDate && dayjs(startDate).isValid()) {
				nextFormValues.startDate = "";
			}

			if (endDate && dayjs(endDate).isValid()) {
				nextFormValues.endDate = "";
			}

			if (page) {
				nextFormValues.page = 0;
			}

			if (Object.keys(nextFormValues).length) {
				queryParams.current.status = defaultStatus;
				queryParams.current["start-date"] = "";
				queryParams.current["end-date"] = "";

				memoSetFormValues(nextFormValues);
			}
		}
	}, [defaultStatus, memoSetFormValues, orderType, searchParams]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.WORK_ORDER.WORK_ORDERS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-work-order">
			<div className="work-order">
				<h1 className="work-order__title">{orderType === WORK_ORDER_TYPE.CORRECTIVE ? "SCM" : workOrderTitle} Work Orders</h1>

				<div className="work-order__header">
					<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Work Order ID or Customer Name or Assignee" searchDefaultValue={queryParams.current.name} onHandleAdd={onHandleCreateWorkOrder} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter} restricted={restricted}>
						<div className="work-order-filter">
							<div className="work-order-filter__container">
								<div className="work-order-filter__inputs">
									<AppInputDate name="startDate" label="Scheduled Date" placeholder="Start Date" onChange={formik.setFieldValue} value={formik.values.startDate} />

									<AppInputDate name="endDate" placeholder="End Date" onChange={formik.setFieldValue} value={formik.values.endDate} />
								</div>

								<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
							</div>

							<div className="work-order-filter__container">
								<p className="work-order-filter__label">Status</p>

								<div className="work-order-filter__checkbox-status">
									{menuFilterStatus.map((o) => {
										const isActiveStatus = formik.values.status?.findIndex((i) => i === o.name) > -1;

										return (
											<div className="work-order-filter__checkbox" key={o.label}>
												<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</AppTableFilterHeader>

					{/* <HeaderLinkButton selectedPending={selectedPending} onCreate={onHandleCreateWorkOrder} /> */}
				</div>

				<div className="work-order__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>
		</div>
	);
};

export default PageWorkOrder;
