import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { useFormik } from "formik";
import { Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppAddCustomerCostCentreModal from "components/pages/customer/app-add-customer-cost-centre-modal";
import AppDeleteCustomerCostCentreModal from "components/pages/customer/app-delete-customer-cost-centre-modal";

import trashIcon from "assets/images/trash-icon.png";
import faqIcon from "assets/images/pages/human-resources/faq-icon.svg";

const AppCustomerCostCentreTable = (props) => {
	const { id } = useParams();
	const addCostCentre = useRef();
	const deleteCostCentre = useRef();
	const selectedItem = useRef();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const isClone = useMemo(() => props.isClone, [props.isClone]);
	const customerContractId = useMemo(() => props.customerContractId, [props.customerContractId]);
	const costCenterIds = useMemo(() => props.costCenterIds, [props.costCenterIds]);
	const customerId = useMemo(() => props.customerId, [props.customerId]);
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });
	const [data, setData] = useState(convertPaginationTableData());
	const defaultStatus = useMemo(() => [STATUS.ACTIVE, STATUS.INACTIVE].join(","), []);
	const formik = useFormik({ initialValues: { keyword: "", status: [STATUS.ACTIVE, STATUS.INACTIVE] }, onSubmit: (values) => onHandleSubmit(values) });

	const onHandleCloseMenu = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
	}, [formik]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Active", name: STATUS.ACTIVE },
			{ label: "Inactive", name: STATUS.INACTIVE }
		];

		return data;
	}, []);

	const onHandleAddCostCentreModal = useCallback(() => {
		addCostCentre.current.onHandleShow(customerContractId, costCenterIds);
	}, [costCenterIds, customerContractId]);

	const onHandleGetExclusiveList = useCallback(() => {
		addCostCentre.current.onHandleGetExclusiveList(id);
	}, [id]);

	//prettier-ignore
	const onHandleConfirmRemovePicModal = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		deleteCostCentre.current.onHandleShow(selectedItem.current);
	}, [data]);

	const onHandleGetLocalData = useCallback(() => {
		let nextData = structuredClone(costCenterIds);

		const lowerCaseSearch = queryParams.current.keyword?.toLowerCase();

		if (queryParams.current.keyword.length) {
			nextData = nextData.filter((o) => {
				return o.picName.toLowerCase().includes(lowerCaseSearch) || o.email.toLowerCase().includes(lowerCaseSearch);
			});
		}

		setData({ content: nextData });
	}, [costCenterIds]);

	//prettier-ignore
	const onHandleGetList = useCallback(async (contractId) => {
		let response = null;

		try {
			const params = sanitizeObject({ "customer-contract-id": contractId, queryParams: { ...queryParams.current, size: 10, status: STATUS.ACTIVE}});

			response = await api.get.customer.contractsCostCentreSearch(params);
		} catch (error) {
			promptLayoutAlertMessage(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;

		onHandleGetList();
	}, [defaultStatus, formik, onHandleGetList]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		if (isCreate) {
			onHandleGetLocalData();
		} else {
			onHandleGetList(id);
		}
	}, [id, isCreate, onHandleGetList, onHandleGetLocalData]);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					return tableMeta.rowIndex + 1;
				}
			}
		},
		{
			name: "name",
			label: "Cost Centre Name",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta?.rowIndex];
				
					return rowData?.configCostCenter?.name ?? costCenterIds[tableMeta?.rowIndex]?.name ;
			  	}
			}
		},
		{
			name: "statesWithComma",
			label: "States",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta?.rowIndex]?.configCostCenter?.statesWithComma;
					const shouldShowTooltip = rowData?.length > 25;

					return (
						<div className="table__wrapper">
							<div className="table__states">{rowData ?? costCenterIds[tableMeta?.rowIndex]?.statesWithComma}</div>
						  
						  	{shouldShowTooltip && (
								<Tooltip title={rowData ?? costCenterIds[tableMeta?.rowIndex]?.statesWithComma} placement="top">
									<img src={faqIcon} alt="faq icon" className="app-radio-input__faq-icon" />
								</Tooltip>
							)}
						</div>
					);
			  	}
			}
		},
		{
			name: "workShiftsSize",
			label: "Work Shifts",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta.rowIndex];

					return rowData?.configCostCenter?.workShiftsSize ?? costCenterIds[tableMeta?.rowIndex]?.workShiftsSize;
				}
			}
		},
		{
			name: "workingDaysWithComma",
			label: "Working Days",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
				  const rowData = data.content[tableMeta.rowIndex];

				  return rowData?.configCostCenter?.workingDaysWithComma ?? costCenterIds[tableMeta?.rowIndex]?.workingDaysWithComma;
				}
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta.rowIndex];
					
					return <AppStatus status={rowData?.configCostCenter.status} />
				}
			}
		},
		{
			name: "remove",
			label: "Remove",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta.rowIndex];
					const status = rowData?.status ?? costCenterIds[tableMeta?.rowIndex]?.status;

					if (status === STATUS.ACTIVE) {
						return (
							<button type="button" className="table__action" onClick={(event) => onHandleConfirmRemovePicModal(event, tableMeta)}>
								<img src={trashIcon} alt="trash-icon" />
							</button>
						);
					} else {
						return;
					}
				}
			}
		}
	], [data, onHandleConfirmRemovePicModal, costCenterIds]);

	const emptyState = useMemo(() => {
		if (data.content.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text" onClick={onHandleAddCostCentreModal}>
							No Cost Centres Added. <span className="table__link">Add Cost Centre?</span>
						</p>
					</td>
				</tr>
			</tbody>
		);
		return { TableBody: node };
	}, [data, onHandleAddCostCentreModal, tableColumns.length]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList(id);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList(id);
					break;
				default:
					break;
			}
		}
	}), [id, data, onHandleGetList]);

	useEffect(() => {
		if (isCreate || isClone) {
			const picContactsCopy = structuredClone(customerContractId);

			setData({ content: picContactsCopy });
		} else {
			onHandleGetList(id);
		}
	}, [id, isClone, isCreate, onHandleGetList, customerContractId]);

	useEffect(() => {
		return () => {
			if (!isCreate) {
				cancelRequest(ENDPOINT_PATH.CUSTOMER.CONTRACTS_PIC);

				cancelRequest(ENDPOINT_PATH.CUSTOMER.SEARCH_CONTRACT_COST_CENTRE);
			}
		};
	}, [cancelRequest, isCreate]);

	return (
		<div className="app-customer-cost-centre-table">
			<div className="customer-cost-centre-table">
				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Cost Centre Name" searchDefaultValue={formik.values.search} onHandleSearch={onHandleDebounceSearch} onHandleSetLastFilter={onHandleCloseMenu} onHandleClearFilter={onHandleClearFilter} onHandleAdd={onHandleAddCostCentreModal}>
					<div>
						<div className="customer-cost-centre-table-filter">
							<div className="customer-cost-centre-table-filter__container">
								<p className="customer-cost-centre-table-filter__label">Status</p>

								<div className="customer-cost-centre-table-filter__checkboxes">
									{menuFilterStatus.map((o) => {
										const isActive = formik.values.status.findIndex((i) => i === o.name) > -1;

										return <AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActive} />;
									})}
								</div>
							</div>
						</div>
					</div>
				</AppTableFilterHeader>

				<AppTable data={data.content} columns={tableColumns} components={emptyState} options={tableOptions} />
			</div>

			{/* prettier-ignore */}
			<AppAddCustomerCostCentreModal ref={addCostCentre} isClone={isClone} customerId={customerId} onHandleUpdateCustomerCostCenter={props.onHandleUpdateCustomerCostCenter} onHandleUpdateCostCenterIds={props.onHandleUpdateCostCenterIds} onHandleRefreshPicTable={onHandleGetList} />

			{/* prettier-ignore */}
			<AppDeleteCustomerCostCentreModal ref={deleteCostCentre} isClone={isClone} onHandleRemoveContactCostCenter={props.onHandleRemoveContactCostCenter} onHandleRefreshPicTable={onHandleGetList} onHandleRefreshAddPicModal={onHandleGetExclusiveList} />
		</div>
	);
};

export default AppCustomerCostCentreTable;
