import React, { Fragment, forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";

import api from "services/api";
import getStateListing from "services/get-state-listing";
import getStatusListing from "services/get-user-status-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";
import HOLIDAY_TYPE from "constants/holiday-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppRadioInput from "components/app-radio-input";
import AppSelectInput from "components/app-select-input";
import AppInputSelectMultiple from "components/app-input-select-multiple";

export const AppHrViewHolidaysCreateEditModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const holidayTypeOptions = useMemo(() => [
		{
			label: "Repeated Yearly",
			value: HOLIDAY_TYPE.REPEAT_YEARLY,
			description: (
				<Fragment>
					This holiday will apply to <span className="app-radio-input__description app-radio-input__description--bold">every</span> year. For holidays with fixed dates.
				</Fragment>
			)
		},
		{ 
			label: "One-Time", 
			value: HOLIDAY_TYPE.ONE_TIME, 
			description: "This holiday will only apply to the selected year. For ad-hoc holidays or holidays without a fixed date." 
		}
	], []);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			name: "",
			holidayType: "",
			holidayDate: "",
			description: "",
			startDate: "",
			endDate: "",
			newHolidayDate: "",
			state: "",
			status: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			state: yup.array().min(1, ERRORS.REQUIRED).required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			startDate: yup.date().when(["id"], {
				is: (id) => !id,
				then: () => yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED)
			}),
			endDate: yup.date().when(["id"], {
				is: (id) => !id,
				then: () => yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED)
			}),
			newHolidayDate: yup.date().when(["id"], {
				is: (id) => !!id,
				then: () => yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED)
			}),
			holidayType: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isCreate = useMemo(() => !formik.values.id, [formik]);

	const title = useMemo(() => (isCreate ? "Add Holiday" : "Edit Holiday"), [isCreate]);

	const endDateRestriction = useMemo(() => (formik?.values.startDate > dayjs(new Date()) ? formik.values.startDate : dayjs(new Date())), [formik]);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({ id: obj?.id, name: obj?.name || "", current: obj?.name, description: obj?.description || "", status: obj?.status || "", holidayDate: dayjs(obj?.holidayDate), holidayType: obj?.holidayType, startDate: "", endDate: "", newHolidayDate: "", state: obj?.stateNames || [] });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { name: values.name, description: values.description, holidayType: values.holidayType, status: values.status, stateNames: values.state, holidayDate: values.holidayDate };

			if (isCreate) {
				payload.startDate = values.startDate;
				payload.endDate = values.endDate;

				await api.post.humanResource.createHoliday(payload);
			}

			if (!isCreate) {
				payload.id = values.id;
				payload.holidayDate = values.newHolidayDate;

				await api.post.humanResource.updateHoliday(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			setVisible(false);

			formik.resetForm();

			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Holiday is added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Holiday is updated successfully!" }));
			}

			props.onHandleGetList();
		}
	}, [isCreate, formik, dispatch, props]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-hr-view-holidays-create-edit-modal" }} open={visible}>
			<div className="hr-view-holidays-create-edit-modal">
				<h1 className="hr-view-holidays-create-edit-modal__title">{title}</h1>

				<form className="hr-view-holidays-create-edit-modal__form" onSubmit={formik.handleSubmit}>
					{!isCreate && <AppInput disabled type="text" name="current" label="Current Holiday Name" value={formik.values.current} onChange={formik.handleChange} />}

					<AppInput required type="text" name="name" label="New Holiday" placeholder="Enter Holiday Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

					<AppSelectInput required searchable={false} name="status" label="Status" placeholder="Select..." loadOptions={getStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

					<div className="hr-view-holidays-create-edit-modal__radio-input">
						<AppRadioInput required label="Holiday Type" options={holidayTypeOptions} value={formik.values.holidayType} error={formik.errors.holidayType} touched={formik.touched.holidayType} onChange={(v) => formik.setFieldValue("holidayType", v)} />
					</div>

					<AppInputSelectMultiple required name="state" label="States" placeholder="State" loadOptions={getStateListing} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

					{isCreate && (
						<div className="hr-view-holidays-create-edit-modal__row">
							<AppInputDate required name="startDate" label="Start Date" value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.setFieldValue} placeholder="Select start date" minDate={dayjs(new Date() + 1)} maxDate={formik.values.endDate ? dayjs(formik.values.endDate) : ""} />

							<AppInputDate required name="endDate" label="End Date" value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.setFieldValue} placeholder="Select end date" minDate={endDateRestriction} />
						</div>
					)}

					{!isCreate && (
						<div className="hr-view-holidays-create-edit-modal__row">
							<AppInputDate disabled name="holidayDate" label="Current Date" value={formik.values.holidayDate} />

							<AppInputDate name="newHolidayDate" label="New Date" value={formik.values.newHolidayDate} error={formik.errors.newHolidayDate} touched={formik.touched.newHolidayDate} onChange={formik.setFieldValue} placeholder="Select end date" />
						</div>
					)}

					<AppInput multiline label="Description" type="textarea" maxLength={255} name="description" placeholder="Enter Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />

					<div className="hr-view-holidays-create-edit-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppHrViewHolidaysCreateEditModal));

AppHrViewHolidaysCreateEditModal.propTypes = {
	onHandleGetList: PropTypes.func.isRequired
};
