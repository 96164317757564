import React, { Fragment, forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";
import getStateListing from "services/get-state-listing";
import getRegionListing from "services/get-region-listing";
import getPostcodeListing from "services/get-postcode-listing";
import getWorkSpaceListing from "services/get-workspace-listing";
import getAssetTypeListing from "services/get-asset-type-listing";
import getSiteAssetListing from "services/get-site-asset-listing";
import getSalesOrderListing from "services/get-sales-order-listing";
import getCustomerPicListing from "services/get-customer-pic-listing";
import getBuildingTypeListing from "services/get-building-type-listing";
import getContractSitesListing from "services/get-contract-sites-listing";
import getContractByPicListing from "services/get-contract-by-pic-listing";
import getCustomerLookupListing from "services/get-customer-lookup-listing";
import getWorkCompletionListing from "services/get-work-completion-listing";
import getWorkInspectionListing from "services/get-work-inspection-listing";
import getEmployeeMaintenanceListing from "services/get-employee-maintenance-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import timeDifferences from "common/time-differences";
import combineDateTime from "common/combine-date-time";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import CONTRACT_TYPE from "constants/contract-type";
import ENDPOINT_PATH from "constants/end-point-path";
import SIGNATURE_TYPE from "constants/signature-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppInputTime from "components/app-input-time";
import AppRadioInput from "components/app-radio-input";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppWorkOrderHoldOnModal from "components/pages/work-order/app-work-order-hold-on-modal";
import AppSiteDetailsTeamMembersTable from "components/pages/work-order/app-site-details-team-members-table";

const AppWorkOrderSiteDetails = (props, ref) => {
	const { orderType, id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const teamMembersTableRef = useRef();
	const holdOnModal = useRef();
	const customerKeyword = useRef();

	//prettier-ignore
	const contractTypeOptions = useMemo(() => [
		{ label: "Contracted", value: "CONTRACTED" },
		{ label: "Non-Contracted", value: "NON_CONTRACTED" }
	], []);

	const initialValues = useMemo(() => {
		let values = {
			id: "",
			city: "",
			email: "",
			state: "",
			status: STATUS.DRAFT,
			workOrderType: "",
			referenceNo: "",
			teamPIC: "",
			duration: "",
			postcode: "",
			prefixNo: "",
			mobileNo: "",
			siteName: "",
			siteId: "",
			assetId: "",
			createdBy: "",
			preparedDate: "",
			workSpace: "",
			workSpaceId: "",
			contractId: "",
			contractName: "",
			buildingType: "",
			customerId: "",
			customerPic: "",
			customerPicId: "",
			salesOrderId: "",
			addressLine1: "",
			addressLine2: "",
			type: CONTRACT_TYPE.CONTRACTED,
			overallJobDescription: "",
			workInspectionId: "",
			issueDescription: "",
			workCompletionId: "",
			customerAssetName: "",
			targetCompletionDate: "",
			targetCompletionTime: "",
			workInspectionCheckbox: false,
			endDateTime: null,
			startDate: "",
			startTime: "",
			endDate: "",
			endTime: "",
			scheduledTime: null,
			scheduledDate: null,
			teamMembers: [],
			woAsset: [],
			reasonForReschedule: "",
			reasonForCancellation: "",
			preparedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			completedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			verifiedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			acknowledgedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			rescheduledBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			cancelledBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			pendingRescheduledBy: { employeeId: "", name: "", signature: "", signatureFile: null }
		};

		if (props.values) {
			values = props.values;
		}

		return values;
	}, [props.values]);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			type: yup.string().required(ERRORS.REQUIRED),
			customerId: yup.string().required(ERRORS.REQUIRED),
			customerPic: yup.string().required(ERRORS.REQUIRED),
			contractId: yup.string().when(["type"], {
				is: (type) => type === CONTRACT_TYPE.CONTRACTED,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			siteId: yup.string().when(["type"], {
				is: (type) => type === CONTRACT_TYPE.CONTRACTED,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			siteName: yup.string().when(["type"], {
				is: (type) => type === CONTRACT_TYPE.NON_CONTRACTED,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			assetId: yup.string().required(ERRORS.REQUIRED),
			overallJobDescription: yup.string().required(ERRORS.REQUIRED),
			issueDescription: yup.string().required(ERRORS.REQUIRED),
			targetCompletionDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			targetCompletionTime: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			scheduledDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			scheduledTime: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const assetNameKeyword = useRef();
	const siteNameKeyword = useRef();
	const workInspectionKeyword = useRef();
	const salesOrderKeyword = useRef();
	const workCompletionKeyword = useRef();
	const teamMemberKeyword = useRef();
	const registeredNameKeyword = useRef();
	const picNameKeyword = useRef();
	const [overridePermission, setOverridePermission] = useState();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.WORK_ORDER], [profile]);
	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const addPermission = useMemo(() => accessible?.create, [accessible]);
	const updatePermission = useMemo(() => accessible?.update, [accessible]);
	const submitLabel = useMemo(() => (isCreate ? "Create" : "Update"), [isCreate]);
	const isContract = useMemo(() => formik.values.type === CONTRACT_TYPE.CONTRACTED, [formik.values.type]);
	const isCancelled = useMemo(() => formik.values.status === STATUS.CANCELLED, [formik]);
	const isRescheduled = useMemo(() => formik.values.status === STATUS.RESCHEDULED, [formik]);
	const isPendingRescheduled = useMemo(() => formik.values.status === STATUS.PENDING_RESCHEDULE, [formik]);
	const isPendingAcknowledge = useMemo(() => formik.values.status === STATUS.PENDING_ACKNOWLEDGE, [formik]);
	const isBeforeInProgress = useMemo(() => formik.values.status === STATUS.DRAFT || formik.values.status === STATUS.PENDING_ASSIGN || formik.values.status === STATUS.PENDING_ASSET || formik.values.status === STATUS.OPEN || formik.values.status === STATUS.PENDING_CHECKLIST, [formik]);
	const checkBoxDisable = useMemo(() => !isCreate || !isContract || !formik.values.workInspectionId, [formik.values.workInspectionId, isContract, isCreate]);

	const restricted = useMemo(() => {
		if (overridePermission) return false;

		return !accessible?.update || !accessible?.create;
	}, [accessible, overridePermission]);

	const hasPermission = useMemo(() => {
		if (overridePermission) return true;

		if (isCreate) return addPermission;

		return updatePermission;
	}, [isCreate, addPermission, updatePermission, overridePermission]);

	const isEditable = useMemo(() => {
		return overridePermission || isBeforeInProgress;
	}, [overridePermission, isBeforeInProgress]);

	// prettier-ignore
	const getStatusListing = useMemo(() => {
		const status = formik.values.status;

		const commonOptions = [
			{ label: "Open", value: STATUS.OPEN },
			{ label: "Rescheduled", value: STATUS.RESCHEDULED },
			{ label: "Completed", value: STATUS.COMPLETED },
			{ label: "Cancelled", value: STATUS.CANCELLED },
		];

		switch (status) {
			case STATUS.DRAFT:
				return [{ label: "Draft", value: STATUS.DRAFT }, { label: "In Progress", value: STATUS.IN_PROGRESS }, ...commonOptions];

			case STATUS.PENDING_ASSIGN:
				return [{ label: "Pending Assignment", value: STATUS.PENDING_ASSIGN }, { label: "In Progress", value: STATUS.IN_PROGRESS }, ...commonOptions];

			case STATUS.PENDING_CHECKLIST:
				return [{ label: "Pending Checklist", value: STATUS.PENDING_CHECKLIST }, { label: "In Progress", value: STATUS.IN_PROGRESS }, ...commonOptions];

			case STATUS.OPEN:
				return [{ label: "Open", value: STATUS.OPEN }, { label: "In Progress", value: STATUS.IN_PROGRESS }, { label: "Rescheduled", value: STATUS.RESCHEDULED }, { label: "Completed", value: STATUS.COMPLETED }, { label: "Cancelled", value: STATUS.CANCELLED }, { label: "Pending Verification", value: STATUS.PENDING_VERIFY }, { label: "Pending Acknowledge", value: STATUS.PENDING_ACKNOWLEDGE }, { label: "Pending Reschedule", value: STATUS.PENDING_RESCHEDULE }];

			case STATUS.IN_PROGRESS:
				return [{ label: "In Progress", value: STATUS.IN_PROGRESS }, ...commonOptions, { label: "Pending Reschedule", value: STATUS.PENDING_RESCHEDULE }];

			case STATUS.PENDING_VERIFY:
				return [{ label: "Pending Verification", value: STATUS.PENDING_VERIFY }, ...commonOptions, { label: "Pending Acknowledge", value: STATUS.PENDING_ACKNOWLEDGE }];

			case STATUS.PENDING_ACKNOWLEDGE:
				return [{ label: "Pending Acknowledge", value: STATUS.PENDING_ACKNOWLEDGE }, ...commonOptions, { label: "Pending Verification", value: STATUS.PENDING_VERIFY }];

			case STATUS.PENDING_RESCHEDULE:
				return [{ label: "Pending Reschedule", value: STATUS.PENDING_RESCHEDULE }, ...commonOptions];

			case STATUS.OVERDUE:
				return [{ label: "Overdue", value: STATUS.OVERDUE }, { label: "Rescheduled", value: STATUS.RESCHEDULED }, { label: "Cancelled", value: STATUS.CANCELLED }, { label: "Open", value: STATUS.OPEN }, { label: "Pending Reschedule", value: STATUS.PENDING_RESCHEDULE }];

			default:
				return [];
		}
	}, [formik.values.status]);

	//prettier-ignore
	const onHandleGetEmployeeDetail = useCallback(async (uniqueId) => {
		let response = null;

		try {
			const params = sanitizeObject({ "user-id": uniqueId });

			response = await api.get.humanResource.employeeHR(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) memoSetFormValues((prev) => ({ ...prev, preparedBy: { employeeId: response?.id, fullName: response?.fullName } }));

			if (isPendingAcknowledge) memoSetFormValues((prev) => ({ ...prev, acknowledgedBy: { employeeId: response?.id, fullName: response?.fullName } }));
		}
	}, [isCreate, isPendingAcknowledge, memoSetFormValues]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (workOrderReportId) => {
		let response = null;

		try {
			response = await api.get.workOrder.workOrder(workOrderReportId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				id: response.id,
				city: response.customerDetail?.city,
				type: CONTRACT_TYPE.CONTRACTED,
				state: response.customerDetail?.state,
				status: response.status,
				teamPIC: response.teamPic?.id,
				salesOrderId: response.saleOrder?.id,
				workCompletionId: response.workCompletion?.id,
				workInspectionId: response.workInspection?.id,
				endDate: dayjs(response.endDate) || "",
				endTime: dayjs(response.endDate) || "",
				referenceNo: response.referenceNo,
				siteId: response.customerDetail?.customerContractSite.id,
				siteName: response.customerDetail?.customerContractSite?.name,
				assetId: response.woAsset[0].customerContractSiteAssetId,
				assetName: response.woAsset[0].assetName,
				customerAssetName: response.woAsset[0].customerAssetName,
				postcode: response.customerDetail?.postcode,
				startDate: dayjs(response.startDate) || "",
				startTime: dayjs(response.startDate) || "",
				targetCompletionDate: dayjs(response.targetCompletionDate) || "",
				targetCompletionTime: dayjs(response.targetCompletionDate) || "",
				customerPic: response.customerDetail?.customerPicContact.picName,
				customerPicId: response.customerDetail?.customerPicContact.id,
				contractId: response.customerDetail.customerContract.id,
				contractName: response.customerDetail.customerContract.name,
				overallJobDescription: response.overallJobDescription,
				issueDescription: response.issueDescription,
				buildingType: response.customerDetail?.configBuildingType?.id,
				addressLine1: response.customerDetail?.address_1,
				addressLine2: response.customerDetail?.address_2,
				email: response.customerDetail?.customerPicContact.email,
				prefixNo: response.customerDetail?.customerPicContact.mobileNoPrefix,
				mobileNo: response.customerDetail?.customerPicContact.mobileNo,
				scheduledDate: dayjs(response.scheduledDate) || "",
				scheduledTime: dayjs(response.scheduledDate) || "",
				customerId: response.customerDetail?.customer.id,
				registeredName: response.customerDetail?.customer.registeredName,
				woAsset: response.woAsset,
				preparedDate: response.createdDate,
				reasonForReschedule: response.signature?.rescheduledReason,
				reasonForCancellation: response.signature?.cancelledReason,
				preparedBy: { employeeId: response.signature?.preparedBy?.id, fullName: response.signature?.preparedBy?.personalInfo?.fullName, signature: response.signature?.preparedBySignaturePath },
				completedBy: { employeeId: response.signature?.completedBy?.id, fullName: response.signature?.completedBy?.personalInfo?.fullName, signature: response.signature?.completedBySignaturePath },
				verifiedBy: { employeeId: response.signature?.verifiedBy?.id, fullName: response.signature?.verifiedBy?.personalInfo?.fullName, signature: response.signature?.verifiedBySignaturePath },
				acknowledgedBy: { employeeId: response.signature?.acknowledgedBy?.id, fullName: response.signature?.acknowledgedBy?.personalInfo?.fullName, signature: response.signature?.acknowledgedBySignaturePath },
				rescheduledBy: { employeeId: response.signature?.rescheduledBy?.id, fullName: response.signature?.rescheduledBy?.personalInfo?.fullName, signature: response.signature?.rescheduledBySignaturePath },
				cancelledBy: { employeeId: response.signature?.cancelledBy?.id, fullName: response.signature?.cancelledBy?.personalInfo?.fullName, signature: response.signature?.cancelledBySignaturePath },
				pendingRescheduledBy: { employeeId: response?.signature?.pendingRescheduledByEmployee?.id, fullName: response.signature?.pendingRescheduledByEmployee?.personalInfo?.fullName, signature: response.signature?.pendingRescheduledBySignaturePath }
			});

			workInspectionKeyword.current = response.workInspection?.customerDetail?.customer?.registeredName;
			salesOrderKeyword.current = response.saleOrder?.referenceNo;
			workCompletionKeyword.current = response.workCompletion?.referenceNo;
			teamMemberKeyword.current = response?.teamPic?.personalInfo?.fullName;
			assetNameKeyword.current = response.woAsset[0].assetName;
			siteNameKeyword.current = response.customerDetail?.customerContractSite?.name;
			registeredNameKeyword.current = response.customerDetail?.customer?.registeredName;
			customerKeyword.current = response.customerDetail?.customerPicContact.picName;

			props.onHandleSetWoInfo({ referenceNo: response.referenceNo, employeeCustomerId: response.customerDetail?.customer.id, status: response.status, siteName: response.customerDetail?.customerContractSite.name, assetId: response.woAsset[0].id, customerContractSiteAssetId: response.woAsset[0].customerContractSiteAssetId, lastModifiedBy: response.lastModifiedBy, lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE) });
		}
	}, [memoSetFormValues, props]);

	//prettier-ignore
	const onHandleSignatures = useCallback(async (workInspectionId, signatureTypes) => {
		let response = null;

		if (!signatureTypes) return;
		
		try {
			const formData = new FormData();

			formData.append("woId", workInspectionId);
			formData.append("signatureType", signatureTypes);

			await api.post.workOrder.signatureUpload(formData);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Signature was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Signature was updated successfully!" }));

				onHandleGetDetails(id);
			}
		}
	}, [dispatch, isCreate, onHandleGetDetails, id]);

	//prettier-ignore
	const onHandleUpdateTeamMembersLocal = useCallback((teamMembers) => {
		formik.setFieldValue("teamMembers", teamMembers);
	}, [formik]);

	//prettier-ignore
	const onHandleUpdateTeamMembers = useCallback(async (workOrderId, teamMembers) => {
		let response = null;

		try {
			const employeeIds = teamMembers.map((o) => o.id);

			const payload = {
				workOrderId: workOrderId,
				employeeIds: employeeIds
			};

			await api.post.workOrder.addTeamMembers(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Team members were added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Team members were updated successfully!" }));
			}

			teamMembersTableRef.current?.onHandleGetList();
		}
	}, [dispatch, isCreate]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				workOrderType: orderType,
				contractType: values.type,
				scheduledDate: combineDateTime(values.scheduledDate, values.scheduledTime),
				targetCompletionDate: combineDateTime(values.targetCompletionDate, values.targetCompletionTime),
				teamPicId: values.teamPIC,
				overallJobDescription: values.overallJobDescription,
				issueDescription: values.issueDescription,
				woAsset: values.woAsset,
				workInspectionId: values.workInspectionId,
				customerDetail: {
					customerId: values.customerId,
					customerPicContactId: values.customerPicId,
					customerContractId: values.contractId,
					customerContractSiteName: values.siteName,
					customerContractSiteId: values.siteId,
					configBuildingTypeId: values.buildingType,
					address_1: values.addressLine1,
					address_2: values.addressLine2,
					state: values.state,
					city: values.city,
					postcode: values.postcode
				}
			};

			if (isCreate) {
				response = await api.post.workOrder.createWorkOrderReport(payload);
			}

			if (!isCreate) {
				payload.id = values.id;
				payload.referenceNo = values.referenceNo;
				payload.status = values.status;

				response = await api.post.workOrder.updateWorkOrderReport(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Order was created successfully!" }));

				onHandleSignatures(response.id, SIGNATURE_TYPE.PREPARED_BY);
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Order was updated successfully!" }));
			}

			if (formik.values.teamMembers?.length) {
				onHandleUpdateTeamMembers(response.id, formik.values.teamMembers);
			}

			navigate(pathnames.workOrder.workOrder + orderType);
		}
	}, [orderType, isCreate, formik, onHandleSignatures, navigate, dispatch, onHandleUpdateTeamMembers]);

	const onHandleOnHoldModal = useCallback(() => {
		holdOnModal.current.onHandleShow({ status: PAGE.CREATE, type: orderType });
	}, [orderType]);

	const onHandleGetWIRData = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.workInspectionReport.reportLite({ "work-inspection-id": formik.values.workInspectionId });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			formik.setValues({ ...formik.values, workInspectionCheckbox: true, customerId: response?.customer?.id, registeredName: response?.customer?.registeredName, customerPicId: response?.customerPicContact?.id, customerPic: response?.customerPicContact?.picName, contractId: response?.customerContract?.id, contractName: response?.customerContract?.name, siteName: response?.customerContractSite?.name, prefixNo: response?.customerPicContact?.prefixNo, mobileNo: response?.customerPicContact?.mobileNo, email: response?.customerPicContact?.email, siteId: response?.customerContractSite?.id, buildingType: response?.configBuildingType?.id, addressLine1: response?.address_1, addressLine2: response?.address_2, state: response?.state, city: response?.city, postcode: response?.postcode });

			registeredNameKeyword.current = response?.customer?.registeredName;
			picNameKeyword.current = response?.customerPicContact?.picName;
		}
	}, [formik]);

	//prettier-ignore
	const onHandleWorkInspectionCheckbox = useCallback((boolean) => {
		if (!boolean) {	
			formik.setValues({ ...formik.values, workInspectionCheckbox: boolean, customerId: "", registeredName: "", customerPicId: "", customerPic: "", contractId: "", contractName: "", siteName: "", assetId: "", customerAssetName: "", email: "", mobileNo: "", prefix: "", buildingType: "", workSpace: "", state: "", city: "", postcode: "", addressLine1: "", addressLine2: "" });
		} else {
			onHandleGetWIRData();
		}
	}, [formik, onHandleGetWIRData]);

	//prettier-ignore
	const onHandleSelectCustomerPic = useCallback(async (event) => {
		const value = event.target.value;

		if (!value) return;

		let customerPic = {};

		try {
			const customerPicListing = await getCustomerPicListing({ id: formik.values.customerId, keyword: value });

			customerPic = customerPicListing.content[0];
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (customerPic) {
			customerKeyword.current = customerPic.label;

			formik.setValues({ ...formik.values, customerPic: customerPic.label, customerPicId: customerPic?.id, email: customerPic?.email, prefixNo: customerPic?.mobileNoPrefix, mobileNo: customerPic?.mobileNo, contractId: "", contractName: "", siteId: "", siteName: "", assetId: "", customerAssetName: "", buildingType: "", workSpaceId: "", addressLine1: "", addressLine2: "", state: "", city: "", postcode: "" });
		}
	}, [formik]);

	// prettier-ignore
	const onHandleSelectContractId = useCallback((event) => {
		const value = event.target.value;

		formik.setValues({ ...formik.values, contractId: value, contractName: "", siteId: "", siteName: "", assetId: "", customerAssetName: "", buildingType: "", workSpaceId: "", addressLine1: "", addressLine2: "", state: "", city: "", postcode: "" });
	}, [formik]);

	// prettier-ignore
	const onHandleSelectSiteName = useCallback(async (event) => {
		const value = event.target.value;

		if (!value) return;

		let response = null;

		try {
			response = await api.get.customerSiteMaintenance.site(value);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			formik.setValues({ ...formik.values, siteId: value, siteName: response.name, assetId: "", customerAssetName: "", buildingType: response.configBuildingTypeId, addressLine1: response.address1, addressLine2: response.address2, state: response.state, city: response.region, postcode: response.postcode });
		}
	}, [formik]);

	//prettier-ignore
	const onHandleSelectSiteAsset = useCallback(async (event) => {
		const value = event.target.value;

		let response = null;

		try {
			const params = { page: 0, size: 10, "customer-contract-site-id": formik.values.siteId };

			response = await api.get.customerSiteMaintenance.assets(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const targetAsset = response.content.find(item => item.id === value);

			const workSpace = await getWorkSpaceListing({ keyword: response.content[0].workspace });

			const asset = await getAssetTypeListing({ keyword: response.content[0].assetType });

			const woAsset = [{ id: formik.values.woAsset[0]?.id || "", customerContractSiteAssetId: value, customerAssetName: targetAsset.customerAssetName, assetName: response.content[0].assetName, configAssetTypeId: asset.content[0].value, configWorkspaceId: workSpace.content[0].value }];

			formik.setValues({ ...formik.values, assetId: value, customerAssetName: targetAsset.customerAssetName, workSpaceId: workSpace.content[0].value, woAsset: woAsset });
		}
	}, [formik]);

	const getRegionLoadOptions = useCallback(() => getRegionListing({ state: formik.values.state }), [formik.values.state]);
	const getPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.state}/${formik.values.city}`), [formik.values.state, formik.values.city]);

	//prettier-ignore
	const onHandleSetSignature = useCallback((name, value, signatureType) => {
		let signatureName = `${name.split(".")[0]}.signatureFile`;

		formik.setFieldValue(value, name);
		formik.setFieldValue(signatureName, value);

		if (!isCreate) onHandleSignatures(value, id, signatureType);
	}, [formik, id, isCreate, onHandleSignatures]);

	//prettier-ignore
	const onHandleGetWIRId = useCallback((event) => {
		let name = event.target.name;
		let value = event.target.value;

		formik.setFieldValue(name, value);
	}, [formik]);

	// prettier-ignore
	const onHandleCalcScheduledDuration = useCallback(() => {
		if (!formik.values.scheduledDate || !formik.values.targetCompletionDate || !formik.values.scheduledTime || !formik.values.targetCompletionTime) {
			return "";
		}

		const scheduledDateTime = combineDateTime(formik.values.scheduledDate, formik.values.scheduledTime);
		const targetCompletionDateTime = combineDateTime(formik.values.targetCompletionDate, formik.values.targetCompletionTime);

		return timeDifferences(scheduledDateTime, targetCompletionDateTime)
	}, [formik.values]);

	// prettier-ignore
	const onHandleCalcDuration = useCallback(() => {
		if (!formik.values.startDate || !formik.values.endDate || !formik.values.startTime || !formik.values.endTime) {
			return "";
		}

		const scheduledDateTime = combineDateTime(formik.values.startDate, formik.values.startTime);
		const targetCompletionDateTime = combineDateTime(formik.values.endDate, formik.values.endTime);

		return timeDifferences(scheduledDateTime, targetCompletionDateTime)
	}, [formik.values]);

	// prettier-ignore
	const onHandleChangeRegisteredName = useCallback(async (event) => {
		const value = event.target.value;
	
		if (!value) {
			formik.setValues({ ...formik.values, customerId: "", customerPic: "", customerPicId: "", contractId: "", contractName: "", siteId: "", siteName: "", assetId: "", customerAssetName: "", mobileNo: "", email: "", buildingType: "", workSpaceId: "", addressLine1: "", addressLine2: "", state: "", city: "", postcode: "" });

			return;
		}
	
		let response = null;
	
		try {
			response = await api.get.customer.customer(value);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}
	
		if (response) {
			registeredNameKeyword.current = response.registeredName;

			formik.setValues({ ...formik.values, customerId: value, customerPic: "", customerPicId: "", contractId: "", contractName: "", siteId: "", siteName: "", assetId: "", customerAssetName: "", mobileNo: "", email: "", buildingType: "", workSpaceId: "", addressLine1: "", addressLine2: "", state: "", city: "", postcode: "" });
		}
	}, [formik]);

	// prettier-ignore
	const onHandleOverrideWorkOrder = useCallback(async (profileId) => {
		let response = null;

		let params = {
			"user-id": profileId
		}

		try {
			response = await api.get.humanResource.permissionOverrideWorkOrder(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setOverridePermission(response);
		}
	}, []);

	useEffect(() => {
		if (isCreate || isPendingAcknowledge) onHandleGetEmployeeDetail(profile.id);

		if (!isCreate) onHandleGetDetails(id);

		onHandleOverrideWorkOrder(profile.id);
	}, [id, isCreate, isPendingAcknowledge, onHandleGetDetails, onHandleGetEmployeeDetail, onHandleOverrideWorkOrder, profile.id]);

	useEffect(() => {
		return () => {
			if (isCreate || isPendingAcknowledge) cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_HR);

			if (!isCreate) cancelRequest(ENDPOINT_PATH.WORK_ORDER.WORK_ORDER);
		};
	}, [cancelRequest, isCreate, isPendingAcknowledge]);

	useImperativeHandle(ref, () => ({
		onHandleGetDetails: onHandleGetDetails
	}));

	return (
		<div className="app-work-order-site-details">
			<div className="work-order-site-details">
				<form className="work-order-site-details__form" onSubmit={formik.handleSubmit}>
					<div className="work-order-site-details__container">
						<p className="work-order-site-details__label">General Details</p>

						{!isCreate && (
							<div className="work-order-site-details__row">
								<AppInput disabled type="text" name="referenceNo" label="Work Order ID" placeholder="Work Order ID" value={formik.values.referenceNo} error={formik.errors.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} />

								{!overridePermission && <AppInput disabled type="text" name="status" label="Status" placeholder="Select..." value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />}

								{overridePermission && <AppSelectInput pagination name="status" label="Status" placeholder="Select..." options={getStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />}
							</div>
						)}

						<div className="work-order-site-details__row">
							<AppRadioInput required disabled={!isCreate} label="Type" options={contractTypeOptions} value={formik.values.type} error={formik.errors.type} touched={formik.touched.type} onChange={(v) => formik.setFieldValue("type", v)} />

							<AppSelectInput pagination disabled={(restricted || !isBeforeInProgress) && !overridePermission} name="workInspectionId" label="Work Inspection ID" placeholder="Enter Work Inspection ID" defaultSearchValue={workInspectionKeyword.current} loadOptions={getWorkInspectionListing} value={formik.values.workInspectionId} error={formik.errors.workInspectionId} touched={formik.touched.workInspectionId} onChange={(o) => onHandleGetWIRId(o)} />
						</div>

						<div className="work-order-site-details__row">
							<AppSelectInput disabled pagination name="salesOrderId" label="Sales Order ID" placeholder="Enter Sales Order ID" defaultSearchValue={salesOrderKeyword.current} loadOptions={getSalesOrderListing} value={formik.values.salesOrderId} error={formik.errors.salesOrderId} touched={formik.touched.salesOrderId} onChange={formik.handleChange} />

							<AppSelectInput disabled pagination name="workCompletionId" label="Work Completion ID" placeholder="Enter Work Completion ID" defaultSearchValue={workCompletionKeyword.current} loadOptions={getWorkCompletionListing} value={formik.values.workCompletionId} error={formik.errors.workCompletionId} touched={formik.touched.workCompletionId} onChange={formik.handleChange} />
						</div>

						<AppInput required disabled={restricted || !isEditable} type="text" name="overallJobDescription" label="Overall Job Description" placeholder="Overall Job Description" value={formik.values.overallJobDescription} error={formik.errors.overallJobDescription} touched={formik.touched.overallJobDescription} onChange={formik.handleChange} />

						<AppInput required disabled={restricted || !isEditable} multiline type="textarea" name="issueDescription" label="Issue Description" placeholder="Issue Description" value={formik.values.issueDescription} error={formik.errors.issueDescription} touched={formik.touched.issueDescription} onChange={formik.handleChange} />

						{formik.values.status !== STATUS.DRAFT && (
							<div className="work-order-site-details__row">
								<AppInput disabled type="text" name="preparedDate" label="Prepared Date" placeholder="" value={formik.values.preparedDate} error={formik.errors.preparedDate} touched={formik.touched.preparedDate} onChange={formik.handleChange} />
							</div>
						)}

						<p className="work-order-site-details__label">Customer Details</p>

						<div className="work-order-site-details__row work-order-site-details__row--checkbox">
							<div className="work-order-site-details__column work-order-site-details__column--checkbox">
								<AppSelectInput required disabled={(restricted || !isCreate) && !overridePermission} pagination name="customerId" label="Registered Name" placeholder="Select..." loadOptions={getCustomerLookupListing} defaultSearchValue={registeredNameKeyword.current} value={formik.values.customerId} error={formik.errors.customerId} touched={formik.touched.customerId} onChange={onHandleChangeRegisteredName} />

								<AppCheckbox disabled={(restricted || checkBoxDisable) && !overridePermission} name="workInspectionCheckbox" label="Same as Work Inspection Customer" value={formik.values.workInspectionCheckbox} touched={formik.touched.workInspectionCheckbox} onClick={(e) => onHandleWorkInspectionCheckbox(e)} />
							</div>

							<AppSelectInput disabled={!formik.values.customerId || (restricted && !overridePermission)} required pagination name="customerPic" label="Customer PIC" placeholder="Select..." loadOptions={(payload) => getCustomerPicListing({ id: formik.values.customerId, keyword: payload?.keyword, page: payload?.page })} defaultSearchValue={picNameKeyword.current} value={formik.values.customerPic} error={formik.errors.customerPic} touched={formik.touched.customerPic} onChange={onHandleSelectCustomerPic} />
						</div>

						<div className="work-order-site-details__row">
							{isContract && <AppSelectInput disabled={!formik.values.customerPic || (restricted && !overridePermission)} required name="contractId" label="Contract ID" placeholder="Select..." loadOptions={(payload) => getContractByPicListing({ id: formik.values.customerPicId, keyword: payload?.keyword, page: payload?.page })} value={formik.values.contractId} error={formik.errors.contractId} touched={formik.touched.contractId} onChange={onHandleSelectContractId} />}

							{isContract && <AppSelectInput disabled={!formik.values.contractId || (restricted && !overridePermission)} required pagination name="siteId" label="Site Name" placeholder="Select..." loadOptions={(payload) => getContractSitesListing({ id: formik.values.contractId, keyword: payload?.keyword, page: payload?.page })} value={formik.values.siteId} error={formik.errors.siteId} touched={formik.touched.siteId} onChange={onHandleSelectSiteName} />}

							{!isContract && <AppInput type="text" disabled={!formik.values.customerPic || (restricted && !overridePermission)} name="siteName" label="Site Name" placeholder="Enter Site Name" value={formik.values.siteName} touched={formik.touched.siteName} onChange={formik.handleChange} />}
						</div>

						<div className="work-order-site-details__row">
							<AppSelectInput disabled={(isContract ? !formik.values.siteId : !formik.values.siteName) || (restricted && !overridePermission)}  pagination required name="assetId" label="Asset Name" placeholder="Asset Name" loadOptions={(payload) => getSiteAssetListing({ id: formik.values.siteId, keyword: payload?.keyword, page: payload?.page })} defaultSearchValue={assetNameKeyword.current} value={formik.values.assetId} error={formik.errors.assetId} touched={formik.touched.assetId} onChange={onHandleSelectSiteAsset} />

							<AppInput disabled={restricted || isContract} type="text" name="customerAssetName" label="Customer Asset Name" placeholder="Customer Asset Name" value={formik.values.customerAssetName} error={formik.errors.customerAssetName} touched={formik.touched.customerAssetName} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

							<AppInput disabled type="text" name="email" label="Email" placeholder="Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
						</div>

						<p className="work-order-site-details__label">Site Location</p>

						<div className="work-order-site-details__row">
							<AppSelectInput disabled={(restricted || isContract) && !overridePermission} name="buildingType" label="Building Type" placeholder="Building Type" loadOptions={getBuildingTypeListing} value={formik.values.buildingType} touched={formik.touched.buildingType} onChange={formik.handleChange} />

							<AppSelectInput disabled={(restricted || isContract) && !overridePermission} name="workSpaceId" label="Work Space" placeholder="Work Space" loadOptions={getWorkSpaceListing} value={formik.values.workSpaceId} error={formik.errors.workSpaceId} touched={formik.touched.workSpaceId} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppInput disabled={(restricted || isContract) && !overridePermission} type="text" name="addressLine1" label="Address Line 1" placeholder="Address Line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

							<AppInput disabled={(restricted || isContract) && !overridePermission} type="text" name="addressLine2" label="Address Line 2" placeholder="Address Line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppSelectInput disabled={(restricted || isContract) && !overridePermission} name="state" label="State" placeholder="State" loadOptions={getStateListing} value={formik.values.state} touched={formik.touched.state} onChange={formik.handleChange} />

							<AppSelectInput disabled={(restricted || !formik.values.state || isContract) && !overridePermission} name="city" label="City" placeholder="City" loadOptions={getRegionLoadOptions} value={formik.values.city} touched={formik.touched.city} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppSelectInput disabled={(restricted || !formik.values.city || isContract) && !overridePermission} name="postcode" label="Postcode" placeholder="Postcode" loadOptions={getPostcodeLoadOptions} value={formik.values.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="work-order-site-details__container">
						<p className="work-order-site-details__label">Scheduling</p>

						<div className="work-order-site-details__row">
							<AppInputDate required disabled={restricted || (!isBeforeInProgress && !isEditable)} name="scheduledDate" label="Scheduled Start Date" placeholder="DD/MM/YYYY" value={formik.values.scheduledDate} error={formik.errors.scheduledDate} touched={formik.touched.scheduledDate} onChange={formik.setFieldValue} />

							<AppInputDate required disabled={restricted || !isEditable} name="targetCompletionDate" label="Scheduled End Date" placeholder="DD/MM/YYYY" value={formik.values.targetCompletionDate} error={formik.errors.targetCompletionDate} touched={formik.touched.targetCompletionDate} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row">
							<AppInputTime required disabled={restricted || !isEditable} name="scheduledTime" label="Scheduled Start Time" placeholder="HH:MM" value={formik.values.scheduledTime} error={formik.errors.scheduledTime} touched={formik.touched.scheduledTime} onChange={formik.setFieldValue} />

							<AppInputTime required disabled={restricted || !isEditable} name="targetCompletionTime" label="Scheduled End Time" placeholder="HH:MM" value={formik.values.targetCompletionTime} error={formik.errors.targetCompletionTime} touched={formik.touched.targetCompletionTime} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row">
							<AppInput disabled type="text" label="Scheduled Duration" value={onHandleCalcScheduledDuration()} />

							<AppSelectInput disabled={restricted || !isEditable} pagination name="teamPIC" label="Team PIC" placeholder="Select..." defaultSearchValue={teamMemberKeyword.current} loadOptions={getEmployeeMaintenanceListing} value={formik.values.teamPIC} touched={formik.touched.teamPIC} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row work-order-site-details__row--divider">
							<AppSiteDetailsTeamMembersTable ref={teamMembersTableRef} teamMembersData={formik.values.teamMembers} status={formik.values.status} onHandleUpdateTeamMembersLocal={onHandleUpdateTeamMembersLocal} onHandleUpdateTeamMembers={onHandleUpdateTeamMembers} />
						</div>

						<p className="work-order-site-details__label">Duration Details</p>

						<div className="work-order-site-details__row">
							<AppInputDate disabled name="startDate" label="Start Date" placeholder="DD/MM/YYYY" value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.setFieldValue} />

							<AppInputDate disabled name="endDate" label="End Date" placeholder="DD/MM/YYYY" value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row">
							<AppInputTime disabled name="startTime" label="Start Time" placeholder="HH:MM" value={formik.values.startTime} error={formik.errors.startTime} touched={formik.touched.startTime} onChange={formik.setFieldValue} />

							<AppInputTime disabled name="endTime" label="End Time" placeholder="HH:MM" value={formik.values.endTime} error={formik.errors.endTime} touched={formik.touched.endTime} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row work-order-site-details__row--divider">
							<AppInput disabled type="text" name="duration" label="Duration" placeholder="Duration" value={onHandleCalcDuration()} error={formik.errors.duration} touched={formik.touched.duration} onChange={formik.handleChange} />
						</div>
					</div>

					{!isCreate && (
						<div className="work-order-site-details__container">
							<div className="work-order-site-details__row work-order-site-details__row--signature">
								<div className="work-order-site-details__column">
									<AppInput disabled name="preparedBy.fullName" label="Prepared By" value={formik.values.preparedBy?.fullName} touched={formik.touched.preparedBy?.fullName} onChange={formik.handleChange} />

									<AppInputDragAndDrop disabled={!isCreate} name="preparedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.preparedBy?.signature} error={formik.errors.preparedBy?.signature} touched={formik.touched.preparedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.PREPARED_BY)} />
								</div>

								{formik.values.completedBy?.signature && (
									<div className="work-order-site-details__column">
										<AppInput disabled name="completedBy.fullName" label="Completed By" value={formik.values.completedBy?.fullName} touched={formik.touched.completedBy?.fullName} onChange={formik.handleChange} />

										<AppInputDragAndDrop disabled name="completedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.completedBy?.signature} error={formik.errors.completedBy?.signature} touched={formik.touched.completedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.COMPLETED_BY)} />
									</div>
								)}
							</div>

							{(isPendingAcknowledge || formik.values.acknowledgedBy.signature || formik.values.verifiedBy.signature) && (
								<div className="work-order-site-details__row work-order-site-details__row--signature">
									<div className="work-order-site-details__column">
										<AppInput disabled name="acknowledgedBy.fullName" label="Acknowledged By" value={formik.values.acknowledgedBy?.fullName} touched={formik.touched.acknowledgedBy?.fullName} onChange={formik.handleChange} />

										{isPendingAcknowledge && (
											<div className="work-order-site-details__column work-order-site-details__column--acknowledgedBy-approval">
												<AppButton outline type="button" label="Acknowledge" onClick={() => onHandleSignatures(id, SIGNATURE_TYPE.ACKNOWLEDGED_BY)} />
											</div>
										)}

										{!isPendingAcknowledge && <AppInputDragAndDrop disabled={restricted} name="acknowledgedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.acknowledgedBy?.signature} error={formik.errors.acknowledgedBy?.signature} touched={formik.touched.acknowledgedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.ACKNOWLEDGED_BY)} />}
									</div>

									{formik.values.verifiedBy.signature && (
										<div className="work-order-site-details__column">
											<AppInput disabled name="verifiedBy.fullName" label="Verified By" value={formik.values.verifiedBy?.fullName} touched={formik.touched.verifiedBy?.fullName} onChange={formik.handleChange} />

											<AppInputDragAndDrop disabled name="verifiedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.verifiedBy?.signature} error={formik.errors.verifiedBy?.signature} touched={formik.touched.verifiedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.VERIFIED_BY)} />
										</div>
									)}
								</div>
							)}

							<div className="work-order-site-details__row work-order-site-details__row--signature">
								{isCancelled && (
									<Fragment>
										<div className="work-order-site-details__column work-order-site-details__column--signature">
											<AppInput disabled type="text" name="cancelledBy.fullName" label="Cancelled By" placeholder="Cancelled By" value={formik.values.cancelledBy?.fullName} touched={formik.touched.cancelledBy?.fullName} onChange={formik.handleChange} />

											<AppInputDragAndDrop disabled name="cancelledBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.cancelledBy?.signature} error={formik.errors.cancelledBy?.signature} touched={formik.touched.cancelledBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.CANCELLED_BY)} />
										</div>

										<div className="work-order-site-details__column work-order-site-details__column--signature">
											<AppInput disabled multiline type="textarea" name="reasonForCancellation" label="Reason for Cancellation" placeholder="Reason for Cancellation" value={formik.values.reasonForCancellation} error={formik.errors.reasonForCancellation} touched={formik.touched.reasonForCancellation} onChange={formik.handleChange} />
										</div>
									</Fragment>
								)}

								{isPendingRescheduled && (
									<Fragment>
										<div className="work-order-site-details__column">
											<AppInput disabled type="text" name="pendingRescheduledBy.fullName" label="Pending Rescheduled By" placeholder="Pending Rescheduled By" value={formik.values.pendingRescheduledBy?.fullName} touched={formik.touched.pendingRescheduledBy?.fullName} onChange={formik.handleChange} />

											<AppInputDragAndDrop disabled name="pendingRescheduledBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.pendingRescheduledBy?.signature} error={formik.errors.pendingRescheduledBy?.signature} touched={formik.touched.pendingRescheduledBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.PENDING_RESCHEDULED_BY)} />
										</div>

										<div className="work-order-site-details__column">
											<AppInput disabled multiline type="textarea" name="reasonForReschedule" label="Reason for Reschedule" placeholder="Reason for Reschedule" value={formik.values.reasonForReschedule} error={formik.errors.reasonForReschedule} touched={formik.touched.reasonForReschedule} onChange={formik.handleChange} />
										</div>
									</Fragment>
								)}

								{isRescheduled && (
									<Fragment>
										<div className="work-order-site-details__column">
											<AppInput disabled name="rescheduledBy.fullName" label="Rescheduled By" value={formik.values.rescheduledBy?.fullName} touched={formik.touched.rescheduledBy?.fullName} onChange={formik.handleChange} />

											<AppInputDragAndDrop disabled name="rescheduledBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.rescheduledBy?.signature} error={formik.errors.rescheduledBy?.signature} touched={formik.touched.rescheduledBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.RESCHEDULED_BY)} />
										</div>

										<div className="work-order-site-details__column">
											<AppInput disabled multiline type="textarea" name="reasonForReschedule" label="Reason for Reschedule" placeholder="Reason for Reschedule" value={formik.values.reasonForReschedule} error={formik.errors.reasonForReschedule} touched={formik.touched.reasonForReschedule} onChange={formik.handleChange} />
										</div>
									</Fragment>
								)}
							</div>
						</div>
					)}

					<div className="work-order-site-details__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleOnHoldModal} />

						{hasPermission && <AppButton disabled={!isEditable} type="submit" label={submitLabel} />}
					</div>
				</form>
			</div>

			<AppWorkOrderHoldOnModal ref={holdOnModal} />
		</div>
	);
};

export default memo(forwardRef(AppWorkOrderSiteDetails));

AppWorkOrderSiteDetails.propTypes = {
	values: PropTypes.object
};
