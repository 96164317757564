import React, { memo, useCallback, useMemo } from "react";

import PropTypes from "prop-types";
import { FormControl, FormHelperText, Tooltip } from "@mui/material";

import classNames from "common/class-names";

import faqIcon from "assets/images/pages/human-resources/faq-icon.svg";

const AppRadioInput = (props) => {

	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);

	const className = useMemo(() => {
		return classNames({
			"app-radio-input": true,
			"app-radio-input__disabled": props.disabled,
			...(props.className && {
				[props.className]: true
			})
		});
	}, [props]);

	//prettier-ignore
	const onHandleChange = useCallback((value) => {
		props.onChange(value);
	}, [props]);

	return (
		<div className={className}>
			<FormControl error={isErrorField}>
				{props.label && (
					<label className="app-radio-input__label" htmlFor={props.name}>
						{props.label}
						{props.required && <span className="app-radio-input__required">*</span>}
					</label>
				)}

				<div className="app-radio-input__radios">
					{props.options.map((o) => {
						const radioClassname = classNames({ "app-radio-input__radio": true, "app-radio-input__radio--active": o.value === props.value });

						return (
							<div className="app-radio-input__item" key={o.label}>
								<button disabled={props.disabled} type="button" className={radioClassname} onClick={() => onHandleChange(o.value)} />

								<div className="app-radio-input__column">
									<p className="app-radio-input__text">{o.label}</p>

									{o?.description && <p className="app-radio-input__description">{o?.description}</p>}

									{o?.tooltip && (
										<Tooltip title={o.tooltip} placement="top">
											<img src={faqIcon} alt="faq icon" className="app-radio-input__faq-icon" />
										</Tooltip>
									)}
								</div>
							</div>
						);
					})}
				</div>

				<FormHelperText>{errorMessage}</FormHelperText>
			</FormControl>
		</div>
	);
};

export default memo(AppRadioInput);

const optionPropsType = PropTypes.shape({
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	description: PropTypes.node || PropTypes.string
});

AppRadioInput.propTypes = {
	options: PropTypes.arrayOf(optionPropsType),
	disabled: PropTypes.bool,
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired
};
