const COMMON = {
	AUTH_TOKEN: "AUTH_TOKEN_MCUBE_134f3611-0366-42c6-955b-f531c2c9e81f",
	REFRESH_TOKEN: "REFRESH_TOKEN_MCUBE_134f3611-0366-42c6-955b-f531c2c9e81f",
	REDUX_PRESIST_KEY: "REDUX_PRESIST_KEY_134f3611-0366-42c6-955b-f531c2c9e81f",
	ACCESSIBLE_PATH: "ACCESSIBLE_PATH",
	REDUX_ACTION: {
		LOGOUT: "LOGOUT"
	},
	MONTH_LIST: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
	WEEK_LIST: [{label: "Monday", value: "Monday"}, {label: "Tuesday", value: "Tuesday"}, {label: "Wednesday", value: "Wednesday"}, {label: "Thursday", value: "Thursday"}, {label: "Friday", value: "Friday"}, {label: "Saturday", value: "Saturday"}, {label: "Sunday", value: "Sunday"}]
};

export default COMMON;
