import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef, useState, useEffect } from "react";

import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import classNames from "common/class-names";

import AppSelectInput from "components/app-select-input";

import chevronLeftIcon from "../assets/images/pages/general-configuration/chevron-left-icon.svg"
import chevronRightIcon from "../assets/images/pages/general-configuration/chevron-right-icon.svg"

const AppTabs = (props, ref) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [currentTab, setCurrentTab] = useState(searchParams.get("tab") || props.tabs.find((tab) => tab.accessible !== false)?.name);
	const [showLeftScroll, setShowLeftScroll] = useState(false);
	const [showRightScroll, setShowRightScroll] = useState(false);
	const tabsRef = useRef(null);

	const tabsWithDefaultAccessible = props.tabs.map((tab) => ({
		...tab,
		accessible: tab.accessible !== false
	}));

	const accessibleTabs = tabsWithDefaultAccessible.filter((tab) => tab.accessible);

	const onHandleCheckScroll = () => {
		const el = tabsRef.current;

		if (el) {
			const hasLeftScroll = el.scrollLeft > 0;
			const hasRightScroll = el.scrollLeft < el.scrollWidth - el.clientWidth;

			setShowLeftScroll(hasLeftScroll);
			setShowRightScroll(hasRightScroll);
		}
	};

	const onHandleScroll = (direction) => {
		const el = tabsRef.current;
		if (el) {
			const scrollAmount = direction === "left" ? -200 : 200;
			el.scrollBy({ left: scrollAmount, behavior: "smooth" });
		}
	};

	useEffect(() => {
		const el = tabsRef.current;

		if (el) {
			el.addEventListener("scroll", onHandleCheckScroll);

			onHandleCheckScroll();

			return () => el.removeEventListener("scroll", onHandleCheckScroll);
		}
	}, []);

	// prettier-ignore
	const onHandleSelectTab = useCallback((obj) => {
		if (currentTab === obj.name) return;

		if (props.onChange) {
			props.onChange(obj, currentTab);
		} else {
			if (!props.queryParamsDisabled) setSearchParams({ tab: obj.name });
			setCurrentTab(obj.name);
		}
	}, [setSearchParams, currentTab, props]);

	const onHandleRefSelectTab = useCallback((name) => {
		setCurrentTab(name);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleGetCurrentTab: () => currentTab,
		onHandleSelectTab: onHandleRefSelectTab
	}));

	return (
		<div className="app-tabs">
			<div className="tabs">
				<AppSelectInput className="tabs__select-input" searchable={false} options={accessibleTabs.map((o) => ({ ...o, value: o.name }))} value={currentTab} placeholder="Please Select..." onChange={(event) => onHandleSelectTab({ name: event.target.value })} />

				<div className="tabs__header-container">
					<div className="tabs__header-wrapper">
						{showLeftScroll && (
							<button className="tabs__scroll-btn tabs__scroll-btn--left" onClick={() => onHandleScroll("left")}>
								<img src={chevronLeftIcon} alt="Scroll left" />
							</button>
						)}

						<div className="tabs__header" ref={tabsRef}>
							{accessibleTabs.map((o, i) => {
								const iconIsNull = !o.icon || !o.activeIcon;
								const icon = o.name === currentTab ? o.activeIcon : o.icon;
								const tabClassName = classNames({
									tab: true,
									"tab--active": o.name === currentTab
								});

								return (
									<button type="button" className={tabClassName} key={i} onClick={() => onHandleSelectTab(o)} disabled={o.disabled}>
										<div className="tab__icon">
											{!iconIsNull && <img src={icon} alt={o.label} />}
											{iconIsNull && <span className="tab__number">{i + 1}</span>}
										</div>
										<p className="tab__label">{o.label}</p>
									</button>
								);
							})}
						</div>

						{showRightScroll && (
							<button className="tabs__scroll-btn tabs__scroll-btn--right" onClick={() => onHandleScroll("right")}>
								<img src={chevronRightIcon} alt="Scroll right" />
							</button>
						)}
					</div>
				</div>

				<div className="tabs__body">{accessibleTabs.find((o) => o.name === currentTab)?.component}</div>
			</div>
		</div>
	);
};

export default memo(forwardRef(AppTabs));

AppTabs.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			queryParamsDisabled: PropTypes.bool,
			icon: PropTypes.node,
			onChange: PropTypes.func,
			activeIcon: PropTypes.node,
			name: PropTypes.string.isRequired,
			component: PropTypes.element.isRequired,
			accessible: PropTypes.bool
		})
	)
};
