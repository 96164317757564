import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from "react";

import dayjs from "dayjs";
import { Modal } from "@mui/material";

import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import DATE_TIME from "constants/date-time";

import AppButton from "components/app-button";

export const AppOverwriteHoldOnModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [employeeId, setEmployeeId] = useState(null);
	const [customShifts, setCustomShifts] = useState([]);
	const [loading, setLoading] = useState(false);

	const onHandleConfirm = useCallback(() => {
		if (props.onSubmit && typeof props.onSubmit === "function") {
			props.onSubmit();
		}

		setVisible(false);
	}, [props]);

	const onHandleCustomShift = useCallback(() => {
		if (props.onMaintainCustomShift && typeof props.onMaintainCustomShift === "function") {
			props.onMaintainCustomShift();
		}

		setVisible(false);
	}, [props]);

	//prettier-ignore
	const onHandleShow = useCallback((id) => {
		setEmployeeId(id);
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	const onHandleBack = useCallback(() => {
		onHandleDismiss();
	}, [onHandleDismiss]);

	const onHandleGetCurrentShift = useCallback(async () => {
		let response = null;
		let today = new Date();
		setLoading(true);

		const params = {
			"employee-id": employeeId,
			attendanceScheduledDate: dayjs(today).format(DATE_TIME.YYYY_MM_DD)
		};

		try {
			response = await api.get.humanResource.getCurrentShift(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			setLoading(false);
		}

		if (response) {
			const filteredShifts = (response || []).filter((shift) => shift.dayType === "ADD_SHIFT").sort((a, b) => new Date(b.scheduledDate) - new Date(a.scheduledDate));

			setCustomShifts(filteredShifts);
		}
	}, [employeeId]);

	useEffect(() => {
		if (visible && employeeId && employeeId !== "create") {
			onHandleGetCurrentShift();
		} else {
			setCustomShifts([]);
		}
	}, [visible, employeeId, onHandleGetCurrentShift]);

	const onHandleFormatDate = useCallback((dateString) => {
		return dayjs(dateString).format(DATE_TIME.DD_MMM_YYYY_DDD);
	  }, []);

	  const onHandleFormatTime = useCallback((timeString) => {
		if (!timeString) return "";
		
		const today = dayjs().format(DATE_TIME.YYYY_MM_DD);
		const time = dayjs(`${today} ${timeString}`);
		
		return time.format(DATE_TIME.H_MM_A);
	  }, []);

	  const onHandleFormatTimeRange = useCallback((shift) => {
		if (!shift.configCostCenterWorkingShift) return "-";
	  
		const startTime = shift.configCostCenterWorkingShift.startTime;
		const endTime = shift.configCostCenterWorkingShift.endTime;
	  
		if (!startTime || !endTime) return shift.configCostCenterWorkingShift.shiftName || "-";
	  
		return `${onHandleFormatTime(startTime)} - ${onHandleFormatTime(endTime)}`;
	  }, [onHandleFormatTime]);

	const onHandleRenderShiftsTable = useCallback(() => {
		if (loading) {
			return <div className="overwrite-hold-on-modal__loading">Loading shifts data...</div>;
		}

		if (!customShifts.length) {
			return null;
		}

		return (
			<div className="overwrite-hold-on-modal__table-container">
				<table className="overwrite-hold-on-modal__table">
					<thead>
						<tr>
							<th>Day</th>
							<th>Current Cost Centre</th>
							<th>Current Start Time - End Time</th>
						</tr>
					</thead>
					<tbody>
						{customShifts.map((dayShift) => {
							const shifts = dayShift.shifts || [];

							if (!shifts.length) {
								return (
									<tr key={dayShift.id}>
										<td>{onHandleFormatDate(dayShift.scheduledDate)}</td>
										<td>-</td>
										<td>-</td>
									</tr>
								);
							}

							return shifts.map((shift, shiftIndex) => (
								<tr key={`${dayShift.id}-${shift.id}`}>
									{!shiftIndex && <td rowSpan={shifts.length}>{onHandleFormatDate(dayShift.scheduledDate)}</td>}
									<td>{shift.configCostCenter?.name || "-"}</td>
									<td>{onHandleFormatTimeRange(shift)}</td>
								</tr>
							));
						})}
					</tbody>
				</table>
			</div>
		);
	}, [loading, customShifts, onHandleFormatDate, onHandleFormatTimeRange]);

	return (
		<Modal classes={{ root: "app-overwrite-hold-on-modal" }} open={visible} disableAutoFocus={true} disableEnforceFocus={true} disableRestoreFocus={true}>
			<div className="overwrite-hold-on-modal">
				<h1 className="overwrite-hold-on-modal__title">Hold On!</h1>

				<p className="overwrite-hold-on-modal__description">You have these custom work shifts set on one or more dates, would you like to replace them?</p>

				{onHandleRenderShiftsTable()}

				<p className="overwrite-hold-on-modal__description overwrite-hold-on-modal__description--maintain-label">
					Clicking <strong>Maintain Custom Shifts</strong> will preserve these custom work shifts.
				</p>

				<p className="overwrite-hold-on-modal__description overwrite-hold-on-modal__description--overwrite-label">
					Clicking <strong>Overwrite</strong> will replace these custom work shifts with the shifts you have configured.
				</p>

				<div className="overwrite-hold-on-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

					<AppButton outline type="button" label="Maintain Custom Shifts" onClick={onHandleCustomShift} />

					<AppButton type="button" label="Overwrite" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppOverwriteHoldOnModal));
