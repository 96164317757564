import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

const getWorkingShiftListing = async (payload) => {
	let response = null;

	let errorMessage = null;

	try {
		const params = {
			"cost-center-id": payload.id,
			queryParams: {
				page: payload?.page !== undefined ? payload.page : 0,
				size: payload?.size || 10,
				keyword: payload?.keyword || "",
				status: payload?.status || null
			}
		};

		const res = await api.get.costCenter.searchWorkingShift(params);

		const sortedContent = res.content && res.content.length > 0  ? [...res.content].sort((a, b) => a.seq - b.seq) : [];

		const content = sortedContent.map(({ shiftName, id, ...o }) => ({ label: shiftName, value: id, ...o }));

		response = { ...res, content };
	} catch (error) {
		serveLayoutRequestErrors(error, (message) => {
			errorMessage = message;
		});
	}

	if (response) return response;

	throw errorMessage;
};

export default getWorkingShiftListing;
