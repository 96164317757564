import React, { Fragment, useCallback, useEffect, useMemo, useRef } from "react";

import * as yup from "yup";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useDispatch } from "react-redux";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import formatCurrencyPattern from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import getDayTypeListing from "services/get-dayType-listing"
import getOTRuleListing from "services/get-ot-rule-listing"

import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

import addIcon from "assets/images/blue-add-icon.svg";
import removeIcon from "assets/images/trash-icon.png";

const AppOTRateView = () => {
	const deleteRef = useRef();
	const dispatch = useDispatch();
	const otRateValues = useMemo(() => ({ id: "", dayType: "", rule: "", hours: "", rate: "" }), []);
	const initialValues = useMemo(() => ({ maximumAmount: "", daysWorked: "", otRateValues: [otRateValues] }), [otRateValues]);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Human Resources", path: pathnames.humanResources.overtimeManagement },
			{ label: "Overtime Management", path: pathnames.humanResources.overtimeManagement + "?tab=EMPLOYEE_DIRECTORY" },
			{ label: "All Overtime", path: pathnames.humanResources.overtimeManagement + "?tab=OT_RATE" }
		];

		return data;
	}, []);

	useBreadcrumb({ breadCrumb });

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			maximumAmount: yup.string().required(ERRORS.REQUIRED),
			daysWorked: yup.string().required(ERRORS.REQUIRED),
			otRateValues: yup.array().of(
				yup.object({
					dayType: yup.string().required(ERRORS.REQUIRED),
					rule: yup.string().required(ERRORS.REQUIRED),
					hours: yup.string().required(ERRORS.REQUIRED),
					rate: yup.string().required(ERRORS.REQUIRED)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const onHandleGetDetails = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.overtime.getOvertimeRate();
		} catch(error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				id: response?.id || "",
				maximumAmount: response?.maximumAmount?.toString() || "",
				daysWorked: response?.daysWorked?.toString() || "",
				otRateValues: response?.configOtRates?.map(rate => ({ id: rate.id || "", dayType: rate.dayType || "", rule: rate.rule || "", hours: rate.hours?.toString() || "", rate: rate.rate?.toString() || "" })) || [otRateValues]
			})
		}
	}, [memoSetFormValues, otRateValues]);

	//prettier-ignore
	const onHandleAddRate = useCallback((o) => {
            o.push(otRateValues);
        }, [otRateValues]);

	//prettier-ignore
	const onHandleRemoveRate = useCallback((object, index) => {
        const otRate = formik.values.otRateValues[index];
        
        if (otRate.id) {
            deleteRef.current.onHandleShow(otRate.id, object, index);
        } else {
            object.remove(index);
        }
    }, [formik.values.otRateValues]);

	const onHandleCancel = useCallback(() => {}, []);

	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				id: values.id || "",
				maximumAmount: parseFloat(values.maximumAmount),
				daysWorked: parseFloat(values.daysWorked),
				configOtRates: values.otRateValues.map((rate) => ({
					dayType: rate.dayType,
					rule: rate.rule,
					hours: parseFloat(rate.hours),
					rate: parseFloat(rate.rate),
					id: rate.id || ""
				}))
			};

			if (values.id) {
				response = await api.post.overtime.updateOvertime(payload);
			} else {
				response = await api.post.overtime.createOvertime(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response && values.id) {
			dispatch(promptLayoutAlertMessage({ message: "OT Rate was updated successfully!" }));
		} else {
			dispatch(promptLayoutAlertMessage({ message: "OT Rate was created successfully!" }));
		}
	}, [dispatch]);

	useEffect(() => {
		onHandleGetDetails();
	}, [onHandleGetDetails]);

	return (
		<FormikProvider value={formik}>
			<div className="app-ot-rate-view">
				<div className="ot-rate-view">
					<form className="ot-rate-view__form" onSubmit={formik.handleSubmit}>
						<div className="ot-rate-view__container">
							<div className="ot-rate-view__box">
								<div className="ot-rate-view__header">
									<p className="ot-rate-view__input-title">Details</p>
								</div>
								<div className="ot-rate-view__wrapper">
									<div className="ot-rate-view__box-body">
										<AppInput required type="text" name="maximumAmount" label="Maximum Amount (RM)" placeholder="Enter Maximum Amount (RM)" value={formik.values.maximumAmount} error={formik.errors.maximumAmount} touched={formik.touched.maximumAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

										<AppInput required type="number" name="daysWorked" label="Days Worked" placeholder="Enter Days Worked" value={formik.values.daysWorked} error={formik.errors.daysWorked} touched={formik.touched.daysWorked} onChange={formik.handleChange} />
									</div>
								</div>

								<div className="ot-rate-view__divider" />

								<FieldArray
									name="otRateValues"
									render={(o) => {
										const errors = formik.errors;
										const touched = formik.touched;
										const otRateValues = formik.values.otRateValues;

										return (
											<Fragment>
												<div className="ot-rate-view__header">
													<p className="ot-rate-view__input-title">OT Rate</p>

													<AppButton type="button" label="Add Rate" icon={addIcon} onClick={() => onHandleAddRate(o)} />
												</div>

												{otRateValues.map((rate, i) => (
													<div key={i} className="ot-rate-view__input">
														{otRateValues.length > 1 && (
															<div className="ot-rate-view__input-section">
																<button type="button" className="ot-rate-view__remove-button" disabled={otRateValues?.length <= 1} onClick={() => onHandleRemoveRate(o, i)}>
																	<img src={removeIcon} alt="remove-ot-rate" />
																</button>
															</div>
														)}

														<div className="ot-rate-view__inline-container">
															<AppSelectInput type="text" label="Day Type" name={`otRateValues.${i}.dayType`} placeholder="Please Select" value={rate.dayType} error={errors.otRateValues?.[i]?.dayType} touched={touched.otRateValues?.[i]?.dayType} loadOptions={getDayTypeListing} onChange={formik.handleChange} />

															<AppSelectInput type="text" label="Rule" name={`otRateValues.${i}.rule`} placeholder="Please Select" value={rate.rule} error={errors.otRateValues?.[i]?.rule} touched={touched.otRateValues?.[i]?.rule} loadOptions={getOTRuleListing} onChange={formik.handleChange} />

															<AppInput maxLength={3} type="text" name={`otRateValues.${i}.hours`} label="OT Hours" placeholder="Enter a No. of Hours" value={rate.hours} error={errors.otRateValues?.[i]?.hours} touched={touched.otRateValues?.[i]?.hours} onChange={formik.handleChange} />

															<AppInput maxLength={6} type="text" name={`otRateValues.${i}.rate`} label="Rate" placeholder="Enter a No. to multiply by" value={rate.rate} error={errors.otRateValues?.[i]?.rate} touched={touched.otRateValues?.[i]?.rate} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
														</div>
													</div>
												))}
											</Fragment>
										);
									}}
								/>
							</div>
						</div>

						<div className="ot-rate-view__button-container">
							<AppButton outline type="button" label="Cancel" onClick={onHandleCancel} />

							<AppButton type="submit" label={formik.values.id ? "Update" : "Add"} />
						</div>
					</form>
				</div>
			</div>
		</FormikProvider>
	);
};

export default AppOTRateView;
