import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ROLES from "constants/roles";
import ENDPOINT_PATH from "constants/end-point-path";

import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import eyeIcon from "assets/images/eye-open-icon.png";

const AppOvertimeManagementEmployeeDirectoryTable = () => {
	const navigate = useNavigate();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [data, setData] = useState(convertPaginationTableData());
	const profile = useSelector((state) => state.profile);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", tab: "EMPLOYEE_DIRECTORY" });
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CLAIM_TYPE_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Human Resources", path: pathnames.humanResources.overtimeManagement },
			{ label: "Overtime Management", path: pathnames.humanResources.overtimeManagement + "?tab=EMPLOYEE_DIRECTORY" },
			{ label: "All Overtime", path: pathnames.humanResources.overtimeManagement + "?tab=EMPLOYEE_DIRECTORY" }
		];

		return data;
	}, []);

	useBreadcrumb({ breadCrumb });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = { ...queryParams.current, size: 10 };

			memoSearchParams.current(params);

			response = await api.get.overtime.getEmployeeDirectoryOvertime(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onViewOvertime = useCallback((obj) => {
		navigate(pathnames.humanResources.viewOvertime + data.content[obj.rowIndex].id);
	}, [data, navigate]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "employeeId",
			label: "Employee ID",
			options: {
				sort: false
			}
		},
		{
			name: "fullName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "overtimeType",
			label: "Eligible For",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "otText",
			label: "Accumulated Overtime",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" disabled={restricted} className="table__action" onClick={() => onViewOvertime(tableMeta)}>
							<img src={eyeIcon} alt="expand-icon" />
						</button>
					);
				}
			}
		}
	], [onViewOvertime, restricted]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_DIRECTORY);
		};
	}, [cancelRequest]);

	return (
		<div className="app-claim-management-employee-directory-table">
			<div className="claim-management-employee-directory-table">
				<AppTableFilterHeader restricted={restricted} searchPlaceholder="Search by Name or Employee ID" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} />

				<div className="claim-management-employee-directory-table__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>
		</div>
	);
};

export default AppOvertimeManagementEmployeeDirectoryTable;
