const ROLES = {
	SUPER_ADMINISTRATOR: "SUPER_ADMINISTRATOR",

	EMPLOYEES: "employee",
	VIEW_EMPLOYEE_MAINTENANCE: "employee:view",
	CREATE_EMPLOYEE_MAINTENANCE: "employee:create",
	UPDATE_EMPLOYEE_MAINTENANCE: "employee:update",
	DELETE_EMPLOYEE_MAINTENANCE: "employee:delete",

	ROLE: "role",
	VIEW_ROLE: "role:view",
	CREATE_ROLE: "role:create",
	UPDATE_ROLE: "role:update",
	DELETE_ROLE: "role:delete",

	USER_ACCESS: "user-access",
	VIEW_USER_ACCESS: "user-access:view",
	CREATE_USER_ACCESS: "user-access:create",
	UPDATE_USER_ACCESS: "user-access:update",
	DELETE_USER_ACCESS: "user-access:delete",

	CONTRACT_CONFIG: "contract_config",
	VIEW_CONTRACT_CONFIG: "contract_config:view",
	CREATE_CONTRACT_CONFIG: "contract_config:create",
	UPDATE_CONTRACT_CONFIG: "contract_config:update",
	DELETE_CONTRACT_CONFIG: "contract_config:delete",

	INPUT_CONFIG: "input_config",
	VIEW_INPUT_CONFIG: "input_config:view",
	CREATE_INPUT_CONFIG: "input_config:create",
	UPDATE_INPUT_CONFIG: "input_config:update",
	DELETE_INPUT_CONFIG: "input_config:delete",

	SERVICE_CONFIG: "service_config",
	VIEW_SERVICE_CONFIG: "service_config:view",
	CREATE_SERVICE_CONFIG: "service_config:create",
	UPDATE_SERVICE_CONFIG: "service_config:update",
	DELETE_SERVICE_CONFIG: "service_config:delete",

	SERVICE_CHECKLIST_CONFIG: "service_checklist_config",
	VIEW_SERVICE_CHECKLIST_CONFIG: "service_checklist_config:view",
	CREATE_SERVICE_CHECKLIST_CONFIG: "service_checklist_config:create",
	UPDATE_SERVICE_CHECKLIST_CONFIG: "service_checklist_config:update",
	DELETE_SERVICE_CHECKLIST_CONFIG: "service_checklist_config:delete",

	SPARE_PART_CONFIG: "spare_part_config",
	VIEW_SPARE_PART_CONFIG: "spare_part_config:view",
	CREATE_SPARE_PART_CONFIG: "spare_part_config:create",
	UPDATE_SPARE_PART_CONFIG: "spare_part_config:update",
	DELETE_SPARE_PART_CONFIG: "spare_part_config:delete",
	UPLOAD_SPARE_PART_CONFIG: "spare_part_config:upload",

	SPARE_PART_CATEGORY_CONFIG: "spare_part_category_config",
	VIEW_SPARE_PART_CATEGORY_CONFIG: "spare_part_category_config:view",
	CREATE_SPARE_PART_CATEGORY_CONFIG: "spare_part_category_config:create",
	UPDATE_SPARE_PART_CATEGORY_CONFIG: "spare_part_category_config:update",

	CUSTOMER_USER: "customer-user",
	VIEW_CUSTOMER_USER: "customer-user:view",
	CREATE_CUSTOMER_USER: "customer-user:create",
	UPDATE_CUSTOMER_USER: "customer-user:update",
	DELETE_CUSTOMER_USER: "customer-user:delete",

	CUSTOMER_MAINTENANCE: "customer:maintenance",
	VIEW_CUSTOMER_MAINTENANCE: "customer:maintenance:view",
	CREATE_CUSTOMER_MAINTENANCE: "customer:maintenance:create",
	UPDATE_CUSTOMER_MAINTENANCE: "customer:maintenance:update",
	DELETE_CUSTOMER_MAINTENANCE: "customer:maintenance:delete",

	CUSTOMER_CONTRACT: "customer-contract",
	VIEW_CUSTOMER_CONTRACT: "customer-contract:view",
	CREATE_CUSTOMER_CONTRACT: "customer-contract:create",
	UPDATE_CUSTOMER_CONTRACT: "customer-contract:update",
	DELETE_CUSTOMER_CONTRACT: "customer-contract:delete",

	CUSTOMER_CONTRACT_PIC: "customer-contract-pic",
	VIEW_CUSTOMER_CONTRACT_PIC: "customer-contract-pic:view",
	CREATE_CUSTOMER_CONTRACT_PIC: "customer-contract-pic:create",
	UPDATE_CUSTOMER_CONTRACT_PIC: "customer-contract-pic:update",
	DELETE_CUSTOMER_CONTRACT_PIC: "customer-contract-pic:delete",

	CUSTOMER_CONTRACT_SITE: "customer-contract-site",
	VIEW_CUSTOMER_CONTRACT_SITE: "customer-contract-site:view",
	CREATE_CUSTOMER_CONTRACT_SITE: "customer-contract-site:create",
	UPDATE_CUSTOMER_CONTRACT_SITE: "customer-contract-site:update",
	DELETE_CUSTOMER_CONTRACT_SITE: "customer-contract-site:delete",

	CUSTOMER_CONTRACT_SITE_PIC: "customer-contract-site:pic",
	VIEW_CUSTOMER_CONTRACT_SITE_PIC: "customer-contract-site:pic:view",
	CREATE_CUSTOMER_CONTRACT_SITE_PIC: "customer-contract-site:pic:create",
	UPDATE_CUSTOMER_CONTRACT_SITE_PIC: "customer-contract-site:pic:update",
	DELETE_CUSTOMER_CONTRACT_SITE_PIC: "customer-contract-site:pic:delete",

	PROGRAM_TRAINING: "program-training",
	VIEW_PROGRAM_TRAINING: "program-training:view",
	CREATE_PROGRAM_TRAINING: "program-training:create",
	UPDATE_PROGRAM_TRAINING: "program-training:update",

	CLAIM_TYPE_CONFIG: "claim_type_config",
	VIEW_CLAIM_TYPE_CONFIG: "claim_type_config:view",
	CREATE_CLAIM_TYPE_CONFIG: "claim_type_config:create",
	UPDATE_CLAIM_TYPE_CONFIG: "claim_type_config:update",
	DELETE_CLAIM_TYPE_CONFIG: "claim_type_config:delete",

	DEPARTMENT_CONFIG: "department_config",
	VIEW_DEPARTMENT_CONFIG: "department_config:view",
	CREATE_DEPARTMENT_CONFIG: "department_config:create",
	UPDATE_DEPARTMENT_CONFIG: "department_config:update",
	DELETE_DEPARTMENT_CONFIG: "department_config:delete",

	HOLIDAY_CONFIG: "holiday_config",
	VIEW_HOLIDAY_CONFIG: "holiday_config:view",
	CREATE_HOLIDAY_CONFIG: "holiday_config:create",
	UPDATE_HOLIDAY_CONFIG: "holiday_config:update",
	DELETE_HOLIDAY_CONFIG: "holiday_config:delete",

	LEAVE_TYPE_CONFIG: "leave_type_config",
	VIEW_LEAVE_TYPE_CONFIG: "leave_type_config:view",
	CREATE_LEAVE_TYPE_CONFIG: "leave_type_config:create",
	UPDATE_LEAVE_TYPE_CONFIG: "leave_type_config:update",
	DELETE_LEAVE_TYPE_CONFIG: "leave_type_config:delete",

	POSITION_CONFIG: "position_config",
	VIEW_POSITION_CONFIG: "position_config:view",
	CREATE_POSITION_CONFIG: "position_config:create",
	UPDATE_POSITION_CONFIG: "position_config:update",
	DELETE_POSITION_CONFIG: "position_config:delete",

	POSITION_LEVEL_CONFIG: "position_level_config",
	VIEW_POSITION_LEVEL_CONFIG: "position_level_config:view",
	CREATE_POSITION_LEVEL_CONFIG: "position_level_config:create",
	UPDATE_POSITION_LEVEL_CONFIG: "position_level_config:update",
	DELETE_POSITION_LEVEL_CONFIG: "position_level_config:delete",

	REMUNERATION_PACKAGE: "remuneration-package",
	VIEW_REMUNERATION_PACKAGE: "remuneration-package:view",
	CREATE_REMUNERATION_PACKAGE: "remuneration-package:create",
	UPDATE_REMUNERATION_PACKAGE: "remuneration-package:update",
	DELETE_REMUNERATION_PACKAGE: "remuneration-package:delete",

	LEAVE_MANAGEMENT: "leave-management",
	VIEW_LEAVE_MANAGEMENT: "leave-management:view",
	CREATE_LEAVE_MANAGEMENT: "leave-management:create",
	UPDATE_LEAVE_MANAGEMENT: "leave-management:update",
	DELETE_LEAVE_MANAGEMENT: "leave-management:delete",
	APPROVE_LEAVE_MANAGEMENT: "leave-management:approve",
	DECLINE_LEAVE_MANAGEMENT: "leave-management:decline",

	CLAIM_MANAGEMENT: "claim-management",
	VIEW_CLAIM_MANAGEMENT: "claim-management:view",
	CREATE_CLAIM_MANAGEMENT: "claim-management:create",
	UPDATE_CLAIM_MANAGEMENT: "claim-management:update",
	DELETE_CLAIM_MANAGEMENT: "claim-management:delete",
	APPROVE_CLAIM_MANAGEMENT: "claim-management:approve",
	DECLINE_CLAIM_MANAGEMENT: "claim-management:decline",
	PAID_CLAIM_MANAGEMENT: "claim-management:paid",

	OVERTIME_MANAGEMENT: "overtime-management",
	VIEW_OVERTIME_MANAGEMENT: "overtime-management:view",
	CREATE_OVERTIME_MANAGEMENT: "overtime-management:create",
	UPDATE_OVERTIME_MANAGEMENT: "overtime-management:update",
	DELETE_OVERTIME_MANAGEMENT: "overtime-management:delete",
	APPROVE_OVERTIME_MANAGEMENT: "overtime-management:approve",
	DECLINE_OVERTIME_MANAGEMENT: "overtime-management:decline",
	PAID_OVERTIME_MANAGEMENT: "overtime-management:paid",

	ATTENDANCE_MANAGEMENT: "attendance-management",
	VIEW_ATTENDANCE_MANAGEMENT: "attendance-management:view",
	CREATE_ATTENDANCE_MANAGEMENT: "attendance-management:create",
	UPDATE_ATTENDANCE_MANAGEMENT: "attendance-management:update",
	APPROVE_ATTENDANCE_MANAGEMENT: "attendance-management:approve",
	DECLINE_ATTENDANCE_MANAGEMENT: "attendance-management:decline",

	ATTENDANCE_RECORD: "attendance-record",
	VIEW_ATTENDANCE_RECORD: "attendance-record:view",
	CREATE_ATTENDANCE_RECORD: "attendance-record:create",
	UPDATE_ATTENDANCE_RECORD: "attendance-record:update",

	WORK_INSPECTION: "work_inspection",
	VIEW_WORK_INSPECTION: "work_inspection:view",
	CREATE_WORK_INSPECTION: "work_inspection:create",
	UPDATE_WORK_INSPECTION: "work_inspection:update",
	UPLOAD_WORK_INSPECTION: "work_inspection:upload",
	APPROVE_WORK_INSPECTION: "work_inspection:approve",
	DECLINE_WORK_INSPECTION: "work_inspection:decline",
	DOWNLOAD_WORK_INSPECTION: "work_inspection:download",

	WORK_ORDER: "work_order",
	VIEW_WORK_ORDER: "work_order:view",
	CREATE_WORK_ORDER: "work_order:create",
	UPDATE_WORK_ORDER: "work_order:update",
	UPLOAD_WORK_ORDER: "work_order:upload",
	APPROVE_WORK_ORDER: "work_order:approve",
	DECLINE_WORK_ORDER: "work_order:decline",
	DOWNLOAD_WORK_ORDER: "work_order:download",

	WORK_ORDER_INVENTORY: "work-order-inventory",
	VIEW_WORK_ORDER_INVENTORY: "work-order-inventory:view",
	CREATE_WORK_ORDER_INVENTORY: "work-order-inventory:create",
	UPDATE_WORK_ORDER_INVENTORY: "work-order-inventory:update",

	WORK_COMPLETION: "work_completion",
	VIEW_WORK_COMPLETION: "work_completion:view",
	CREATE_WORK_COMPLETION: "work_completion:create",
	UPDATE_WORK_COMPLETION: "work_completion:update",
	UPLOAD_WORK_COMPLETION: "work_completion:upload",
	DOWNLOAD_WORK_COMPLETION: "work_completion:download",

	INVENTORY_LOCATION: "inventory-location",
	VIEW_INVENTORY_LOCATION: "inventory-location:view",
	CREATE_INVENTORY_LOCATION: "inventory-location:create",
	UPDATE_INVENTORY_LOCATION: "inventory-location:update",
	DELETE_INVENTORY_LOCATION: "inventory-location:delete",

	INVENTORY_HISTORY_MOVEMENT: "inventory-history-movement",
	VIEW_INVENTORY_HISTORY_MOVEMENT: "inventory-history-movement:view",

	INVENTORY_REQUEST: "request-inventory",
	VIEW_INVENTORY_REQUEST: "request-inventory:view",
	CREATE_INVENTORY_REQUEST: "request-inventory:create",
	UPDATE_INVENTORY_REQUEST: "request-inventory:update",
	UPLOAD_INVENTORY_REQUEST: "request-inventory:upload",
	DOWNLOAD_INVENTORY_REQUEST: "request-inventory:download",

	INVENTORY_HISTORY_REQUEST: "inventory-history-request",
	VIEW_INVENTORY_HISTORY_REQUEST: "inventory-history-request:view",
	UPDATE_INVENTORY_HISTORY_REQUEST: "inventory-history-request:update",
	UPLOAD_INVENTORY_HISTORY_REQUEST: "inventory-history-request:upload",

	QUOTATION: "quotation",
	VIEW_QUOTATION: "quotation:view",
	CREATE_QUOTATION: "quotation:create",
	UPDATE_QUOTATION: "quotation:update",

	SALES_ORDER: "sales-order",
	VIEW_SALES_ORDER: "sales-order:view",
	CREATE_SALES_ORDER: "sales-order:create",
	UPDATE_SALES_ORDER: "sales-order:update",
	DELETE_SALES_ORDER: "sales-order:view",

	DELIVERY_ORDER: "delivery-order",
	VIEW_DELIVERY_ORDER: "delivery-order:view",
	CREATE_DELIVERY_ORDER: "delivery-order:create",
	UPDATE_DELIVERY_ORDER: "delivery-order:update",

	INVOICE: "invoice",
	VIEW_INVOICE: "invoice:view",
	CREATE_INVOICE: "invoice:create",
	UPDATE_INVOICE: "invoice:update"
};

export default ROLES;
