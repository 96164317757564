import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import getUserStatusListing from "services/get-user-status-listing";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputTime from "components/app-input-time";
import AppSelectInput from "components/app-select-input";

import closeIcon from "assets/images/close-icon.png";

export const AppAddWorkingShiftModal = (props, ref) => {
	const { id } = useParams();
	const [visible, setVisible] = useState(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const initialValues = useMemo(() => {
		const values = {
			shiftName: "",
			status: "",
			startTime: "",
			endTime: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			shiftName: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			startTime: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			endTime: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isEditPic = useMemo(() => formik.values.number || formik.values.id, [formik]);
	const title = useMemo(() => (isEditPic ? "Edit Working Shift" : "Add Working Shift"), [isEditPic]);
	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const onHandleTimeConvertor = useCallback((timeString) => {
		const today = new Date();

		const [hours, minutes, seconds] = timeString.split(':');

		today.setHours(hours);
  		today.setMinutes(minutes);
  		today.setSeconds(seconds);

		return dayjs(today.toISOString());
	}, [])

	//prettier-ignore
	const onHandleShow = useCallback((values, workingShiftData) => {
		if (values) {
			const row = values.rowIndex;

			memoSetFormValues({
				shiftName: workingShiftData[row].shiftName,
				status: workingShiftData[row].status,
				startTime: onHandleTimeConvertor(workingShiftData[row].startTime),
				endTime: onHandleTimeConvertor(workingShiftData[row].endTime),
				id: workingShiftData[row].id || null,
			});
		}

		setVisible(true);
	}, [memoSetFormValues, onHandleTimeConvertor]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		const isCreateCustomerEditPic = values.number;

		if (id === PAGE.CREATE) {
			props.onConfirm(values);

			if (isCreateCustomerEditPic) props.onHandleEditItem(values);

			formik.setSubmitting(false);

			return onHandleDismiss();
		}

		props.onHandleAddUpdatePic(values);

		onHandleDismiss();
	}, [id, props, formik, onHandleDismiss]);

	useImperativeHandle(ref, () => ({
		onHandleShow,
		onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-add-working-shift-modal" }} open={visible}>
			<div className="add-working-shift-modal">
				<button type="button" className="add-working-shift-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="add-working-shift-modal__title">{title}</h1>

				<form className="add-working-shift-modal__form" onSubmit={formik.handleSubmit}>
					<div className="add-working-shift-modal__row">
						<AppInput required type="text" disabled={restricted} name="shiftName" label="Shift Name" placeholder="Enter Shift Name" value={formik.values.shiftName} error={formik.errors.shiftName} touched={formik.touched.shiftName} onChange={formik.handleChange} />

						<AppSelectInput required searchable={false} disabled={restricted} name="status" label="Status" placeholder="Select..." loadOptions={getUserStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
					</div>

					<div className="add-working-shift-modal__row">
						<AppInputTime required disabled={restricted} name="startTime" label="Start Time" placeholder="HH:MM" value={formik.values.startTime} error={formik.errors.startTime} touched={formik.touched.startTime} onChange={formik.setFieldValue} />
						
						<AppInputTime required disabled={restricted} name="endTime" label="End Time" placeholder="HH:MM" value={formik.values.endTime} error={formik.errors.endTime} touched={formik.touched.endTime} onChange={formik.setFieldValue} />
					</div>

					<div className="add-working-shift-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAddWorkingShiftModal));

AppAddWorkingShiftModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
