import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getCostCenterListing from "services/get-cost-center-listing";
import getWorkingShiftListing from "services/get-working-shift-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInputMultiSelect from "components/app-input-select-multiple";
import AppOverwriteHoldOnModal from "components/pages/human-resources/app-overwrite-hold-on-modal";

const AppShiftsInfo = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const submitLabel = isCreate ? "Add" : "Update";
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.EMPLOYEES], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [workingDaysListing, setWorkingDaysListing] = useState([]);
	const [selectedShifts, setSelectedShifts] = useState({});
	const holdOnModal = useRef();

	const initialValues = useMemo(() => {
		let data = {
			costCenterId: "",
			state: [],
			employeeWorkingDays: [
				{
					costCenterWorkingDayId: "",
					employeeWorkingShifts: [{ costCenterWorkingShiftId: "" }]
				}
			]
		};

		if (props.shift) {
			const costCenterId = props.shift.configCostCenter?.id || "";

			let stateList = [];
			if (props.shift.configCostCenter?.configCostCenterStates) {
				stateList = props.shift.configCostCenter.configCostCenterStates.filter((state) => state.status === "ACTIVE").map((state) => state.value);
			}

			let workingDays = [];

			if (props.shift.workingDays.length) {
				workingDays = props.shift.workingDays
					.filter((day) => day.configCostCenterWorkingDay)
					.map((day) => ({
						costCenterWorkingDayId: day.configCostCenterWorkingDay.id,
						employeeWorkingShifts: (day.workingShifts || [])
							.filter((shift) => shift.configCostCenterWorkingShift)
							.map((shift) => ({
								costCenterWorkingShiftId: shift.configCostCenterWorkingShift.id
							}))
					}))
					.filter((day) => day.employeeWorkingShifts.length > 0);
			}

			data = {
				costCenterId: costCenterId,
				state: stateList.join(", "),
				employeeWorkingDays: workingDays
			};
		}

		return data;
	}, [props.shift]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			costCenterId: yup.string().required(ERRORS.REQUIRED),
			employeeWorkingDays: yup.array().required(ERRORS.REQUIRED).min(1, ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	// prettier-ignore
	const onHandleGetCostCenter = useCallback(async (id) => {
		let response = null;
		let params = { id: id };

		try {
			response = await api.get.costCenter.getCostCenter(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				costCenterId: response.id,
				state: response.statesWithComma,
				employeeWorkingDays: []
			});

			const workingDaysToArray = response.configCostCenterWorkingDays.map((day, index) => ({
				seq: index,
				label: day.label,
				value: day.id
			}));

			setWorkingDaysListing(workingDaysToArray);

			setSelectedShifts({});
		}
	}, [memoSetFormValues]);

	// prettier-ignore
	const onHandleCostCenter = useCallback((event) => {
		const value = event.target.value;

		if (value) {
			formik.setFieldValue("employeeWorkingDays", []);

			setSelectedShifts({});
		}

		formik.setFieldValue("costCenterId", value);

		onHandleGetCostCenter(value);
	}, [formik, onHandleGetCostCenter]);

	const getWorkingShiftLoadOptions = useCallback(() => getWorkingShiftListing({ id: formik.values.costCenterId, status: STATUS.ACTIVE }), [formik.values.costCenterId]);

	const onHandleEmployeeWorkingDays = (event, workingDay) => {
		const shiftValues = event.target.value;

		setSelectedShifts((prev) => ({
			...prev,
			[workingDay.value]: shiftValues
		}));
	};

	useEffect(() => {
		if (!workingDaysListing.length) {
			return;
		}

		const formattedWorkingDays = workingDaysListing.map((day) => {
				const dayId = day.value;
				const shifts = selectedShifts[dayId];

				if (!Array.isArray(shifts) || !shifts.length) {
					return null;
				}

				return {
					costCenterWorkingDayId: dayId,
					employeeWorkingShifts: shifts.map((shiftId) => ({
						costCenterWorkingShiftId: shiftId
					}))
				};
		}).filter((item) => item !== null);

		memoSetFormValues({ employeeWorkingDays: formattedWorkingDays })
	}, [selectedShifts, workingDaysListing, memoSetFormValues]);

	useEffect(() => {
		if (props.shift && props.shift.configCostCenter?.id) {
			onHandleGetCostCenter(props.shift.configCostCenter.id);

			const initialSelectedShifts = {};

			props.shift.workingDays.forEach((day) => {
				if (day.configCostCenterWorkingDay && day.workingShifts) {
					initialSelectedShifts[day.configCostCenterWorkingDay.id] = day.workingShifts.filter((shift) => shift.configCostCenterWorkingShift).map((shift) => shift.configCostCenterWorkingShift.id);
				}
			});

			setSelectedShifts(initialSelectedShifts);
		}
	}, [props.shift, onHandleGetCostCenter]);

	// prettier-ignore
	const onHandlePreparePayload = useCallback((values) => {
		const filteredContacts = props.personal.emergencyContact.filter((contact) => contact.fullName.trim() !== "" && contact.mobileNo.trim() !== "" && contact.relationship.trim() !== "");

		const currentWorkingDays = [];

		workingDaysListing.forEach(day => {
			const dayId = day.value;
			const shifts = selectedShifts[dayId];
			
			if (!Array.isArray(shifts) || !shifts.length) {
				return;
			}
			
			const formattedShifts = shifts.map(shiftId => ({ costCenterWorkingShiftId: shiftId }));
			
			currentWorkingDays.push({
				costCenterWorkingDayId: dayId,
				employeeWorkingShifts: formattedShifts
			});
		});

			
		const payload = {
			personalInfo: {
				fullName: props.personal.fullName,
				identificationType: props.personal.identificationType,
				identificationNumber: props.personal.identificationNumber,
				passport: props.personal.passport,
				dateOfBirth: dayjs(props.personal.dateOfBirth).toISOString(),
				gender: props.personal.gender,
				nationality: props.personal.nationality,
				maritalStatus: props.personal.maritalStatus
			},
			personalInfoContact: {
				primaryMobileNo: props.personal.primaryMobileNo,
				primaryMobileNoPrefix: props.personal.primaryMobileNoPrefix,
				secondaryMobileNo: props.personal.secondaryMobileNo,
				secondaryMobileNoPrefix: props.personal.secondaryMobileNoPrefix,
				email: props.personal.email,
				sameAsCorrespondenceAddress: props.personal.sameAsCorrespondenceAddress ? "Y" : "N",
				correspondenceAddress1: props.personal.correspondenceAddress1,
				correspondenceAddress2: props.personal.correspondenceAddress2,
				correspondenceRegion: props.personal.correspondenceRegion,
				correspondencePostcode: props.personal.correspondencePostcode,
				correspondenceState: props.personal.correspondenceState,
				residentialAddress1: props.personal.sameAsCorrespondenceAddress ? props.personal.correspondenceAddress1 : props.personal.residentialAddress1,
				residentialAddress2: props.personal.sameAsCorrespondenceAddress ? props.personal.correspondenceAddress2 : props.personal.residentialAddress2,
				residentialRegion: props.personal.sameAsCorrespondenceAddress ? props.personal.correspondenceRegion : props.personal.residentialRegion,
				residentialPostcode: props.personal.sameAsCorrespondenceAddress ? props.personal.correspondencePostcode : props.personal.residentialPostcode,
				residentialState: props.personal.sameAsCorrespondenceAddress ? props.personal.correspondenceState : props.personal.residentialState
			},
			personalInfoEmergencyContact: filteredContacts,
			familyInfo: props.family.familyInfo.map((o) => ({ ...o, dateOfBirth: dayjs(o.dateOfBirth).toISOString() })),
			employeeDetail: {
				workEmail: props.employee.workEmail,
				employmentStatus: props.employee.employmentStatus,
				reportingManagerId: props.employee.reportingManagerId,
				supervisorId: props.employee.supervisorId,
				positionId: props.employee.positionId,
				departmentId: props.employee.departmentId,
				jobLevelId: props.employee.jobLevelId,
				leaveBenefitPackageId: props.employee.leaveBenefitPackageId,
				claimBenefitPackageId: props.employee.claimBenefitPackageId,
				overtimeType: props.employee.overtimeType,
				packageId: props.employee.packageId,
				dateJoined: dayjs(props.employee.dateJoined).toISOString(),
				probationEndDate: dayjs(props.employee.probationEndDate).toISOString(),
				lastEmploymentDate: props.employee.lastEmploymentDate ? dayjs(props.employee.lastEmploymentDate).toISOString() : ""
			},
			employeeContribution: {
				epfNo: props.employee.epfNo || "",
				incomeTaxNo: props.employee.incomeTaxNo || "",
				socsoNo: props.employee.socsoNo || ""
			},
			employeeBank: {
				bankName: props.employee.bankName,
				bankAccount: props.employee.bankAccount,
				swiftCode: props.employee.swiftCode
			},
			employeeShift: {
				costCenterId: values.costCenterId,
				employeeWorkingDays: currentWorkingDays
			},
			remark: props.employee.remark
		};

		if (!isCreate) {
			payload.attendanceScheduledDate = new Date();
			payload.id = id;
		}

		return payload;
	}, [props, isCreate, id, selectedShifts, workingDaysListing]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		formik.setSubmitting(true);
		
		try {
			const payload = onHandlePreparePayload(values);
			
			if (isCreate) {
				await api.post.humanResource.createEmployee(payload);
			}
			
			if (!isCreate) {
				await api.post.humanResource.updateEmployee(payload);
			}

			response = true;
		} catch (error) {
			formik.setSubmitting(false);

			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "User has added successfully" }));
			}
			
			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "User has updated successfuly" }));
			}

			navigate(pathnames.humanResources.employeeMaintenance);
		}
	}, [isCreate, formik, dispatch, navigate, onHandlePreparePayload]);

	const onHandleMaintainCustomShift = useCallback(async () => {
		let response = null;

		formik.setSubmitting(true);

		try {
			const payload = onHandlePreparePayload(formik.values);

			if (!isCreate) {
				await api.post.humanResource.updateMaintainCustomShift(payload);
			}

			response = true;
		} catch (error) {
			formik.setSubmitting(false);
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "User has been updated while maintaining custom shifts" }));

			navigate(pathnames.humanResources.employeeMaintenance);
		}
	}, [formik, isCreate, dispatch, navigate, onHandlePreparePayload]);

	const onHandleCancel = () => {
		navigate(pathnames.humanResources.employeeMaintenance);
	};

	useImperativeHandle(ref, () => ({
		onHandleSubmit: formik.handleSubmit
	}));

	// prettier-ignore
	const onHandleOnHoldModal = useCallback((e) => {
		if (submitLabel === "Add") {
			formik.handleSubmit();
		} else {
			e.preventDefault();

			holdOnModal.current.onHandleShow(id);
		}
	}, [id, formik, submitLabel]);

	return (
		<div className="app-shifts-info">
			<div className="shifts-info">
				<form className="shifts-info__form" onSubmit={formik.handleSubmit}>
					<div className="shifts-info__container">
						<div className="shifts-info__label">Shift</div>

						<div className="shifts-info__row">
							<AppSelectInput required searchable={true} disabled={restricted} name="costCenterId" label="Cost Centre" placeholder="Select..." loadOptions={getCostCenterListing} value={formik.values.costCenterId} error={formik.errors.costCenterId} touched={formik.touched.costCenterId} onChange={onHandleCostCenter} />

							<AppInput disabled name="state" type="text" required label="State" placeholder="State" touched={formik.touched.state} error={formik.errors.state} value={formik.values.state} />
						</div>

						{!!workingDaysListing.length &&
							workingDaysListing.map((weekdays, index) => (
								<div className="shifts-info__row shifts-info__row--works" key={weekdays.seq}>
									<AppInput disabled name="workingDays" type="text" label={weekdays.seq === 0 ? "Working Days" : ""} placeholder="Working Days" value={weekdays.label} />

									<AppInputMultiSelect required searchable={false} menuPropsRootClasses={["employeeWorkingDays-multi-select-popup"]} label={weekdays.seq === 0 ? "Work Shifts" : ""} name="employeeWorkingDays" placeholder="Select..." loadOptions={getWorkingShiftLoadOptions} value={selectedShifts[weekdays.value] || []} error={formik.errors.employeeWorkingDays} touched={formik.touched.employeeWorkingDays} onChange={(value) => onHandleEmployeeWorkingDays(value, weekdays)} />
								</div>
							))}
					</div>

					<div className="shifts-info__button-container">
						<AppButton outline type="button" onClick={onHandleCancel} label="Cancel" />

						<AppButton type="button" label={submitLabel} disabled={formik.isSubmitting || restricted} onClick={onHandleOnHoldModal} />
					</div>
				</form>
			</div>

			<AppOverwriteHoldOnModal ref={holdOnModal} onMaintainCustomShift={onHandleMaintainCustomShift} onSubmit={formik.handleSubmit} />
		</div>
	);
};

export default memo(forwardRef(AppShiftsInfo));
