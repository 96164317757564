import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";
import getCostCenterListing from "services/get-cost-center-listing";
import getWorkingShiftListing from "services/get-working-shift-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import capitalizeCharacter from "common/capitalize-character";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppInputTime from "components/app-input-time";
import AppSelectInput from "components/app-select-input";
import AppCalendarSchedule from "components/app-calendar-schedule/app-calendar-schedule";

import addIcon from "assets/images/add-blue-icon.png";
import deleteIcon from "assets/images/trash-icon.png";
import toolIcon from "assets/images/pages/human-resources/tool-icon.svg";
import umbrellaIcon from "assets/images/pages/human-resources/umbrella-icon.svg";
import verticalBreadCrumbsIcon from "assets/images/vertical-breadcrumbs-icon.png";

const AppScheduledAttendanceEmployeeCalendar = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState([]);
	const [editingStatus, setEditingStatus] = useState(false);
	const calendarRef = useRef(null);
	const [expandedRows, setExpandedRows] = useState({});
	const [groupedDate, setGroupedDate] = useState([]);
	const selectedItem = useRef();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });

	// prettier-ignore
	const formik = useFormik({
		initialValues: {
			id: [],
			startTime: [],
			endTime: [],
			costCentre: [],
			costCentreId: [],
			workShift: [],
			workShiftId: []
		},
		validationSchema: yup.object({
			startTime: yup.array().of(
				yup.array().of(
					yup.mixed().nullable().test("required-when-endTime", ERRORS.REQUIRED, function (value, context) {
						const [seqIndex, timeIndex] = context.path.match(/\[(\d+)\]\[(\d+)\]/) || [];

						if (!seqIndex || !timeIndex) return true;

						const clockOutValue = context.options.context.endTime?.[seqIndex]?.[timeIndex];

						if (!clockOutValue) return true;

						return value !== null && value !== undefined;
					})
				)
			),
			endTime: yup.array().of(
				yup.array().of(
					yup.mixed().nullable().test("required-when-startTime", ERRORS.REQUIRED, function (value, context) {
						const [seqIndex, timeIndex] = context.path.match(/\[(\d+)\]\[(\d+)\]/) || [];

						if (!seqIndex || !timeIndex) return true;

						const clockInValue = context.options.context?.startTime?.[seqIndex]?.[timeIndex];

						if (!clockInValue) return true;

						return value !== null && value !== undefined;
					})
				)
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	// prettier-ignore
	const onHandleGetList = useCallback(async (currentDate) => {
		let response = null;

		try {
			let currentMonth = new Date().getMonth() + 1 === parseInt(dayjs(currentDate.split("T")[0]).format("M"), 10);

			const payload = { employeeId: id, currentMonth: currentMonth, attendanceScheduledDate: currentDate };

			response = await api.post.humanResource.scheduledAttendanceEmployee(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const groupedByDate = {};
			const processedData = [];
			const scheduleIds = {};

			response?.forEach((scheduledDay) => {
				const date = dayjs(scheduledDay.scheduledDate).format("YYYY-MM-DD");
				const dayIndex = scheduledDay.seq - 1;

				scheduleIds[date] = scheduledDay.id;

				if (!groupedByDate[dayIndex]) {
					groupedByDate[dayIndex] = [];
				}

				if (scheduledDay.shifts.length) {
					scheduledDay.shifts.forEach((shift) => {
						const processedShift = {
							id: shift.id,
							scheduleId: scheduledDay.id,
							seq: scheduledDay.seq,
							date,
							costCentre: scheduledDay.dayType === "REST_DAY" ? capitalizeCharacter(scheduledDay.dayType) : shift.configCostCenter?.name,
							costCentreId: shift.configCostCenter?.id || null,
							workShift: shift.configCostCenterWorkingShift?.shiftName || null,
							workShiftId: shift.configCostCenterWorkingShift?.id || null,
							startTime: shift.configCostCenterWorkingShift?.startTime || dayjs(shift.clockIn).format(DATE_TIME.HH_MM_SS),
							endTime: shift.configCostCenterWorkingShift?.endTime || dayjs(shift.clockOut).format(DATE_TIME.HH_MM_SS),
							status: scheduledDay.status,
							dayType: scheduledDay.dayType
						};

						groupedByDate[dayIndex].push(processedShift);
						processedData.push(processedShift);
					});
				} else {
					const placeholderShift = {
						id: null,
						scheduleId: scheduledDay.id,
						seq: scheduledDay.seq,
						date,
						costCentre: scheduledDay.dayType === "REST_DAY" ? capitalizeCharacter(scheduledDay.dayType) : null,
						costCentreId: null,
						workShift: null,
						workShiftId: null,
						startTime: null,
						endTime: null,
						status: scheduledDay.status,
						dayType: scheduledDay.dayType
					};

					groupedByDate[dayIndex].push(placeholderShift);
					processedData.push(placeholderShift);
				}
			});

			setGroupedDate(groupedByDate);
			setData(processedData);
		}
	}, [id]);

	const onHandleDateConverter = useCallback((time) => {
		if (!time) return null;

		try {
			const [hours, minutes, seconds] = time.split(":");
			const today = new Date();
			const year = today.getFullYear();
			const month = today.getMonth() + 1;
			const day = today.getDate();

			return dayjs(new Date(year, month - 1, day, hours, minutes, seconds)).format();
		} catch (e) {
			serveLayoutRequestErrors(e);

			return null;
		}
	}, []);

	const editableDate = useCallback(() => {
		let id = [];
		let startTime = [];
		let endTime = [];
		let costCentre = [];
		let costCentreId = [];
		let workShift = [];
		let workShiftId = [];

		data.forEach((shift) => {
			const dayIndex = shift.seq - 1;

			if (shift) {
				if (!id[dayIndex]) {
					id[dayIndex] = [];
					startTime[dayIndex] = [];
					endTime[dayIndex] = [];
					costCentre[dayIndex] = [];
					costCentreId[dayIndex] = [];
					workShift[dayIndex] = [];
					workShiftId[dayIndex] = [];
				}

				id[dayIndex].push(shift.id ?? null);
				startTime[dayIndex].push(shift.startTime ? dayjs(onHandleDateConverter(shift.startTime)) : null);
				endTime[dayIndex].push(shift.endTime ? dayjs(onHandleDateConverter(shift.endTime)) : null);
				costCentre[dayIndex].push(shift.costCentre ?? null);
				costCentreId[dayIndex].push(shift.costCentreId ?? null);
				workShift[dayIndex].push(shift.workShift ?? null);
				workShiftId[dayIndex].push(shift.workShiftId ?? null);
			}
		});

		formik.setValues({ id: id, startTime: startTime, endTime: endTime, costCentre: costCentre, costCentreId: costCentreId, workShift: workShift, workShiftId: workShiftId });

		calendarRef.current.onHandleSetValue({ id: id, startTime: startTime, endTime: endTime, costCentreId: costCentreId, workShift: workShift, workShiftId: workShiftId });
	}, [data, formik, onHandleDateConverter]);

	// prettier-ignore
	const onHandleSetEditing = useCallback((status, date) => {
		if (!status) {
			formik.resetForm();

			onHandleGetList(date);
		}

		setEditingStatus(status);
	}, [formik, onHandleGetList]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		const scheduleDates = {};

		if (!values.id) return;

		for (let dayIndex = 0; dayIndex < values.startTime?.length; dayIndex++) {
			let dayDate = "";

			const dayDataEntry = data.find((item) => item.seq === dayIndex + 1);

			if (dayDataEntry && dayDataEntry.date) {
				dayDate = dayDataEntry.date;
			} else {
				dayDate = `2025-03-${String(dayIndex + 1).padStart(2, "0")}`;
			}

			const dayScheduleEntry = data.find((item) => item.date === dayDate);

			let scheduleId = null;

			if (dayScheduleEntry) {
				scheduleId = dayScheduleEntry.scheduleId;
			}

			if (!scheduleDates[dayDate]) {
				scheduleDates[dayDate] = { id: scheduleId, shifts: [] };
			}

			for (let shiftIndex = 0; shiftIndex < values.startTime[dayIndex].length; shiftIndex++) {
				const startTime = values.startTime[dayIndex][shiftIndex];
				const endTime = values.endTime[dayIndex][shiftIndex];
				const costCentreId = values.costCentreId?.[dayIndex]?.[shiftIndex];
				const workShiftId = values.workShiftId?.[dayIndex]?.[shiftIndex];
				const shiftId = values.id?.[dayIndex]?.[shiftIndex];
				const workShiftValue = values.workShift?.[dayIndex]?.[shiftIndex];


				if (!costCentreId || !workShiftId) {
					continue;
				}

				if (!startTime || !endTime) {
					continue;
				}

				let shiftStatus = "ACTIVE";

				if (workShiftValue === "Custom") {
					shiftStatus = "CUSTOM";
				}

				scheduleDates[dayDate].shifts.push({
					id: shiftId || null,
					configCostCenterId: costCentreId,
					configCostCenterWorkingShiftId: workShiftId,
					clockIn: startTime.toISOString(),
					clockOut: endTime.toISOString(),
					status: shiftStatus
				});
			}
		}

		const filteredScheduleDates = Object.entries(scheduleDates).reduce((acc, [date, schedule]) => {
			if (schedule.shifts.length) {
				acc[date] = schedule;
			}

			return acc;
		}, {});

		const scheduleData = Object.values(filteredScheduleDates);

		if (!scheduleData.length) {
			return;
		}

		let response = null;

		try {
			await api.post.humanResource.updateScheduledAttendanceEmployee(scheduleData);
			
			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			calendarRef.current.onHandleShow(values);

			onHandleGetList(dayjs(new Date()).format(DATE_TIME.YYYY_MM_DD));

			dispatch(promptLayoutAlertMessage({ message: "Schedule Attendance was updated successfully!" }));
		}

		calendarRef.current.updateMonthAndYear();
	}, [data, onHandleGetList, dispatch]);

	// prettier-ignore
	const onHandleChange = useCallback(async (name, value) => {
		await formik.setFieldValue(name, value);

		calendarRef.current.onHandleFormik(name, value);
	}, [formik]);

	// prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		const rowDate = tableMeta.rowData[0];

		const matchingItems = data.filter((item) => item.date === rowDate);

		if (matchingItems.length) {
			selectedItem.current = { ...matchingItems[0] };
		} else {
			selectedItem.current = {
				scheduleId: null,
				seq: dayjs(rowDate).date(),
				date: rowDate,
				costCentre: null,
				costCentreId: null,
				workShift: null,
				workShiftId: null,
				startTime: null,
				endTime: null,
				dayType: "ADD_SHIFT"
			};
		}

		setMenuAnchor(event.currentTarget);
	}, [data]);

	const onHandleCloseAttendanceMenu = useCallback(() => {
		selectedItem.current = null;

		setMenuAnchor(null);
	}, []);

	// prettier-ignore
	const onHandleDeleteScheduledDate = useCallback(async (shiftId) => {
		let response = null;

		let params = {
			"shift-id": shiftId
		};

		try {
			response = await api.post.humanResource.deleteScheduledAttendanceEmployee(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			onHandleGetList(dayjs(new Date()).format(DATE_TIME.YYYY_MM_DD));
		}
	}, [onHandleGetList]);

	// prettier-ignore
	const onHandleRemoveScheduledDate = useCallback((tableMeta, index) => {
		const rowDate = tableMeta.rowData[0];
		const currentDay = dayjs(rowDate).date() - 1;
		const dateGroup = groupedDate[currentDay];

		if (!dateGroup || !dateGroup.length) return;

		const itemToRemove = dateGroup[index];
		const shiftId = itemToRemove.id;

		formik.setFieldValue("id", formik.values.id.map((idGroup, seqIndex) => (seqIndex === currentDay ? idGroup.filter((_, i) => i !== index) : idGroup)));
		formik.setFieldValue("startTime", formik.values.startTime.map((startTimeGroup, seqIndex) => (seqIndex === currentDay ? startTimeGroup.filter((_, i) => i !== index) : startTimeGroup)));
		formik.setFieldValue("endTime", formik.values.endTime.map((endTimeGroup, seqIndex) => (seqIndex === currentDay ? endTimeGroup.filter((_, i) => i !== index) : endTimeGroup)));
		formik.setFieldValue("costCentreId", formik.values.costCentreId.map((costCentreGroup, seqIndex) => (seqIndex === currentDay ? costCentreGroup.filter((_, i) => i !== index) : costCentreGroup)));
		formik.setFieldValue("workShiftId", formik.values.workShiftId.map((workShiftGroup, seqIndex) => (seqIndex === currentDay ? workShiftGroup.filter((_, i) => i !== index) : workShiftGroup)));

		const updatedGroupedDate = {
			...groupedDate,
			[currentDay]: dateGroup.filter((_, i) => i !== index)
		};

		setGroupedDate(updatedGroupedDate);

		setData((prevData) => prevData.filter((item) => !(item.date === rowDate && item.id === itemToRemove.id)));

		if (shiftId) {
			onHandleDeleteScheduledDate(shiftId);
		}
	}, [groupedDate, formik, onHandleDeleteScheduledDate]);

	const onHandleAddShift = useCallback(() => {
		if (!selectedItem.current) {
			onHandleCloseAttendanceMenu();

			return;
		}

		if (!selectedItem.current.date || typeof selectedItem.current.seq !== "number") {
			if (selectedItem.current.date) {
				selectedItem.current.seq = dayjs(selectedItem.current.date).date();
			} else {
				onHandleCloseAttendanceMenu();

				return;
			}
		}

		const seq = selectedItem.current.seq - 1;

		if (!formik.values.id) formik.values.id = [];
		if (!formik.values.startTime) formik.values.startTime = [];
		if (!formik.values.endTime) formik.values.endTime = [];
		if (!formik.values.costCentre) formik.values.costCentre = [];
		if (!formik.values.costCentreId) formik.values.costCentreId = [];
		if (!formik.values.workShift) formik.values.workShift = [];
		if (!formik.values.workShiftId) formik.values.workShiftId = [];

		if (!formik.values.id[seq]) formik.values.id[seq] = [];
		if (!formik.values.startTime[seq]) formik.values.startTime[seq] = [];
		if (!formik.values.endTime[seq]) formik.values.endTime[seq] = [];
		if (!formik.values.costCentre[seq]) formik.values.costCentre[seq] = [];
		if (!formik.values.costCentreId[seq]) formik.values.costCentreId[seq] = [];
		if (!formik.values.workShift[seq]) formik.values.workShift[seq] = [];
		if (!formik.values.workShiftId[seq]) formik.values.workShiftId[seq] = [];

		try {
			const updateField = (fieldName) => {
				const currentArray = formik.values[fieldName][seq] || [];
				formik.setFieldValue(`${fieldName}[${seq}]`, [...currentArray, null]);
			};

			updateField("id");
			updateField("startTime");
			updateField("endTime");
			updateField("costCentre");
			updateField("costCentreId");
			updateField("workShift");
			updateField("workShiftId");
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		try {
			setGroupedDate((prevGroupedDate) => {
				const currentItems = prevGroupedDate[seq] || [];

				const newItem = {
					id: null,
					scheduleId: selectedItem.current?.scheduleId || null,
					seq: selectedItem.current?.seq || seq + 1,
					date:
						selectedItem.current?.date ||
						dayjs()
							.date(seq + 1)
							.format("YYYY-MM-DD"),
					startTime: null,
					endTime: null,
					costCentre: null,
					costCentreId: null,
					workShift: null,
					workShiftId: null
				};

				return {
					...prevGroupedDate,
					[seq]: [...currentItems, newItem]
				};
			});
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		try {
			setData((prevData) => {
				const newItem = {
					id: null,
					scheduleId: selectedItem.current?.scheduleId || null,
					seq: selectedItem.current?.seq || seq + 1,
					date:
						selectedItem.current?.date ||
						dayjs()
							.date(seq + 1)
							.format("YYYY-MM-DD"),
					startTime: null,
					endTime: null,
					costCentre: null,
					costCentreId: null,
					workShift: null,
					workShiftId: null
				};

				return [...prevData, newItem];
			});
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		try {
			setExpandedRows((prev) => ({
				...prev,
				[seq]: true
			}));
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		onHandleCloseAttendanceMenu();
	}, [formik, onHandleCloseAttendanceMenu]);

	const onHandleSetAsRestDay = useCallback(async () => {
		if (!selectedItem.current || !selectedItem.current.scheduleId) {
			return;
		}

		let params = {
			"scheduled-id": selectedItem.current.scheduleId
		};

		try {
			const response = await api.post.humanResource.setAsDayRestScheduledAttendanceEmployee(params);

			if (response) {
				const currentDate = dayjs(new Date()).format(DATE_TIME.YYYY_MM_DD);
				onHandleGetList(currentDate);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		onHandleCloseAttendanceMenu();
	}, [onHandleGetList, onHandleCloseAttendanceMenu]);

	const onHandleSetAsWorkingDay = useCallback(async () => {
		if (!selectedItem.current || !selectedItem.current.scheduleId) {
			return;
		}

		let params = {
			"scheduled-id": selectedItem.current.scheduleId
		};

		try {
			const response = await api.post.humanResource.setAsDayWorkingScheduledAttendanceEmployee(params);

			if (response) {
				const currentDate = dayjs(new Date()).format(DATE_TIME.YYYY_MM_DD);
				
				onHandleGetList(currentDate);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		onHandleCloseAttendanceMenu();
	}, [onHandleGetList, onHandleCloseAttendanceMenu]);

	const handleRowExpand = useCallback((rowIndex) => {
		setExpandedRows((prev) => ({
			...prev,
			[rowIndex]: !prev[rowIndex]
		}));
	}, []);

	// prettier-ignore
	const onHandleChangeWorkShift = useCallback((event, currentDay, index) => {
		const workShiftId = event.target.value;

		const getSelectedWorkShift = async () => {
			try {
				const costCentreId = formik.values.costCentreId[currentDay][index];
				if (!costCentreId) return;

				const params = {
					"cost-center-id": costCentreId,
					queryParams: sanitizeObject({ ...queryParams.current, size: 10 })
				};

				const response = await api.get.costCenter.searchWorkingShift(params);

				if (response.content) {
					const selectedShift = response.content.find((shift) => shift.id === workShiftId);

					if (selectedShift) {
						formik.setFieldValue(`workShiftId[${currentDay}][${index}]`, workShiftId);
						formik.setFieldValue(`workShift[${currentDay}][${index}]`, selectedShift.shiftName);

						if (selectedShift.shiftName === "Custom" || selectedShift.status === "CUSTOM") {
							formik.setFieldValue(`startTime[${currentDay}][${index}]`, null);
							formik.setFieldValue(`endTime[${currentDay}][${index}]`, null);
						}

						if (selectedShift.startTime) {
							const startTime = onHandleDateConverter(selectedShift.startTime);
							formik.setFieldValue(`startTime[${currentDay}][${index}]`, dayjs(startTime));
						}

						if (selectedShift.endTime) {
							const endTime = onHandleDateConverter(selectedShift.endTime);
							formik.setFieldValue(`endTime[${currentDay}][${index}]`, dayjs(endTime));
						}
					}
				}
			} catch (error) {
				serveLayoutRequestErrors(error);
			}
		};

		getSelectedWorkShift();
	}, [formik, onHandleDateConverter]);

	// prettier-ignore
	const getWorkingShiftLoadOptions = useCallback((costCentreId) => {
		return () => {
			if (!costCentreId) {
				return Promise.resolve([]);
			}

			return getWorkingShiftListing({ id: costCentreId });
		};
	}, []);

	// prettier-ignore
	const renderExpandableCell = useCallback((value, tableMeta, formatFn, loadOptions, options, changeHandler) => {
		const { rowData, rowIndex, columnData } = tableMeta;
		const currentDay = dayjs(rowData[0]).date() - 1;
		const isOnLeave = groupedDate[currentDay]?.[0]?.status === STATUS.ON_LEAVE;

		if (isOnLeave) {
			return columnData.name === "endTime" ? <AppStatus status={data.content[rowIndex]?.status} /> : null;
		}

		const isExpanded = expandedRows[rowIndex];

		if (editingStatus) {
			const fieldValue = formik.values[columnData.name]?.[currentDay] || [];
			const fieldError = formik.errors[columnData.name]?.[currentDay] || [];
			const fieldTouched = formik.touched[columnData.name]?.[currentDay] || [];

			if (columnData.name === "costCentreId") {
				const handleCostCenterChange = (e, index) => {
					const costCenterId = e.target.value;

					formik.setFieldValue(`costCentreId[${currentDay}][${index}]`, costCenterId);
					formik.setFieldValue(`workShiftId[${currentDay}][${index}]`, null);
					formik.setFieldValue(`workShift[${currentDay}][${index}]`, null);
					formik.setFieldValue(`startTime[${currentDay}][${index}]`, null);
					formik.setFieldValue(`endTime[${currentDay}][${index}]`, null);
				};

				return <div>{!isExpanded ? <AppSelectInput searchable={false} name={`${columnData.name}[${currentDay}][0]`} loadOptions={loadOptions} value={fieldValue[0] || null} error={fieldError[0] || null} touched={fieldTouched[0] || null} onChange={(e) => handleCostCenterChange(e, 0)} /> : fieldValue.map((item, index) => <AppSelectInput key={index} searchable={false} name={`${columnData.name}[${currentDay}][${index}]`} loadOptions={loadOptions} value={fieldValue[index] || null} error={fieldError[index] || null} touched={fieldTouched[index] || null} onChange={(e) => handleCostCenterChange(e, index)} />)}</div>;
			}

			if (columnData.name === "workShiftId") {
				return <div>{!isExpanded ? <AppSelectInput searchable={false} name={`${columnData.name}[${currentDay}][0]`} loadOptions={getWorkingShiftLoadOptions(formik.values.costCentreId[currentDay]?.[0])} value={fieldValue[0] || null} error={fieldError[0] || null} touched={fieldTouched[0] || null} onChange={(e) => onHandleChangeWorkShift(e, currentDay, 0)} disabled={!formik.values.costCentreId[currentDay]?.[0]} /> : fieldValue.map((item, index) => <AppSelectInput key={index} searchable={false} name={`${columnData.name}[${currentDay}][${index}]`} loadOptions={getWorkingShiftLoadOptions(formik.values.costCentreId[currentDay]?.[index])} value={fieldValue[index] || null} error={fieldError[index] || null} touched={fieldTouched[index] || null} onChange={(e) => onHandleChangeWorkShift(e, currentDay, index)} disabled={!formik.values.costCentreId[currentDay]?.[index]} />)}</div>;
			}

			if (loadOptions) {
				return <div>{!isExpanded ? <AppSelectInput searchable={false} name={`${columnData.name}[${currentDay}][0]`} loadOptions={loadOptions} value={fieldValue[0] || null} error={fieldError[0] || null} touched={fieldTouched[0] || null} onChange={formik.handleChange} /> : fieldValue.map((item, index) => <AppSelectInput key={index} searchable={false} name={`${columnData.name}[${currentDay}][${index}]`} loadOptions={loadOptions} value={fieldValue[index] || null} error={fieldError[index] || null} touched={fieldTouched[index] || null} onChange={formik.handleChange} />)}</div>;
			}

			const isCustomWorkShift = (index) => {
				const workShiftValue = formik.values.workShift?.[currentDay]?.[index];

				return workShiftValue === "Custom";
			};

			return <div>{!isExpanded ? <AppInputTime disabled={formik.values.workShiftId?.[currentDay]?.[0] != null && !isCustomWorkShift(0)} required name={`${columnData.name}[${currentDay}][0]`} value={fieldValue[0] || null} error={fieldError[0] || null} touched={fieldTouched[0] || null} onChange={onHandleChange} /> : fieldValue.map((item, index) => <AppInputTime key={index} disabled={formik.values.workShiftId?.[currentDay]?.[index] != null && !isCustomWorkShift(index)} required name={`${columnData.name}[${currentDay}][${index}]`} value={fieldValue[index] || null} error={fieldError[index] || null} touched={fieldTouched[index] || null} onChange={onHandleChange} />)}</div>;
		}

		const dateGroup = groupedDate[currentDay];

		if (!dateGroup) return null;

		if (columnData.name === "startTime" || columnData.name === "endTime") {
			const onHandleFormatTime = (timeValue) => {
				if (!timeValue) return "";

				try {
					const formatted = dayjs(timeValue, DATE_TIME.HH_MM_SS).format(DATE_TIME.HH_MM_A);
					
					return formatted === "Invalid Date" ? "" : formatted;
				} catch (e) {
					return "";
				}
			};

			return <div>{!isExpanded ? value : dateGroup.map((item, index) => <div key={index}>{onHandleFormatTime(item[columnData.name])}</div>)}</div>;
		}

		return <div>{!isExpanded ? value : dateGroup.map((item, index) => <div key={index}>{item[columnData.name]}</div>)}</div>;
	}, [data.content, editingStatus, expandedRows, formik, groupedDate, onHandleChange, onHandleChangeWorkShift, getWorkingShiftLoadOptions]);

	const tableColumns = useMemo(() => {
		const getModeColumns = (isEditing) => [
			{
				name: isEditing ? "costCentreId" : "costCentre",
				label: "Cost Centre",
				options: {
					sort: false,
					customBodyRender: (value, tableMeta) => {
						const rowDate = tableMeta.rowData[0];
						const matchingItem = data.find((item) => item.date === rowDate);
						const isRestDay = matchingItem?.dayType === "REST_DAY";
						const isHolidayDay = matchingItem?.dayType === "PUBLIC_HOLIDAY";

						if (isRestDay) {
							return <div className="scheduled-attendance-employee-input rest-day">Rest Day</div>;
						}

						if (isHolidayDay) {
							return <div className="scheduled-attendance-employee-input rest-day">Public Holiday</div>;
						}

						return <div className="scheduled-attendance-employee-input">{renderExpandableCell(value, tableMeta, (val) => val, getCostCenterListing)}</div>;
					}
				}
			},
			{
				name: isEditing ? "workShiftId" : "workShift",
				label: "Work Shift",
				options: {
					sort: false,
					customBodyRender: (value, tableMeta) => {
						const rowDate = tableMeta.rowData[0];
						const matchingItem = data.find((item) => item.date === rowDate);
						const isRestDay = matchingItem?.dayType === "REST_DAY";
						const isHolidayDay = matchingItem?.dayType === "PUBLIC_HOLIDAY";

						if (isRestDay || isHolidayDay) {
							return <div className="scheduled-attendance-employee-input"></div>;
						}

						return <div className="scheduled-attendance-employee-input">{renderExpandableCell(value, tableMeta, (val) => val)}</div>;
					}
				}
			}
		];

		const baseColumns = [
			{
				name: "startTime",
				label: "Start Time",
				options: {
					sort: false,
					customBodyRender: (value, tableMeta) => {
						const rowDate = tableMeta.rowData[0];
						const matchingItem = data.find((item) => item.date === rowDate);
						const isRestDay = matchingItem?.dayType === "REST_DAY";
						const isHolidayDay = matchingItem?.dayType === "PUBLIC_HOLIDAY";

						if (isRestDay || isHolidayDay) {
							return <div className="scheduled-attendance-employee-input"></div>;
						}

						let formattedTime = "";

						if (value) {
							try {
								formattedTime = dayjs(value, DATE_TIME.HH_MM_SS).format(DATE_TIME.HH_MM_A);

								if (formattedTime === "Invalid Date") {
									formattedTime = "";
								}
							} catch (e) {
								formattedTime = "";
							}
						}

						const onHandleFormatTime = (val) => {
							if (!val) return "";

							try {
								const formatted = dayjs(val).format(DATE_TIME.HH_MM_A);

								return formatted === "Invalid Date" ? "" : formatted;
							} catch (e) {
								return "";
							}
						};

						return <div className="scheduled-attendance-employee-input">{renderExpandableCell(formattedTime, tableMeta, onHandleFormatTime)}</div>;
					}
				}
			},
			{
				name: "endTime",
				label: "End Time",
				options: {
					sort: false,
					customBodyRender: (value, tableMeta) => {
						const rowDate = tableMeta.rowData[0];
						const matchingItem = data.find((item) => item.date === rowDate);
						const isRestDay = matchingItem?.dayType === "REST_DAY";
						const isHolidayDay = matchingItem?.dayType === "PUBLIC_HOLIDAY";

						if (isRestDay || isHolidayDay) {
							return <div className="scheduled-attendance-employee-input"></div>;
						}

						let formattedTime = "";

						if (value) {
							try {
								formattedTime = dayjs(value, DATE_TIME.HH_MM_SS).format(DATE_TIME.HH_MM_A);

								if (formattedTime === "Invalid Date") {
									formattedTime = "";
								}
							} catch (e) {
								formattedTime = "";
							}
						}

						const onHandleFormatTime = (val) => {
							if (!val) return "";
							try {
								const formatted = dayjs(val).format(DATE_TIME.HH_MM_A);

								return formatted === "Invalid Date" ? "" : formatted;
							} catch (e) {
								return "";
							}
						};

						return <div className="scheduled-attendance-employee-input">{renderExpandableCell(formattedTime, tableMeta, onHandleFormatTime)}</div>;
					}
				}
			},
			{
				name: "remove",
				label: "Remove",
				options: {
					sort: false,
					display: editingStatus,
					customBodyRender: (value, tableMeta) => {
						const { rowData, rowIndex } = tableMeta;
						const currentDay = dayjs(rowData[0]).date() - 1;
						const isOnLeave = groupedDate[currentDay]?.[0]?.status === STATUS.ON_LEAVE;
						const isExpanded = expandedRows[rowIndex];
						const dateGroup = groupedDate[currentDay];
						const rowDate = tableMeta.rowData[0];
						const matchingItem = data.find((item) => item.date === rowDate);
						const isRestDay = matchingItem?.dayType === "REST_DAY";
						const isHolidayDay = matchingItem?.dayType === "PUBLIC_HOLIDAY";

						if (isRestDay || isHolidayDay) {
							return <div className="scheduled-attendance-employee-input"></div>;
						}

						if (isOnLeave) return null;

						if (dateGroup?.length < 2) {
							return <div className="scheduled-attendance-employee-input"></div>;
						}

						return (
							<div className="table__delete-group">
								{!isExpanded
									? dateGroup?.[0] && (
											<button type="button" className="table__delete" onClick={() => onHandleRemoveScheduledDate(tableMeta, 0)}>
												<img src={deleteIcon} alt="delete-icon" />
											</button>
										)
									: dateGroup?.map((item, index) => (
											<button key={index} type="button" className="table__delete" onClick={() => onHandleRemoveScheduledDate(tableMeta, index)}>
												<img src={deleteIcon} alt="delete-icon" />
											</button>
										))}
							</div>
						);
					}
				}
			},
			{
				name: "action",
				label: "Action",
				options: {
					sort: false,
					display: editingStatus,
					customBodyRender: (value, tableMeta) => (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={verticalBreadCrumbsIcon} alt="edit-icon" />
						</button>
					)
				}
			}
		];

		return [...getModeColumns(editingStatus), ...baseColumns];
	}, [data, expandedRows, editingStatus, groupedDate, onHandleRemoveScheduledDate, onToggleTableAction, renderExpandableCell]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		customFooter: () => null
	}), []);

	useEffect(() => {
		const currentDate = dayjs(new Date()).format(DATE_TIME.YYYY_MM_DD);

		onHandleGetList(currentDate);

		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.SCHEDULED_ATTENDANCE_EMPLOYEE);
		};
	}, [onHandleGetList, cancelRequest]);

	return (
		<div className="app-scheduled-attendance-employee-calendar">
			<div className="scheduled-attendance-employee-calendar">
				<form onSubmit={formik.handleSubmit}>
					<AppCalendarSchedule expandable groupedDate={groupedDate} ref={calendarRef} formik={{ ...formik }} data={data} columns={tableColumns} options={tableOptions} editableDate={editableDate} onHandleGetList={onHandleGetList} onHandleSetEditing={onHandleSetEditing} handleRowExpand={handleRowExpand} />
				</form>
			</div>

			<Menu classes={{ root: "scheduled-attendance-employee-calendar-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseAttendanceMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				{selectedItem.current?.dayType === "ADD_SHIFT" && (
					<MenuItem onClick={onHandleAddShift}>
						<img src={addIcon} alt="add-shift" />
						Add Shift
					</MenuItem>
				)}

				{selectedItem.current?.dayType === "ADD_SHIFT" && (
					<MenuItem onClick={onHandleSetAsRestDay}>
						<img src={umbrellaIcon} alt="set-as-rest-day" />
						Set As Rest Day
					</MenuItem>
				)}

				{(selectedItem.current?.dayType === "REST_DAY" || selectedItem.current?.dayType === "PUBLIC_HOLIDAY") && (
					<MenuItem onClick={onHandleSetAsWorkingDay}>
						<img src={toolIcon} alt="set-as-working-day" />
						Set As Working Day
					</MenuItem>
				)}
			</Menu>
		</div>
	);
};

export default AppScheduledAttendanceEmployeeCalendar;
