import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getLevelsListing from "services/get-levels-listing";
import getPositionsListing from "services/get-positions-listing";
import getDepartmentsListing from "services/get-department-listing";
import getClaimPackageListing from "services/get-claim-package-listing";
import getLeavePackageListing from "services/get-leave-package-listing";
import getMalaysiaBankListing from "services/get-malaysia-bank-listing";
import getEmploymentStatusListing from "services/get-employment-status-listing";
import getEmployeeMaintenanceListing from "services/get-employee-maintenance-listing";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import REGEX from "constants/regex";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppRadioInput from "components/app-radio-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

const AppEmployeeInfo = (props, ref) => {
	const { id } = useParams();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.EMPLOYEES], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const supervisorNameKeyword = useRef();
	const reportingManagerKeyword = useRef();
	const downloadingFile = useRef(false);

	//prettier-ignore
	const contractTypeOptions = useMemo(() => [
		{ label: "Overtime Claim", value: "Overtime Claim", tooltip: "When Overtime Claim is checked, the employee will be allowed to make Overtime Claims from the employee mobile app." },
		{ label: "Replacement Leave", value: "Replacement Leave", tooltip: "When Replacement Claim is checked, the employee will be allowed to make Replacement Claims from the employee mobile app." }
	], []);

	const initialValues = useMemo(() => {
		let data = {
			epfNo: "",
			eisNo: "",
			remark: "",
			socsoNo: "",
			bankName: "",
			swiftCode: "",
			workEmail: "",
			employeeId: "",
			jobLevelId: "",
			positionId: "",
			bankAccount: "",
			dateJoined: "",
			incomeTaxNo: "",
			departmentId: "",
			employmentStatus: "",
			probationEndDate: "",
			lastEmploymentDate: "",
			leaveBenefitPackageId: "",
			claimBenefitPackageId: "",
			supervisorId: "",
			reportingManagerId: "",
			overtimeType: ""
		};

		if (props.employee) {
			data = props.employee;

			supervisorNameKeyword.current = props.employee?.supervisorName;
			reportingManagerKeyword.current = props.employee?.reportingManagerName;
		}

		return data;
	}, [props.employee]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			employmentStatus: yup.string().required(ERRORS.REQUIRED),
			positionId: yup.string().required(ERRORS.REQUIRED),
			departmentId: yup.string().required(ERRORS.REQUIRED),
			jobLevelId: yup.string().required(ERRORS.REQUIRED),
			workEmail: yup.string().matches(REGEX.EMAIL, ERRORS.REQUIRED),
			dateJoined: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			probationEndDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			leaveBenefitPackageId: yup.string().required(ERRORS.REQUIRED),
			claimBenefitPackageId: yup.string().required(ERRORS.REQUIRED),
			overtimeType: yup.string().required(ERRORS.REQUIRED),
			bankName: yup.string().required(ERRORS.REQUIRED),
			bankAccount: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			props.onSubmit(values);
		}
	});

	const onHandleCancel = () => {
		navigate(pathnames.humanResources.employeeMaintenance);
	};

	useImperativeHandle(ref, () => ({
		onHandleSubmit: formik.handleSubmit
	}));

	const onHandleChangeFile = useCallback(
		(field, nextValues) => {
			if (nextValues.length > 1) {
				nextValues = nextValues.slice(nextValues.length - 1);
			}

			formik.setFieldValue(field, nextValues);
		},
		[formik]
	);

	const onHandleDownloadFile = useCallback(async (fileObject) => {
		if (downloadingFile.current) return;

		downloadingFile.current = true;

		let response = null;

		try {
			const payload = { "doc-id": fileObject.id };

			response = await api.post.sparePart.downloadDoc(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const url = response.fileUrl;
			const a = document.createElement("a");
			document.body.appendChild(a);

			a.href = url;
			a.download = response.fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				downloadingFile.current = false;
			}, 0);
		}
	}, []);

	const onHandleRemoveFile = useCallback(
		(indexToRemove) => {
			const updatedFiles = formik.values.files.filter((_, index) => index !== indexToRemove);

			formik.setFieldValue("files", updatedFiles);
		},
		[formik]
	);

	return (
		<div className="app-employee-info">
			<div className="employee-info">
				<form className="employee-info__form" onSubmit={formik.handleSubmit}>
					<div className="employee-info__container">
						<div className="employee-info__label">General</div>

						<div className="employee-info__row">
							{!isCreate && /* prettier-ignore */ <AppInput disabled name="employeeId" type="text" required label="Employee ID" placeholder="Employee ID" touched={formik.touched.employeeId} error={formik.errors.employeeId} value={formik.values.employeeId} onChange={formik.handleChange} />}

							<AppSelectInput required searchable={false} disabled={restricted} name="employmentStatus" label="Employment Status" placeholder="Select..." loadOptions={getEmploymentStatusListing} value={formik.values.employmentStatus} error={formik.errors.employmentStatus} touched={formik.touched.employmentStatus} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row">
							<AppSelectInput searchable pagination disabled={restricted} name="reportingManagerId" label="Reporting Manager" placeholder="Select..." defaultSearchValue={reportingManagerKeyword.current} loadOptions={getEmployeeMaintenanceListing} value={formik.values.reportingManagerId} error={formik.errors.reportingManagerId} touched={formik.touched.reportingManagerId} onChange={formik.handleChange} />

							<AppSelectInput searchable pagination disabled={restricted} name="supervisorId" label="Supervisor" placeholder="Select..." defaultSearchValue={supervisorNameKeyword.current} loadOptions={getEmployeeMaintenanceListing} value={formik.values.supervisorId} error={formik.errors.supervisorId} touched={formik.touched.supervisorId} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row">
							<AppSelectInput required pagination searchable={false} disabled={restricted} name="positionId" label="Position" placeholder="Select..." loadOptions={getPositionsListing} value={formik.values.positionId} error={formik.errors.positionId} touched={formik.touched.positionId} onChange={formik.handleChange} />

							<AppSelectInput required pagination searchable={false} disabled={restricted} name="departmentId" label="Department" placeholder="Select..." loadOptions={getDepartmentsListing} value={formik.values.departmentId} error={formik.errors.departmentId} touched={formik.touched.departmentId} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row employee-info__row">
							<AppSelectInput required pagination searchable disabled={restricted} name="jobLevelId" label="Job Level" placeholder="Select..." loadOptions={getLevelsListing} value={formik.values.jobLevelId} error={formik.errors.jobLevelId} touched={formik.touched.jobLevelId} onChange={formik.handleChange} />

							<AppInput required type="text" disabled={restricted} name="workEmail" label="Work Email" placeholder="Enter Work Email" value={formik.values.workEmail} error={formik.errors.workEmail} touched={formik.touched.workEmail} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row employee-info__row">
							<AppInputDate required disabled={restricted} name="dateJoined" label="Join Date" placeholder="DD/MM/YYYY" value={formik.values.dateJoined} error={formik.errors.dateJoined} touched={formik.touched.dateJoined} onChange={formik.setFieldValue} />

							<AppInputDate required disabled={restricted} name="probationEndDate" label="Probation End Date" placeholder="DD/MM/YYYY" value={formik.values.probationEndDate} error={formik.errors.probationEndDate} touched={formik.touched.probationEndDate} onChange={formik.setFieldValue} />
						</div>

						<div className="employee-info__row employee-info__row">
							<AppInputDate disabled={restricted} name="lastEmploymentDate" label="Last Employment Date" placeholder="DD/MM/YYYY" value={formik.values.lastEmploymentDate} error={formik.errors.lastEmploymentDate} touched={formik.touched.lastEmploymentDate} onChange={formik.setFieldValue} />
						</div>
					</div>

					<div className="employee-info__container">
						<div className="employee-info__label">Remuneration Package</div>

						<div className="employee-info__row">
							<AppSelectInput required pagination searchable={false} disabled={restricted} name="leaveBenefitPackageId" label="Leave Package" placeholder="Select..." loadOptions={getLeavePackageListing} value={formik.values.leaveBenefitPackageId} error={formik.errors.leaveBenefitPackageId} touched={formik.touched.leaveBenefitPackageId} onChange={formik.handleChange} />

							<AppSelectInput required pagination searchable={false} disabled={restricted} name="claimBenefitPackageId" label="Claim Package" placeholder="Select..." loadOptions={getClaimPackageListing} value={formik.values.claimBenefitPackageId} error={formik.errors.claimBenefitPackageId} touched={formik.touched.claimBenefitPackageId} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row employee-info__row">
							<AppRadioInput required label="This Employee may claim OT as..." options={contractTypeOptions} name="overtimeType" value={formik.values.overtimeType} error={formik.errors.overtimeType} touched={formik.touched.overtimeType} onChange={(v) => formik.setFieldValue("overtimeType", v)} />
						</div>
					</div>

					<div className="employee-info__container">
						<div className="employee-info__label">Contributions</div>

						<div className="employee-info__row">
							<AppInput disabled={restricted} name="epfNo" type="number" label="EPF Number" placeholder="EPF Number" touched={formik.touched.epfNo} error={formik.errors.epfNo} value={formik.values.epfNo} onChange={formik.handleChange} />

							<AppInput disabled={restricted} name="incomeTaxNo" type="text" label="Income Tax Number" placeholder="Income Tax Number" touched={formik.touched.incomeTaxNo} error={formik.errors.incomeTaxNo} value={formik.values.incomeTaxNo} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row">
							<AppInput disabled={restricted} name="socsoNo" type="number" label="SOCSO Number" placeholder="SOCSO Number" touched={formik.touched.socsoNo} error={formik.errors.socsoNo} value={formik.values.socsoNo} onChange={formik.handleChange} />

							<AppInput disabled={restricted} name="eisNo" type="number" label="EIS Number" placeholder="EIS Number" touched={formik.touched.eisNo} error={formik.errors.eisNo} value={formik.values.eisNo} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="employee-info__container">
						<div className="employee-info__label">Bank Info</div>

						<div className="employee-info__row">
							<AppSelectInput required searchable={false} disabled={restricted} name="bankName" label="Bank" placeholder="Select..." loadOptions={getMalaysiaBankListing} value={formik.values.bankName} error={formik.errors.bankName} touched={formik.touched.bankName} onChange={formik.handleChange} />

							<AppInput required disabled={restricted} name="bankAccount" type="number" label="Bank Account No." placeholder="Bank Account No." touched={formik.touched.bankAccount} error={formik.errors.bankAccount} value={formik.values.bankAccount} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row">
							<AppInput disabled={restricted} name="swiftCode" type="text" label="Swift Code" placeholder="Swift Code" touched={formik.touched.swiftCode} error={formik.errors.swiftCode} value={formik.values.swiftCode} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="employee-info__container">
						<div className="employee-info__label">Document</div>

						<p className="employee-info__label--regular-text">Accepted formats: doc, docx, pdf (not more than 5MB each)</p>

						<div className="employee-info__file">
							<AppInputDragAndDropFiles name="files" accept="image/png, image/jpeg" value={formik.values.files} onChange={onHandleChangeFile} onHandleDownloadFile={isCreate ? false : onHandleDownloadFile} onHandleRemoveFile={onHandleRemoveFile} />
						</div>
					</div>

					<div className="employee-info__container">
						<div className="employee-info__label">Remarks</div>

						<div className="employee-info__row">
							<AppInput disabled={restricted} required multiline length={255} type="textarea" name="remark" placeholder="Type Note here" value={formik.values.remark} error={formik.errors.remark} touched={formik.touched.remark} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="employee-info__button-container">
						<AppButton outline type="button" onClick={onHandleCancel} label="Cancel" />

						<AppButton type="submit" label="Next" />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(forwardRef(AppEmployeeInfo));

AppEmployeeInfo.propTypes = {
	onSubmit: PropTypes.func,
	employee: PropTypes.object
};
