import { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal, Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import faqIcon from "assets/images/pages/human-resources/faq-icon.svg";

const AppAddCustomerCostCentreModal = (props, ref) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const profile = useSelector((state) => state.profile);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const isClone = useMemo(() => props.isClone, [props.isClone]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT_PIC], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(convertPaginationTableData());

	const formik = useFormik({
		initialValues: { keyword: "", costCenterIds: [], customerContractId: [] },
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleGetExclusiveList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10, "customer-contract-id": id });

			response = await api.get.costCenter.searchCostCenter(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		const nextCostCenterIds = values.costCenterIds;
		const nextCustomerContractId = values.customerContractId;

		if (isCreate || isClone) {
			props.onHandleUpdateCostCenterIds(nextCostCenterIds);

			props.onHandleUpdateCustomerCostCenter(nextCustomerContractId);
		} else {
			let response = null;

			try {
				const payload = {
					customerContractId: id,
					costCenterIds: values.costCenterIds.map((o) => o.id)
				};

				await api.post.customer.createContractCostCentre(payload);

				response = true;
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				props.onHandleRefreshPicTable(id);

				onHandleGetExclusiveList();

				dispatch(promptLayoutAlertMessage({ message: "Cost Centres were added successfully!" }));
			}
		}

		setVisible(false);
	}, [id, dispatch, isClone, isCreate, onHandleGetExclusiveList, props]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetExclusiveList();
	}, [onHandleGetExclusiveList]);

	// prettier-ignore
	const onHandleShow = useCallback((costCenterIds, customerContractId) => {
		formik.setFieldValue("costCenterIds", costCenterIds);

		formik.setFieldValue("customerContractId", customerContractId);

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSelectPic = useCallback((boolean, id) => {
		let nextCostCenterIds = [...formik.values.costCenterIds];
		let nextCustomerContractId = [...formik.values.customerContractId];

		if (boolean) {
			const item = data.content.find((o) => o.id === id);

			nextCostCenterIds = [...nextCostCenterIds, item];
			nextCustomerContractId = [...nextCustomerContractId, item.id];
		} else {
			nextCostCenterIds = nextCostCenterIds.filter((o) => o.id !== id);
			nextCustomerContractId = nextCustomerContractId.filter((selectedId) => selectedId !== id);
		}

		formik.setFieldValue("costCenterIds", nextCostCenterIds);
		formik.setFieldValue("customerContractId", nextCustomerContractId);
	}, [formik, data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "name",
			label: "Cost Centre Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "statesWithComma",
			label: "States",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const shouldShowTooltip = value && value.length > 25;

					return (
						<div className="table__wrapper">
							<div className="table__states">{value}</div>
						  
						  	{shouldShowTooltip && (
								<Tooltip title={value} placement="top">
									<img src={faqIcon} alt="faq icon" className="app-radio-input__faq-icon" />
								</Tooltip>
							)}
						</div>
					);
				}
			}
		},
		{
			name: "workShiftsSize",
			label: "Work Shifts",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "workingDaysWithComma",
			label: "Working Days",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "add",
			label: "Add",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePicId = data.content[rowIndex].id;
					const selected = formik.values.costCenterIds.findIndex((o) => o.id === tablePicId) > -1;

					return <AppCheckbox label="" onClick={(v) => onHandleSelectPic(v, tablePicId)} value={selected} />;
				}
			}
		}
	], [data, formik.values.costCenterIds, onHandleSelectPic]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					props.onHandleGetPicContacts(id);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					props.onHandleGetPicContacts(id);
					break;
				default:
					break;
			}
		}
	}), [id, data, props]);

	useEffect(() => {
		onHandleGetExclusiveList();
	}, [onHandleGetExclusiveList]);

	useEffect(() => {
		return () => {
			if (isCreate || isClone) {
				cancelRequest(ENDPOINT_PATH.CUSTOMER.CUSTOMER_PIC);
			} else {
				cancelRequest(ENDPOINT_PATH.CUSTOMER.CONTRACTS_EXCLUSIVE_PIC);
			}
		};
	}, [cancelRequest, isClone, isCreate]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleGetExclusiveList: onHandleGetExclusiveList
	}));

	return (
		<Modal classes={{ root: "app-add-customer-cost-centre-modal" }} open={visible}>
			<div className="add-customer-cost-centre-modal">
				<div className="add-customer-cost-centre-modal__title">Add Cost Centres</div>

				<div className="add-customer-cost-centre-modal__description">Select Cost Centres from the General Configuration module</div>

				<form className="add-customer-cost-centre-modal__form" onSubmit={formik.handleSubmit}>
					<AppTableFilterHeader searchPlaceholder="Search by Cost Centre Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} />

					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />

					<div className="add-customer-cost-centre-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" disabled={restricted} label="Add" />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAddCustomerCostCentreModal));

AppAddCustomerCostCentreModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
