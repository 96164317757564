import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { setNestedObjectValues, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import pathnames from "routes/pathnames";

import api from "services/api";
import getStateListing from "services/get-state-listing";
import getCitiesListing from "services/get-region-listing";
import getPostcodeListing from "services/get-postcode-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import capitalizeCharacter from "common/capitalize-character";
import formatCurrencyPattern from "common/format-currency-pattern";
import convertPaginationTableData from "common/convert-pagination-table-data";
import serveRequestErrors, { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";
import VERIFICATION_TYPE from "constants/verification-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";
import AppInvoicePaidModal from "components/pages/sales/app-invoice-paid-modal";
import AppInvoiceVoidModal from "components/pages/sales/app-invoice-void-modal";

import exportIcon from "assets/images/export-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const PageInvoiceCreateEdit = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.INVOICE], [profile]);
	const restricted = useMemo(() => accessible?.update, [accessible]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [statusRestrict, setStatusRestrict] = useState(false);
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const [linesTableData, setLinesTableData] = useState(convertPaginationTableData());
	const downloadingFile = useRef();
	const invoicePaidModalRef = useRef();
	const invoiceVoidModalRef = useRef();
	const queryLineParams = useRef({ page: 0, size: 10 });

	const initialValues = useMemo(() => {
		const values = {
			city: "",
			type: "",
			email: "",
			state: "",
			picName: "",
			quantity: "",
			mobileNo: "",
			prefixNo: "",
			postcode: "",
			createdBy: "",
			serviceId: "",
			taxAmount: "",
			taxPercent: "",
			totalAmount: "",
			referenceNo: "",
			saleOrderId: "",
			description: "",
			invoiceTitle: "",
			customerName: "",
			addressLine1: "",
			customerNote: "",
			addressLine2: "",
			internalNote: "",
			subtotalAmount: "",
			discountAmount: "",
			lastModifiedBy: "",
			lastModifiedDate: "",
			saleOrderReferenceNo: "",
			status: STATUS.DRAFT,
			file: [],
			lineItems: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			invoiceTitle: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isEdit = useMemo(() => formik.values.status === STATUS.DRAFT, [formik.values.status]);
	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Sales", path: pathnames.sales.quotations },
			{ label: "Invoices", path: pathnames.sales.invoices }
		];

		if (isEdit) {
			data.push({ label: "Edit Invoice " + formik.values.referenceNo, path: pathnames.sales.invoiceCreateEdit });
		} else {
			data.push({ label: "View Invoice " + formik.values.referenceNo, path: pathnames.sales.invoiceCreateEdit });
		}

		return data;
	}, [formik.values.referenceNo, isEdit]);

	useBreadcrumb({ breadCrumb });

	const onHandleBack = useCallback(() => {
		navigate(pathnames.sales.invoices);
	}, [navigate]);

	const getRegionLoadOptions = useCallback(() => getCitiesListing({ state: formik.values.state }), [formik.values.state]);
	const getPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.state}/${formik.values.city}`), [formik.values.state, formik.values.city]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				invoiceId: formik.values.id,
				createFlag: values.createFlag,
				title: formik.values.invoiceTitle,
				internalNote: formik.values.internalNote,
				itemList: values.itemList
			};

			response = await api.post.invoice.updateInvoice(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (values.createFlag) {
				dispatch(promptLayoutAlertMessage({ message: "Invoice was created successfully!" }));

				onHandleBack();
			} else {
				dispatch(promptLayoutAlertMessage({ message: "Draft was created successfully!" }));
			}
		}
	}, [dispatch, formik, onHandleBack]);

	//prettier-ignore
	const onHandleCreateUpdateInvoice = useCallback(async (createFlag) => {
		const fieldErrors = await formik.validateForm();

		if (Object.keys(fieldErrors).length) {
			formik.setTouched(setNestedObjectValues(fieldErrors, true));

			return;
		}

		const hasSelectedItems = formik.values.lineItems.some(item => item.checkedFlag);
  
  		if (!hasSelectedItems) {
  			dispatch(promptLayoutAlertMessage({ error: "error", message: "Please select at least one item to create the invoice." }));

  		  	return;
  		}

		const allItemsEnabled = formik.values.lineItems.every(item => !item.disabledFlag);

		const payload = {
			invoiceId: formik.values.id,
			createFlag: createFlag,
			title: formik.values.invoiceTitle,
			internalNote: formik.values.internalNote,
		};

		if (allItemsEnabled) {
			payload.itemList = formik.values.lineItems.map(item => ({
				lineItemId: item.id,
				checkedFlag: item.checkedFlag,
				disabledFlag: item.disabledFlag
			}));
		} else {
			payload.itemList = formik.values.lineItems
				.filter(item => item.checkedFlag && !item.disabledFlag)
				.map(item => ({
					lineItemId: item.id,
					checkedFlag: item.checkedFlag,
					disabledFlag: item.disabledFlag
				}));
		}

		onHandleSubmit(payload);
	}, [formik, onHandleSubmit, dispatch]);

	//prettier-ignore
	const onHandleCellSelect = useCallback((cell, cellData) => {
		if (cell?.type) return;

		const item = formik.values.lineItems[cellData.rowIndex];

		if (item) setSelectedRowItem(item);
	}, [formik.values.lineItems, setSelectedRowItem]);

	//prettier-ignore
	const onHandleSelectAll = useCallback((isChecked) => {
		const totalAmount = isChecked ? formik.values.lineItems.filter((item) => !item.disabledFlag).map((item) => Number(item.subtotalAmount)).reduce((sum, amount) => sum + amount, 0): 0;
		const taxAmount = isChecked ? Math.round((totalAmount * formik.values.taxPercent / 100) * 100) / 100 : 0;
		const grandTotal = isChecked ? totalAmount + taxAmount - formik.values.discountAmount : 0;

		formik.setFieldValue("taxAmount", taxAmount);
		formik.setFieldValue("subtotalAmount", totalAmount);
		formik.setFieldValue("totalAmount", grandTotal);
		formik.setFieldValue("lineItems",
		
		formik.values.lineItems.map((item) => ({ ...item, checkedFlag: isChecked })));
	}, [formik]);

	//prettier-ignore
	const onHandleCheckboxChange = useCallback((itemId, isChecked) => {
		const updatedLineItems = formik.values?.lineItems.map((item) => {
			if (item.id === itemId) {
				return { ...item, checkedFlag: isChecked };
			}
			return item;
		});
	
		const subTotalAmount = updatedLineItems.filter((item) => item.id === itemId).map((item) => item.subtotalAmount)[0] || 0;
		const roundedSubTotalAmount = Math.round(subTotalAmount * 100) / 100;
		const taxPercent = formik.values?.taxPercent || 0;
		const discountAmount = formik.values?.discountAmount || 0;
	
		let newSubtotalAmount = formik.values?.subtotalAmount || 0;
		let newTaxAmount = formik.values?.taxAmount || 0;
		let newGrandTotalAmount = formik.values?.totalAmount || 0;
	
		const taxForItem = Math.round((roundedSubTotalAmount * taxPercent / 100) * 100) / 100;
	
		if (isChecked) {
			newSubtotalAmount += roundedSubTotalAmount;
			newTaxAmount += taxForItem;
			newGrandTotalAmount += roundedSubTotalAmount + taxForItem - discountAmount;
		} else {
			newSubtotalAmount -= roundedSubTotalAmount;
			newTaxAmount -= taxForItem;
			newGrandTotalAmount -= roundedSubTotalAmount + taxForItem - discountAmount;
		}
	
		newSubtotalAmount = Math.round(newSubtotalAmount * 100) / 100;
		newTaxAmount = Math.round(newTaxAmount * 100) / 100;
		newGrandTotalAmount = Math.round(newGrandTotalAmount * 100) / 100;
	
		formik.setFieldValue("subtotalAmount", newSubtotalAmount);
		formik.setFieldValue("taxAmount", newTaxAmount);
		formik.setFieldValue("totalAmount", newGrandTotalAmount);
		formik.setFieldValue("lineItems", updatedLineItems);
	}, [formik]);

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);
	const title = useMemo(() => {
		if (isEdit) {
			return "Edit Invoice";
		}

		return "View Invoice";
	}, [isEdit]);
	const paidTitle = useMemo(() => (formik.values.verificationType === VERIFICATION_TYPE.TRANSACTION_ID ? "Transaction ID" : "Attachment"), [formik.values.verificationType]);
	const discountAmount = useMemo(() => {
		const allCheckedItems = formik.values.lineItems.every((o) => o.checkedFlag);

		if (allCheckedItems) {
			return formik.values.discountAmount;
		}

		return "";
	}, [formik.values.discountAmount, formik.values.lineItems]);

	//prettier-ignore
	const onHandleGetInvoiceDoc = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.invoice.invoiceDoc(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues((prevValues) => ({ ...prevValues, verificationType: response?.type, transactionId: response?.transactionId, file: [response?.invoicePaymentDocVo] }));
		}
	}, [memoSetFormValues]);

	//prettier-ignore
	const onHandleGetInvoiceItems = useCallback(async (uniqueId) => {
		let response = null;

		const params = sanitizeObject({ ...queryLineParams.current, "invoice-id": uniqueId });

		try {
			if (!statusRestrict) {
				response = await api.get.invoice.baseItems(params);
			}

			if (statusRestrict) {
				response = await api.get.invoice.confirmedItems(params);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setLinesTableData(obj);

			return response?.content || [];
		}
	}, [statusRestrict]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.invoice.invoice(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setStatusRestrict(response.status !== STATUS.DRAFT);

			const lineItems = await onHandleGetInvoiceItems(uniqueId);

			memoSetFormValues({
				id: response.id,
				referenceNo: response.referenceNo,
				invoiceTitle: response.title,
				createdBy: response.createdBy,
				saleOrderId: response.saleOrderId,
				saleOrderReferenceNo: response.saleOrderReferenceNo,
				status: response.status,
				customerName: response.customerName,
				picName: response.picName,
				prefixNo: response.picPrefixMobileNumber,
				mobileNo: response.picPostfixMobileNumber,
				email: response.picEmail,
				addressLine1: response.addressLine1,
				addressLine2: response.addressLine2,
				postcode: response.postcode,
				state: response.state,
				city: response.city,
				internalNote: response.internalNote,
				customerNote: response.customerNote,
				subtotalAmount: response.subtotalAmount,
				taxPercent: response.taxPercent,
				taxAmount: response.taxAmount,
				discountAmount: response.discountAmount,
				totalAmount: response.totalAmount,
				bankName: response.bankName,
				bankAccountName: response.bankAccountName,
				bankAccountNumber: response.bankAccountNumber,
				bankBranch: response.bankBranch,
				lineItems: lineItems,
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE),
			});

			if (response.status === STATUS.PAID) onHandleGetInvoiceDoc(uniqueId);
		}
	}, [memoSetFormValues, onHandleGetInvoiceDoc, onHandleGetInvoiceItems]);

	//prettier-ignore
	const onHandleDownloadFile = useCallback(async () => {
		if (downloadingFile.current) return;

		downloadingFile.current = true;

		let response = null;

		try {
			const payload = { "invoice-id": id };

			response = await api.post.invoice.downloadPaidInvoice(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const url = response.fileUrl;
			const a = document.createElement("a");
			document.body.appendChild(a);

			a.href = url;
			a.download = response.fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				downloadingFile.current = false;
			}, 0);
		}
	}, [id]);

	const onHandleShowPaid = useCallback(() => {
		setMenuAnchor(null);

		invoicePaidModalRef.current.onHandleShow(formik.values);
	}, [formik.values]);

	const onHandleShowVoid = useCallback(() => {
		setMenuAnchor(false);

		invoiceVoidModalRef.current.onHandleShow(formik.values);
	}, [formik.values]);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const processLineItems = useCallback((lineItems) => {
		return lineItems.map((item, index) => ({
			number: index + 1,
			id: item.id,
			title: item.title,
			referenceNo: item.referenceNo,
			status: item.status,
			staffId: item.staffId,
			name: item.name,
			checkedFlag: item.checkedFlag
		}));
	}, []);

	const onHandleExportPDF = useCallback(async (action) => {
		let response = null;
		let fileName = "";

		try {
			const payload = { "invoice-id": id };

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("inline; filename=")?.[1]?.split(";")?.[0];

				if (fileName) return data;

				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));

					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			response = await api.post.invoice.generateInvoicePdf(payload, { transformResponse });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			if (action === "preview") {
				const blob = new Blob([response], { type: 'application/pdf' });
      			const urlPdf = window.URL.createObjectURL(blob);

				window.open(urlPdf, '_blank');

        		setTimeout(() => {
        			window.URL.revokeObjectURL(urlPdf);
        		}, 1000);

				return;
			}

			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	}, [id]);

	const tableColumns = useMemo(() => {
		const commonColumns = [
			{
				name: "referenceNo",
				label: "Delivery Order ID",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "title",
				label: "title",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "status",
				label: "Status",
				options: {
					sort: true,
					sortThirdClickReset: true,
					customBodyRender: (value) => <AppStatus status={value} />
				}
			},
			{
				name: "name",
				label: "Created By",
				options: {
					sort: true,
					sortThirdClickReset: true,
					customBodyRender: (value, tableMeta) => {
						return (
							<span className="table__name">
								{linesTableData.content[tableMeta.rowIndex]?.staffId} {linesTableData.content[tableMeta.rowIndex]?.name}
							</span>
						);
					}
				}
			}
		];

		if (!isEdit) {
			return [
				{
					name: "number",
					label: "#",
					options: {
						sort: true,
						sortThirdClickReset: true
					}
				},
				...commonColumns
			];
		} else {
			return [
				{
					name: "checkbox",
					options: {
						sort: false,
						customHeadRender: () => {
							const allSelected = formik.values?.lineItems?.length > 0 && formik.values.lineItems.filter((item) => !item.disabledFlag).every((item) => item.checkedFlag);

							return (
								<TableCell key="table-key" className="app-table__cell app-table__cell--header">
									<AppCheckbox onClick={(isChecked) => onHandleSelectAll(isChecked)} value={allSelected} />
								</TableCell>
							);
						},
						customBodyRender: (value, tableMeta) => {
							const rowIndex = tableMeta.rowIndex;
							const item = formik.values?.lineItems[rowIndex];

							return (
								<Table>
									<TableBody>
										<TableRow>
											<AppCheckbox className="checkbox" disabled={item.disabledFlag} onClick={(isChecked) => onHandleCheckboxChange(item.id, isChecked)} value={item.checkedFlag} />
										</TableRow>
									</TableBody>
								</Table>
							);
						}
					}
				},
				...commonColumns
			];
		}
	}, [isEdit, linesTableData.content, formik.values.lineItems, onHandleSelectAll, onHandleCheckboxChange]);

	const SelectedItemDetails = useCallback((obj) => {
		if (!obj.selectedRowItem) return null;

		const firstItem = obj.selectedRowItem.number === 1;
		const lastItem = obj.lineItems.length === obj.selectedRowItem.number;

		const onHandleNextItem = () => {
			setSelectedRowItem(obj.lineItems[obj.selectedRowItem.number]);
		};

		const onHandlePrevItem = () => {
			setSelectedRowItem(obj.lineItems[obj.selectedRowItem.number - 2]);
		};

		return (
			<div className="invoice-create-edit__item-table">
				<div className="item-table">
					<div className="item-table__content">
						<div className="item-table__item">
							<p className="item-table__label">Title</p>

							<p className="item-table__value">{obj.selectedRowItem.title}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service ID</p>

							<p className="item-table__value">{obj.selectedRowItem.serviceId}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Type</p>

							<p className="item-table__value">{obj.selectedRowItem.type}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Description</p>

							<p className="item-table__value">{obj.selectedRowItem.description}</p>
						</div>
					</div>

					<div className="item-table__pagination">
						<button type="button" className="item-table__button item-table__button--prev" disabled={firstItem} onClick={onHandlePrevItem}>
							<img src={chevronIcon} alt="pagination-prev" />
						</button>

						<p className="item-table__page">
							item of {obj.selectedRowItem.number} of {obj.lineItems.length}
						</p>

						<button type="button" className="item-table__button item-table__button--next" disabled={lastItem} onClick={onHandleNextItem}>
							<img src={chevronIcon} alt="pagination-next" />
						</button>
					</div>
				</div>
			</div>
		);
	}, []);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		expandableRows: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		count: linesTableData?.totalElements,
		page: linesTableData?.page,
		serverSide: true,
		onCellClick: onHandleCellSelect,
		onTableChange: (action, tableState) => {
			if (action === "changePage") {
				queryLineParams.current.page = tableState.page;

				onHandleGetDetails(id);
			}
		}
	}), [id, linesTableData?.page, linesTableData?.totalElements, onHandleCellSelect, onHandleGetDetails]);

	useEffect(() => {
		onHandleGetDetails(id);
	}, [id, isEdit, onHandleGetDetails, queryLineParams.current.page]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.INVOICE.INVOICE);
			cancelRequest(ENDPOINT_PATH.INVOICE.INVOICE_DOC);

			if (!isEdit) {
				cancelRequest(ENDPOINT_PATH.INVOICE.CONFIRMED_ITEMS);
			} else {
				cancelRequest(ENDPOINT_PATH.INVOICE.BASE_ITEMS);
			}
		};
	}, [cancelRequest, id, isEdit]);

	return (
		<div className="page-invoice-create-edit">
			<div className="invoice-create-edit">
				<form className="invoice-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="invoice-create-edit__header">
						<h1 className="invoice-create-edit__title">{title}</h1>

						<div className="invoice-create-edit__header invoice-create-edit__header--column">
							{statusRestrict && (
								<div className="invoice-create-edit__header-buttons">
									{formik.values.status !== STATUS.VOID && <AppButton outline className="invoice-create-edit__action-button" type="button" label="Actions" icon={chevronIcon} onClick={onToggleFilterMenu} />}

									<AppButton outline type="button" label="Export as PDF" icon={exportIcon} onClick={() => onHandleExportPDF("download")} />

									<AppButton outline type="button" label="Preview" onClick={() => onHandleExportPDF("preview")}/>
								</div>
							)}

							<p className="invoice-create-edit__last-update">
								<span>Last Updated By</span> {formik.values.lastModifiedBy}, {formik.values.lastModifiedDate}
							</p>
						</div>
					</div>

					<div className="invoice-create-edit__container">
						<div className="invoice-create-edit__wrapper invoice-create-edit__wrapper--divider">
							<p className="invoice-create-edit__label">General Details</p>

							<div className="invoice-create-edit__row invoice-create-edit__row--general-details">
								<AppInput disabled type="text" name="referenceNo" label="Invoice ID" placeholder="Invoice ID" value={formik.values.referenceNo} error={formik.errors.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="status" label="Status" placeholder="Status" value={capitalizeCharacter(formik.values.status.split("_").join(" "))} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="saleOrderReferenceNo" label="Sales Order ID" placeholder="Sales Order ID" value={formik.values.saleOrderReferenceNo} error={formik.errors.saleOrderReferenceNo} touched={formik.touched.saleOrderReferenceNo} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="createdBy" label="Created By" placeholder="Created By" value={formik.values.createdBy} error={formik.errors.createdBy} touched={formik.touched.createdBy} onChange={formik.handleChange} />
							</div>
						</div>

						<div className="invoice-create-edit__wrapper">
							<p className="invoice-create-edit__label">Customer Details</p>

							<div className="invoice-create-edit__row">
								<AppInput disabled type="text" name="customerName" label="Registered Name" placeholder="Registered Name" value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="picName" label="PIC Name" placeholder="PIC name" value={formik.values.picName} error={formik.errors.picName} touched={formik.touched.picName} onChange={formik.handleChange} />
							</div>

							<div className="invoice-create-edit__row">
								<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

								<AppInput disabled type="text" name="email" label="Email" placeholder="Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
							</div>

							<div className="invoice-create-edit__row">
								<AppInput disabled type="text" name="addressLine1" label="Address Line 1" placeholder="Address line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="addressLine2" label="Address Line 2" placeholder="Address line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
							</div>

							<div className="invoice-create-edit__row">
								<AppSelectInput disabled name="state" label="State" placeholder="Select..." loadOptions={getStateListing} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

								<AppSelectInput disabled name="city" label="City" placeholder="Select..." loadOptions={getRegionLoadOptions} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
							</div>

							<div className="invoice-create-edit__row">
								<AppSelectInput disabled name="postcode" label="Postcode" placeholder="Select..." loadOptions={getPostcodeLoadOptions} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					<div className="invoice-create-edit__container">
						<div className="invoice-create-edit__wrapper">
							<AppInput disabled={statusRestrict} required type="text" name="invoiceTitle" label="Invoice Title" placeholder="Invoice Title" value={formik.values.invoiceTitle} error={formik.errors.invoiceTitle} touched={formik.touched.invoiceTitle} onChange={formik.handleChange} />

							<p className="invoice-create-edit__label">Item Lines {isEdit && <span>*</span>}</p>

							{isEdit && <p className="invoice-create-edit__description">Please select minimum 1 item to create the Delivery Order.</p>}

							<AppTable data={processLineItems(linesTableData.content)} columns={tableColumns} options={tableOptions} />

							<SelectedItemDetails selectedRowItem={selectedRowItem} lineItems={formik.values.lineItems} />

							<div className="invoice-create-edit__row">
								<AppInput disabled={statusRestrict} multiline type="textarea" maxLength={1000} name="internalNote" label="Internal Note" placeholder="Type Note here" value={formik.values.internalNote} error={formik.errors.internalNote} touched={formik.touched.internalNote} onChange={formik.handleChange} />

								<div className="invoice-create-edit__column">
									<AppInput disabled type="text" name="subtotalAmount" label="Subtotal (MYR)" placeholder="For example: 100.00" value={formik.values.subtotalAmount} error={formik.errors.subtotalAmount} touched={formik.touched.subtotalAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									<div className="invoice-create-edit__gap">
										<AppInput disabled type="number" maxLength={1} name="taxPercent" label="Tax (%)" placeholder="Enter Tax(%)" value={formik.values.taxPercent} error={formik.errors.taxPercent} touched={formik.touched.taxPercent} onChange={formik.handleChange} />

										<AppInput disabled type="text" name="taxAmount" value={formik.values.taxAmount} error={formik.errors.taxAmount} touched={formik.touched.taxAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
									</div>
								</div>
							</div>

							<div className="invoice-create-edit__row">
								<AppInput disabled multiline type="textarea" maxLength={1000} name="customerNote" label="Customer Note" placeholder="Type Note here" value={formik.values.customerNote} error={formik.errors.customerNote} touched={formik.touched.customerNote} onChange={formik.handleChange} />

								<div className="invoice-create-edit__column">
									<AppInput disabled type="text" name="discountAmount" label="Discount (MYR)" value={discountAmount} error={formik.errors.discountAmount} touched={formik.touched.discountAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									<AppInput disabled type="text" name="totalAmount" label="Grand Total (MYR)" value={formik.values.totalAmount} error={formik.errors.totalAmount} touched={formik.touched.totalAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
								</div>
							</div>
						</div>
					</div>

					{formik.values.status === STATUS.PAID && (
						<div className="invoice-create-edit__container">
							<div className="invoice-create-edit__wrapper">
								<p className="invoice-create-edit__label">{paidTitle}</p>

								{formik.values.verificationType === VERIFICATION_TYPE.TRANSACTION_SLIP && <AppInputDragAndDropFiles disabled name="file" accept="image/png, image/jpg" value={formik.values.file} onChange={formik.setFieldValue} onHandleDownloadFile={onHandleDownloadFile} />}

								{formik.values.verificationType === VERIFICATION_TYPE.TRANSACTION_ID && (
									<div className="invoice-create-edit__row">
										<AppInput disabled name="transactionId" label="Transaction ID" value={formik.values.transactionId} error={formik.errors.transactionId} touched={formik.touched.transactionId} onChange={formik.handleChange} />
									</div>
								)}
							</div>
						</div>
					)}

					{formik.values.authorisedBy?.fullName && (
						<div className="invoice-create-edit__container invoice-create-edit__container--signature">
							<div className="invoice-create-edit__row invoice-create-edit__row--signature">
								<div className="invoice-create-edit__column">
									<AppInput disabled name="authorisedBy.fullName" label="Authorised By" value={formik.values.authorisedBy?.fullName} touched={formik.touched.authorisedBy?.fullName} onChange={formik.handleChange} />

									<AppInputDragAndDrop disabled name="authorisedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.authorisedBy?.signature} error={formik.errors.authorisedBy?.signature} touched={formik.touched.authorisedBy?.signature} />
								</div>
							</div>
						</div>
					)}

					<div className="invoice-create-edit__button-container">
						<AppButton outline className="invoice-create-edit__cancel-button" type="button" label="Cancel" onClick={onHandleBack} />

						{isEdit && restricted && (
							<Fragment>
								<AppButton outline type="button" label="Update Draft" onClick={() => onHandleCreateUpdateInvoice(false)} />

								<AppButton type="button" label="Create Invoice" disabled={formik.isSubmitting || !formik.dirty} onClick={() => onHandleCreateUpdateInvoice(true)} />
							</Fragment>
						)}
					</div>
				</form>
			</div>

			<Menu classes={{ root: "invoice-create-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				{formik.values.status !== STATUS.PAID && <MenuItem onClick={onHandleShowPaid}>Set As Paid</MenuItem>}

				<MenuItem onClick={onHandleShowVoid}>Set As Void</MenuItem>
			</Menu>

			<AppInvoicePaidModal ref={invoicePaidModalRef} onHandleGetDetails={onHandleGetDetails} />

			<AppInvoiceVoidModal ref={invoiceVoidModalRef} onHandleGetDetails={onHandleGetDetails} />
		</div>
	);
};

export default PageInvoiceCreateEdit;
