import React, { Fragment, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate, useParams } from "react-router-dom";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";
import getLeaveTypeListing from "services/get-leave-type-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ERRORS from "constants/errors";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppRemunerationLeaveTypeDeleteModal from "components/pages/human-resources/app-remuneration-leave-type-delete-modal";

import addIcon from "assets/images/blue-add-icon.svg";
import removeIcon from "assets/images/trash-icon.png";
import faqIcon from "assets/images/pages/human-resources/faq-icon.png";

const PageLeavePackageCreateEdit = () => {
	let { id } = useParams();
	const deleteRef = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const extraLeaveBenefit = useMemo(() => ({ id: "", hrConfigLeaveTypeId: "", numberOfDays: "", needsAttachment: true, limitToCurrentYear: true, reimbursement: true }), []);
	const initialValues = useMemo(() => ({ name: "", description: "", employedFor: "", extraLeaveBenefits: isCreate ? [extraLeaveBenefit] : [] }), [isCreate, extraLeaveBenefit]);
	
	const holidayTooltipContent = (
		<div>
			<div>
				<strong>Enabled</strong> = Approved leave that coincides on a Holiday or Rest Day will be automatically credited back to the employee's leave balance.
			</div>
			<div>
				<strong>Disabled</strong> = Approved Leave that coincides with a Holiday Rest Day will be deducted from the employee's leave balance as usual.
			</div>
		</div>
	);

	const limitYearTooltipContent = (
		<div>
			<strong>Enabled</strong> = Employees will only be allowed to apply for this leave type during the current calendar year.
		</div>
	);

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			employedFor: yup.string().required(ERRORS.REQUIRED),
			extraLeaveBenefits: yup.array().of(
				yup.object({
					hrConfigLeaveTypeId: yup.string().required(ERRORS.REQUIRED),
					numberOfDays: yup.string().required(ERRORS.REQUIRED)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isDisabled = useMemo(() => formik.isSubmitting, [formik.isSubmitting]);
	const title = useMemo(() => (isCreate ? "Add Leave Package" : "Edit Leave Package"), [isCreate]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);

	const onHandleLeaveBenefitsPayload = (leaveBenefits) => {
		return leaveBenefits.map((o) => ({
			id: o.id,
			numberOfDays: o.numberOfDays,
			needsAttachment: o.needsAttachment,
			limitToCurrentYear: o.limitToCurrentYear,
			hrConfigLeaveTypeId: o.hrConfigLeaveTypeId,
			reimbursement: o.reimbursement
		}));
	};

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.humanResource.leavePackage(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				name: response.name,
				description: response.description,
				employedFor: response.eligibility.employedYears,
				extraLeaveBenefits: response.leaveBenefits?.length ? response.leaveBenefits : [extraLeaveBenefit]
			});
		}
	}, [extraLeaveBenefit, memoSetFormValues]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				name: values.name,
				description: values.description,
				eligibility: {
					employedYears: parseInt(values.employedFor)
				},
				leaveBenefits: onHandleLeaveBenefitsPayload(values.extraLeaveBenefits)
			};

			if (isCreate) {
				await api.post.humanResource.createLeavePackage(payload);
			}

			if (!isCreate) {
				payload.id = id;

				await api.post.humanResource.updateLeavePackage(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Leave Package was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Leave Package was updated successfully!" }));
			}

			navigate(pathnames.humanResources.remunerationPackages + "?tab=LEAVE_PACKAGES");
		}
	}, [navigate, dispatch, formik, id, isCreate]);

	//prettier-ignore
	const onHandleSetNeedsAttachment = useCallback((index, currentValue) => {
		formik.setFieldValue(`extraLeaveBenefits.${index}.needsAttachment`, !currentValue);
	}, [formik]);

	//prettier-ignore
	const onHandleSetLimitToCurrentYear = useCallback((index, currentValue) => {
		formik.setFieldValue(`extraLeaveBenefits.${index}.limitToCurrentYear`, !currentValue);
	}, [formik]);

	//prettier-ignore
	const onHandleSetReimbursement = useCallback((index, currentValue) => {
		formik.setFieldValue(`extraLeaveBenefits.${index}.reimbursement`, !currentValue);
	}, [formik]);

	//prettier-ignore
	const onHandleAddExtraBenefit = useCallback((o) => {
		o.push(extraLeaveBenefit);
	}, [extraLeaveBenefit]);

	//prettier-ignore
	const onHandleRemoveExtraBenefit = useCallback((object, index) => {
		const benefit = formik.values.extraLeaveBenefits[index];
		
		if (benefit.id) {
			deleteRef.current.onHandleShow(benefit.id, object, index);
		} else {
			object.remove(index);
		}
	}, [formik.values.extraLeaveBenefits]);

	//prettier-ignore
	const onHandleConfirm = useCallback((o, index) => {
		if (formik.values.extraLeaveBenefits?.length > 1) {
			o.remove(index);
		}
	}, [formik.values.extraLeaveBenefits]);

	const onHandleCancel = useCallback(() => {
		navigate(pathnames.humanResources.remunerationPackages + "?tab=LEAVE_PACKAGES");
	}, [navigate]);

	useEffect(() => {
		if (!isCreate) {
			onHandleGetDetails(id);
		}
	}, [isCreate, id, onHandleGetDetails]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.LEAVE_PACKAGE);
		};
	}, [cancelRequest]);

	return (
		<FormikProvider value={formik}>
			<div className="page-leave-package-create-edit">
				<div className="leave-package-create-edit">
					<h1 className="leave-package-create-edit__title">{title}</h1>

					<form className="leave-package-create-edit__form" onSubmit={formik.handleSubmit}>
						<div className="leave-package-create-edit__container">
							<div className="leave-package-create-edit__box">
								<div className="leave-package-create-edit__wrapper">
									<div className="leave-package-create-edit__box-body">
										<AppInput required type="text" name="name" disabled={isDisabled} label="Leave Package Name" placeholder="Enter Leave Package Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

										<AppInput multiline maxLength={255} type="text" name="description" disabled={isDisabled} label="Description" placeholder="Enter Description" value={formik.values.description} touched={formik.touched.description} onChange={formik.handleChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="leave-package-create-edit__box">
							<p className="leave-package-create-edit__header-title">Eligibility</p>

							<div className="leave-package-create-edit__box-body">
								<AppInput required type="number" name="employedFor" disabled={isDisabled} label="Employed for (Years)" placeholder="Enter the No. of years" value={formik.values.employedFor} error={formik.errors.employedFor} touched={formik.touched.employedFor} onChange={formik.handleChange} />
							</div>
						</div>

						<FieldArray
							name="extraLeaveBenefits"
							render={(o) => {
								const errors = formik.errors;
								const touched = formik.touched;
								const extraLeaveBenefits = formik.values.extraLeaveBenefits;

								return (
									<div className="leave-package-create-edit__container">
										<div className="leave-package-create-edit__box">
											<div className="leave-package-create-edit__header">
												<p className="leave-package-create-edit__input-title">Leave Benefits</p>

												<AppButton type="button" label="Add Leave" icon={addIcon} onClick={() => onHandleAddExtraBenefit(o)} />
											</div>

											{!!extraLeaveBenefits?.length && (
												<Fragment>
													<div className="leave-package-create-edit__divider" />

													<div className="leave-package-create-edit__extra-benefit">
														<div className="leave-package-create-edit__box-body">
															{extraLeaveBenefits.map((benefit, i) => (
																<div key={i} className="leave-package-create-edit__input">
																	{extraLeaveBenefits.length > 1 && (
																		<div className="leave-package-create-edit__input-section">
																			<button type="button" className="leave-package-create-edit__remove-button" disabled={isDisabled || extraLeaveBenefits?.length <= 1} onClick={() => onHandleRemoveExtraBenefit(o, i)}>
																				<img src={removeIcon} alt="remove-extra-benefit" />
																			</button>
																		</div>
																	)}

																	<AppSelectInput required type="text" label="Leave Types" name={`extraLeaveBenefits.${i}.hrConfigLeaveTypeId`} disabled={isDisabled} placeholder="Please Select" value={benefit.hrConfigLeaveTypeId} error={errors.extraLeaveBenefits?.[i]?.hrConfigLeaveTypeId} touched={touched.extraLeaveBenefits?.[i]?.hrConfigLeaveTypeId} loadOptions={getLeaveTypeListing} onChange={formik.handleChange} />

																	<AppInput required maxLength={3} type="number" name={`extraLeaveBenefits.${i}.numberOfDays`} disabled={isDisabled} label="No. of Days" placeholder="Enter the No. of Entitled Days " value={benefit.numberOfDays} error={errors.extraLeaveBenefits?.[i]?.numberOfDays} touched={touched.extraLeaveBenefits?.[i]?.numberOfDays} onChange={formik.handleChange} />

																	{/*prettier-ignore*/}
																	<div className="leave-package-create-edit__input-switch">
																		<div className="leave-package-create-edit__switch">
																			<Switch checked={benefit.limitToCurrentYear} disabled={isDisabled} onChange={() => onHandleSetLimitToCurrentYear(i, benefit.limitToCurrentYear)} />

																			<p className="leave-package-create-edit__switch-label">Limit to Current Year</p>

																			<Tooltip title={limitYearTooltipContent} placement="top" arrow>
																				<img src={faqIcon} alt="help icon" className="leave-package-create-edit__faq-icon" />
																			</Tooltip>
																		</div>

																		<div className="leave-package-create-edit__switch">
																			<Switch checked={benefit.reimbursement} disabled={isDisabled} onChange={() => onHandleSetReimbursement(i, benefit.reimbursement)} />
																			
																			<p className="leave-package-create-edit__switch-label">Holidays & Rest Days Exempt</p>
																			
																			<Tooltip title={holidayTooltipContent} placement="top" arrow>
																				<img src={faqIcon} alt="help icon" className="leave-package-create-edit__faq-icon" />
																			</Tooltip>
																		</div>

																		<div className="leave-package-create-edit__switch">
																			<Switch checked={benefit.needsAttachment} disabled={isDisabled} onChange={() => onHandleSetNeedsAttachment(i, benefit.needsAttachment)} />

																			<p className="leave-package-create-edit__switch-label">Attachment Required</p>
																		</div>
																	</div>
																</div>
															))}
														</div>
													</div>
												</Fragment>
											)}
										</div>
									</div>
								);
							}}
						/>

						<div className="leave-package-create-edit__button-container">
							<AppButton outline type="button" label="Cancel" onClick={onHandleCancel} />

							<AppButton type="submit" disabled={isDisabled} label={submitLabel} />
						</div>
					</form>
				</div>

				<AppRemunerationLeaveTypeDeleteModal ref={deleteRef} onHandleConfirm={onHandleConfirm} />
			</div>
		</FormikProvider>
	);
};

export default memo(PageLeavePackageCreateEdit);
