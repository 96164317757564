import React, { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

export const AppSiteDetailsCreateEditTeamMembersModal = (props, ref) => {
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState(convertPaginationTableData());
	const [visible, setVisible] = useState(false);
	const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
	const selectedTeamMembersRef = useRef([]);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const onHandleUpdateTeamMembersLocal = useMemo(() => props.onHandleUpdateTeamMembersLocal, [props.onHandleUpdateTeamMembersLocal]);
	const onHandleUpdateTeamMembers = useMemo(() => props.onHandleUpdateTeamMembers, [props.onHandleUpdateTeamMembers]);
	const queryParams = useRef({ page: 0, keyword: "" });

	//prettier-ignore
	const onHandleShow = useCallback((values) => {
		if (isCreate) setSelectedTeamMembers(values);

		selectedTeamMembersRef.current = values;

		setVisible(true);
	}, [isCreate]);

	const onHandleDismiss = useCallback(() => {
		setSelectedTeamMembers([]);

		setVisible(false);
	}, []);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = { ...queryParams.current, size: 10, "check-work-order-eligible": true };

			response = await api.get.humanResource.employees(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	const onHandleSubmit = useCallback(() => {
		if (isCreate) {
			onHandleUpdateTeamMembersLocal(selectedTeamMembers);
		}

		if (!isCreate) {
			onHandleUpdateTeamMembers(id, selectedTeamMembers);
		}

		onHandleDismiss();
	}, [id, isCreate, onHandleUpdateTeamMembersLocal, onHandleUpdateTeamMembers, selectedTeamMembers, onHandleDismiss]);

	//prettier-ignore
	const onHandleAddTeamMembers = useCallback((boolean, obj) => {
		let nextTeamMembers = [...selectedTeamMembers];

		if (boolean) {
			let teamMember = data.content.find((o) => o.id === obj.id);

			nextTeamMembers = [...nextTeamMembers, { ...teamMember, employeeId: teamMember.employeeDetail.employeeId }];
		} else {
			nextTeamMembers = nextTeamMembers.filter((o) => o.id !== obj.id);
		}

		setSelectedTeamMembers(nextTeamMembers);
	}, [data.content, selectedTeamMembers]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "personalInfo.fullName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "employeeDetail.employeeId",
			label: "Employee ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "add",
			label: "Add",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const obj = data.content[rowIndex];
					const selected = selectedTeamMembers?.findIndex((o) => o.id === obj.id) > -1;

					return <AppCheckbox label="" onClick={(v) => onHandleAddTeamMembers(v, obj)} value={selected} />;
				}
			}
		}
	], [data, selectedTeamMembers, onHandleAddTeamMembers]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEES);
		};
	}, [cancelRequest]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-site-details-create-edit-team-members-modal" }} open={visible}>
			<div className="site-details-create-edit-team-members-modal">
				<h1 className="site-details-create-edit-team-members-modal__title">Add Team Member</h1>

				<form className="site-details-create-edit-team-members-modal__form" onSubmit={onHandleSubmit}>
					<p className="site-details-create-edit-team-members-modal__description">Select Employees from Human Resource Module</p>

					<AppTableFilterHeader searchPlaceholder="Search by Name or Employee ID" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} />

					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />

					<div className="site-details-create-edit-team-members-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="button" label="Add" onClick={onHandleSubmit} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppSiteDetailsCreateEditTeamMembersModal));

AppSiteDetailsCreateEditTeamMembersModal.propTypes = {
	ref: PropTypes.object
};
