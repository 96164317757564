import { Fragment, forwardRef, memo, useCallback, useImperativeHandle, useMemo } from "react";

import * as yup from "yup";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FieldArray, FormikProvider, setNestedObjectValues, useFormik } from "formik";

import pathnames from "routes/pathnames";

import getEmployeeFamilyMemberRelationshipListing from "services/get-employee-family-member-relationship-listing";
import getEmployeeFamilyMemberWorkingStatusListing from "services/get-employee-family-member-working-status-listing";

import getOrdinalNumber from "common/get-ordinal-number";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppSelectInput from "components/app-select-input";

import addIcon from "assets/images/add-blue-icon.png";
import deleteIcon from "assets/images/trash-icon.png";

const AppFamilyInfo = (props, ref) => {
	const navigate = useNavigate();
	const defaultField = useMemo(() => ({ fullName: "", relationship: "", workingStatus: "", dateOfBirth: "" }), []);
	const maximumFamily = useMemo(() => 10, []);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.EMPLOYEES], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const initialValues = useMemo(() => {
		let data = {
			familyInfo: [defaultField]
		};

		if (props.family) {
			data.familyInfo = props.family.familyInfo;
		}

		return data;
	}, [defaultField, props.family]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			familyInfo: yup.array().of(
				yup.object({
					fullName: yup.string().required(ERRORS.REQUIRED),
					relationship: yup.string().required(ERRORS.REQUIRED),
					workingStatus: yup.string().required(ERRORS.REQUIRED),
					dateOfBirth: yup.date().required(ERRORS.REQUIRED)
				})
			)
		}),
		onSubmit: (values) => {
			props.onSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleAddFamilyMember = useCallback(async (obj) => {
		if (obj.form.values.familyInfo.length >= maximumFamily) return;

        const fieldErrors = await obj.form.validateForm();

        if(fieldErrors.familyInfo) {
            obj.form.setTouched(setNestedObjectValues(fieldErrors, true));

            return;
        }

		obj.push(defaultField);
	}, [defaultField, maximumFamily]);

	const onHandleCancel = useCallback(() => {
		navigate(pathnames.humanResources.employeeMaintenance);
	}, [navigate]);

	useImperativeHandle(ref, () => ({
		onHandleSubmit: formik.handleSubmit
	}));

	return (
		<FormikProvider value={formik}>
			<div className="app-family-info">
				<div className="family-info">
					<form className="family-info__form" onSubmit={formik.handleSubmit}>
						<div className="family-info__container">
							<FieldArray
								name="familyInfo"
								render={(f) => {
									const values = f.form.values?.familyInfo;
									const errors = f.form.errors?.familyInfo;
									const touched = f.form.touched?.familyInfo;
									const familyInfo = f.form.values.familyInfo;

									return (
										<Fragment>
											<div className="family-info__header">
												<div className="family-info__label">Family Members</div>

												<AppButton disabled={restricted} type="button" outline icon={addIcon} label="Add Member" onClick={() => onHandleAddFamilyMember(f)} />
											</div>

											{familyInfo.map((o, i) => {
												const ordinalNumber = getOrdinalNumber(i + 1) + " Member";

												return (
													<Fragment key={i}>
														<div className="family-info__label">
															{ordinalNumber}
															{familyInfo.length > 1 && (
																<span className="family-info__delete" onClick={() => f.remove(i)}>
																	Delete <img src={deleteIcon} alt="delete" />
																</span>
															)}
														</div>

														<div className="family-info__row">
															{/* prettier-ignore */}
															<AppInput required disabled={restricted} type="text" name={`familyInfo.${i}.fullName`} label="Full Name" placeholder="Enter Full Name" touched={touched?.[i]?.fullName} error={errors?.[i]?.fullName} value={values[i].fullName} onChange={formik.handleChange} />

															{/* prettier-ignore */}
															<AppSelectInput required searchable={false} disabled={restricted} type="text" name={`familyInfo.${i}.relationship`} label="Relationship" placeholder="Relationship" loadOptions={getEmployeeFamilyMemberRelationshipListing} value={values[i].relationship} error={errors?.[i]?.relationship} touched={touched?.[i]?.relationship} onChange={formik.handleChange} />
														</div>

														<div className="family-info__row">
															{/* prettier-ignore */}
															<AppSelectInput required searchable={false} disabled={restricted} type="text" name={`familyInfo.${i}.workingStatus`} label="Working Status" placeholder="Select..." loadOptions={getEmployeeFamilyMemberWorkingStatusListing} value={values[i].workingStatus} error={errors?.[i]?.workingStatus} touched={touched?.[i]?.workingStatus} onChange={formik.handleChange} />

															{/* prettier-ignore */}
															<AppInputDate required disabled={restricted} name={`familyInfo.${i}.dateOfBirth`} label="Date of Birth"  placeholder="DD/MM/YYYY" value={values[i].dateOfBirth} error={errors?.[i]?.dateOfBirth} touched={touched?.[i]?.dateOfBirth} onChange={formik.setFieldValue} />
														</div>
													</Fragment>
												);
											})}
										</Fragment>
									);
								}}
							/>
						</div>
						<div className="family-info__button-container">
							<AppButton outline type="button" onClick={onHandleCancel} label="Cancel" />

							<AppButton type="submit" label="Next" disabled={formik.isSubmitting || restricted} />
						</div>
					</form>
				</div>
			</div>
		</FormikProvider>
	);
};

export default memo(forwardRef(AppFamilyInfo));
