const ENDPOINT_PATH = {
	GENERAL: {
		GENDER: "general/lookup/detail?lookupHdrId=GENDER&sort=lookupTitle,asc",
		NATIONALITY: "general/lookup/detail?lookupHdrId=NATIONALITY&sort=lookupTitle,asc",
		ID_TYPE: "general/lookup/detail?lookupHdrId=IDENTIFICATION_TYPE&sort=lookupTitle,asc",
		MARITAL_STATUS: "general/lookup/detail?lookupHdrId=MARITAL_STATUS&sort=lookupTitle,asc",
		RELATIONSHIP: "general/lookup/detail?lookupHdrId=RELATIONSHIP&sort=lookupTitle,asc",
		EMPLOYEE_FAMILY_MEMBER_RELATIONSHIP: "general/lookup/detail?lookupHdrId=EMPLOYEE_FAMILY_MEMBER_RELATIONSHIP&sort=lookupTitle,asc",
		EMPLOYEE_FAMILY_MEMBER_WORKING_STATUS: "general/lookup/detail?lookupHdrId=EMPLOYEE_FAMILY_MEMBER_WORKING_STATUS&sort=lookupTitle,asc",
		MALAYSIA_BANK: "general/lookup/detail?lookupHdrId=MALAYSIA_BANK&sort=lookupTitle,asc",
		WEEK_DAYS: "general/lookup/detail?lookupHdrId=WEEK_DAYS&sort=seq,asc",
		USER_STATUS: "general/lookup/detail?lookupHdrId=STATUS",
		FREQUENCY: "general/lookup/detail?lookupHdrId=FREQUENCY&sort=lookupTitle,asc",
		SERVICE_STATUS: "general/lookup/detail?lookupHdrId=SERVICE_STATUS&sort=lookupTitle,asc",
		SERVICE_TYPE: "general/lookup/detail?lookupHdrId=SERVICE_TYPE&sort=lookupTitle,asc",
		EMPLOYEMENT_STATUS: "general/lookup/detail?lookupHdrId=EMPLOYMENT_STATUS&sort=lookupTitle,asc",
		CONTRACT_TYPE: "general/lookup/detail?lookupHdrId=CONTRACT_TYPE",
		CONTRACT_STATUS: "general/lookup/detail?lookupHdrId=CONTRACT_STATUS",
		CONTRACT_TEMPLATE: "general/config/contract/search",
		ENQUIRY_STATUS: "general/lookup/detail?lookupHdrId=ENQUIRY_STATUS",
		SITE_STATUS: "general/lookup/detail?lookupHdrId=SITE_STATUS",
		INPUT_TYPE: "general/lookup/detail?lookupHdrId=INPUT_TYPE",
		SERVICE_INPUT: "general/config/input/builders/lite/search",
		PERMISSIONS: "general/role/permission/list",
		STATES: "general/address/state/page",
		REGION: "general/address/city/page",
		POSTCODE: "general/address/postcode/",
		ROLES: "general/role/drop-down-list",
		TEMPLATE: "general/templates/",
		COUNTRY_CURRENCY: "general/country-currency/page",
		DAY_TYPE: "general/lookup/detail?lookupHdrId=OT_DAY_TYPE",
		OT_RULE: "general/lookup/detail?lookupHdrId=OT_RULE"
	},
	AUTHENTICATE: {
		SIGN_IN: "auth/public/token",
		REFRESH_TOKEN: "auth/public/token/refresh"
	},
	FORGOT_PASSWORD: {
		FORGOT_PASSWORD: "auth/public/reset-password/send",
		UPDATE_PASSWORD: "auth/public/reset-password/update",
		VERIFY_EMAIl: "auth/public/reset-password/verify"
	},
	CUSTOMER_MOBILE_USER: {
		DEACTIVATE_ACCOUNT: "customer-user/public/account/deactivate",
		VERIFY_EMAIl: "customer-user/public/account/deactivate/verify"
	},
	EMPLOYEE_MOBILE_USER: {
		DEACTIVATE_ACCOUNT: "staff-user/public/account/deactivate",
		VERIFY_EMAIl: "staff-user/public/account/deactivate/verify"
	},
	PROFILE: {
		PROFILE: "staff-user/whoami",
		CHANGE_PASSWORD: "staff-user/change-password/update"
	},
	ACCOUNT: {
		OTP_CODE_VERIFY: "auth/account/otp-code/verify",
		OTP_RESEND: "auth/account/otp-code/resend"
	},
	CUSTOMER: {
		CUSTOMER: "customer/customer-maintenance/",
		CUSTOMERS: "customer/customer-maintenance/search",
		CUSTOMERS_LOOKUP: "customer/customer-maintenance/search/lookup",
		CREATE_CUSTOMER: "customer/customer-maintenance/create",
		UPDATE_CUSTOMER: "customer/customer-maintenance/update",
		CUSTOMER_PIC: "customer/customer-maintenance/{customer-id}/pic/search",
		CREATE_CUSTOMER_PIC: "customer/customer-maintenance/pic/create",
		UPDATE_CUSTOMER_PIC: "customer/customer-maintenance/pic/update",
		CONTRACT: "customer/contract-maintenance/",
		CONTRACTS: "customer/contract-maintenance/search",
		CONTRACTS_SITES: "customer/contract-maintenance/{contract-id}/sites/search",
		CONTRACTS_PIC: "customer/contract-maintenance/{contract-id}/pic/search",
		ADD_ALL_CONTRACTS_PIC: "customer/contract-maintenance/pic/add-all",
		DELETE_CONTRACT_PIC: "customer/contract-maintenance/pic/delete/{contract-id}/{pic-id}",
		CONTRACTS_EXCLUSIVE_PIC: "customer/contract-maintenance/{contract-id}/pic/exclusive/search",
		CONTRACTS_BY_PIC: "customer/contract-maintenance/{pic-id}/contractByPic/search",
		CREATE_CONTRACT: "customer/contract-maintenance/create",
		UPDATE_CONTRACT: "customer/contract-maintenance/update",
		DOCS: "customer/contract-maintenance/{contract-id}/docs",
		DELETE_DOC: "customer/contract-maintenance/docs/{doc-id}/delete",
		DOWNLOAD_DOC: "customer/contract-maintenance/docs/{doc-id}/download",
		UPLOAD_DOC: "customer/contract-maintenance/{contract-id}/docs/upload",
		DELETE_CONTRACT_COST_CENTRE: "customer/contract-maintenance/contract-cost-center/{contract-cost-center-id}/delete",
		CREATE_CONTRACT_COST_CENTRE: "customer/contract-maintenance/contract-cost-center/create",
		SEARCH_CONTRACT_COST_CENTRE: "customer/contract-maintenance/{customer-contract-id}/contract-cost-center/search"
	},
	CUSTOMER_SITE_MAINTENANCE: {
		SITE: "customer/site-maintenance/",
		CREATE_SITE: "customer/site-maintenance/create",
		UPDATE_SITE: "customer/site-maintenance/update",
		ASSETS: "customer/site-maintenance/{customer-contract-site-id}/assets/search",
		UPLOAD_ASSETS: "customer/site-maintenance/{customer-contract-site-id}/assets/upload",
		ADD_ALL_SITE_PIC: "customer/site-maintenance/pic/add-all",
		DELETE_SITE_PIC: "customer/site-maintenance/pic/delete/{customer-contract-site-id}/{pic-id}",
		SITE_PIC: "customer/site-maintenance/{customer-contract-site-id}/pic/search",
		EXCLUSIVE_SITE_PIC: "customer/site-maintenance/{customer-contract-site-id}/pic/exclusive/search"
	},
	ASSET_MAINTENANCE: {
		ASSET: "customer/asset-maintenance/",
		ASSET_SERVICE: "customer/asset-maintenance/{asset-id}/services/{service-id}",
		ASSET_SERVICES: "customer/asset-maintenance/{asset-id}/services/search",
		ASSET_SERVICES_LIST: "customer/asset-maintenance/{asset-id}/services/",
		ASSET_EXCLUSIVE_SERVICES_SEARCH: "customer/asset-maintenance/{asset-id}/services/exclusive/search",
		ASSET_EXCLUSIVE_SERVICE_CHECKLIST_SEARCH: "customer/asset-maintenance/{asset-id}/service-checklist/exclusive/search",
		CREATE_ASSET_SERVICE: "customer/asset-maintenance/{asset-id}/services/create",
		ADD_ASSET_SERVICES: "customer/asset-maintenance/{asset-id}/services/add",
		ADD_ASSET_SERVICE_CHECKLIST: "customer/asset-maintenance/{asset-id}/service-checklist/add",
		ASSET_SERVICES_REORDER: "customer/asset-maintenance/{asset-id}/services/reorder",
		UPDATE_ASSET: "customer/asset-maintenance/{asset-id}/update",
		DELETE_ASSET: "customer/asset-maintenance/{asset-id}/delete",
		UPDATE_ASSET_SERVICE: "customer/asset-maintenance/services/{service-id}/update",
		UPDATE_ASSET_SERVICES: "customer/asset-maintnenance/{asset-id}/services/update",
		UPDATE_ASSET_SERVICE_STATUS: "customer/asset-maintenance/{asset-id}/services/{service-id}/status/{status-update}",
		GENERATE_ASSET_PDF: "customer/asset-maintenance/generate-qr-pdf"
	},
	CUSTOMER_ENQUIRY: {
		ENQUIRY: "customer/customer-enquiry/",
		OPEN_ENQUIRIES: "customer/customer-enquiry/open/search",
		CLOSED_ENQUIRIES: "customer/customer-enquiry/closed/search",
		DOWNLOAD_DOC: "customer/customer-enquiry/docs/{doc-id}/download",
		CONFIGURATION: "customer/customer-enquiry/configuration",
		UPDATE_ENQUIRY: "customer/customer-enquiry/update"
	},
	USER_ACCESS: {
		ROLE: "general/role/maintenance/",
		ROLES: "general/role/maintenance/search",
		CREATE_ROLE: "general/role/maintenance/create",
		UPDATE_ROLE: "general/role/maintenance/update",
		USER: "staff-user/user-access/",
		USERS: "staff-user/user-access/search",
		CREATE_USER: "staff-user/user-access/add-user",
		UPDATE_USER: "staff-user/user-access/update-user",
		CHANGE_PASSWORD: "staff-user/user-access/change-password",
		UPDATE_AVATAR: "staff-user/avatar"
	},
	HUMAN_RESOURCE: {
		POSITIONS: "hr/position/search",
		CREATE_POSITION: "hr/position/create",
		UPDATE_POSITION: "hr/position/update",
		LEAVE_TYPES: "hr/leave-type/search",
		CREATE_LEAVE_TYPE: "hr/leave-type/create",
		UPDATE_LEAVE_TYPE: "hr/leave-type/update",
		LEVELS: "hr/position-level/search",
		CREATE_LEVEL: "hr/position-level/create",
		UPDATE_LEVEL: "hr/position-level/update",
		DEPARTMENTS: "hr/department/search",
		CREATE_DEPARTMENT: "hr/department/create",
		UPDATE_DEPARTMENT: "hr/department/update",
		EMPLOYEES: "/hr/employee/maintenance/search",
		EMPLOYEE_HR: "/hr/employee/maintenance/{user-id}/reporting-manager-supervisor",
		CREATE_EMPLOYEE: "hr/employee/maintenance/create",
		UPDATE_EMPLOYEE: "hr/employee/maintenance/update-overwrite",
		EMPLOYEE: "hr/employee/maintenance/",
		EMPLOYEE_SIGNATURE_UPLOAD: "hr/employee/maintenance/signature/upload",
		EMPLOYEE_SIGNATURE_VIEW: "hr/employee/maintenance/signature/view",
		CLAIM_TYPES: "hr/claim-type/search",
		CREATE_CLAIM_TYPE: "hr/claim-type/create",
		UPDATE_CLAIM_TYPE: "hr/claim-type/update",
		EMPLOYEE_LEAVE_SUMMARY: "hr/employee-leave-management/employee/{employee-id}/leave-summary",
		EMPLOYEE_LEAVE_HISTORY: "hr/employee-leave-management/employee/{employee-id}/leave-history/search",
		EMPLOYEE_PENDING_LEAVE: "hr/employee-leave-management/employee/{employee-id}/pending-leave/search",
		EMPLOYEE_CLAIM_SUMMARY: "hr/employee-claim-management/employee/{employee-id}/claim-summary",
		EMPLOYEE_CLAIM_HISTORY: "hr/employee-claim-management/employee/{employee-id}/claim-history/search",
		EMPLOYEE_PENDING_CLAIM: "hr/employee-claim-management/employee/{employee-id}/pending-claim/search",
		APPROVE_EMPLOYEE_LEAVE: "hr/employee-leave-management/application/{leave-id}/approve",
		REJECT_EMPLOYEE_LEAVE: "hr/employee-leave-management/application/{leave-id}/reject",
		APPROVE_EMPLOYEE_CLAIM: "hr/employee-claim-management/application/{claim-id}/approve",
		REJECT_EMPLOYEE_CLAIM: "hr/employee-claim-management/application/{claim-id}/reject",
		PAID_EMPLOYEE_CLAIM: "hr/employee-claim-management/application/{claim-id}/paid",
		HOLIDAYS: "hr/holiday/search",
		HOLIDAY_CALENDAR: "/hr/holiday/calendar/year/{year}/month/{month}",
		SUBORDINATE_LEAVE_CALENDAR: "hr/employee-leave-management/overview/year/{year}/month/{month}",
		CREATE_HOLIDAY: "hr/holiday/create",
		UPDATE_HOLIDAY: "hr/holiday/update",
		DELETE_HOLIDAY: "hr/holiday/{holiday-id}/delete",
		UNASSIGNED_USER_ID: "hr/employee/unassigned-user-id/search",
		LEAVE_PACKAGE: "hr/remuneration-maintenance/leave-package/",
		DROP_DOWN_LEAVE_TYPE: "hr/remuneration-maintenance/leave-package/leave-type/drop-down-list",
		CLAIM_PACKAGE: "hr/remuneration-maintenance/claim-package/",
		DROP_DOWN_CLAIM_TYPE: "hr/remuneration-maintenance/claim-package/claim-type/drop-down-list",
		LEAVE_PACKAGES: "hr/remuneration-maintenance/leave-package/search",
		CLAIM_PACKAGES: "hr/remuneration-maintenance/claim-package/search",
		LEAVE_HISTORY: "hr/employee-leave-management/leave-history/search",
		PENDING_LEAVE: "hr/employee-leave-management/pending-leave/search",
		DOWNLOAD_DOC_LEAVE: "hr/employee-leave-management/docs/{doc-id}/download",
		DOWNLOAD_ALL_DOC_LEAVE: "hr/employee-leave-management/docs/download",
		DOWNLOAD_DOC_CLAIM: "hr/employee-claim-management/docs/{doc-id}/download",
		DOWNLOAD_ALL_DOC_CLAIM: "hr/employee-claim-management/docs/download",
		CLAIM_HISTORY: "hr/employee-claim-management/claim-history/search",
		PENDING_CLAIM: "hr/employee-claim-management/pending-claim/search",
		EMPLOYEE_DIRECTORY: "hr/employee-leave-management/employee-directory",
		CREATE_LEAVE_PACKAGES: "hr/remuneration-maintenance/leave-package/create",
		UPDATE_LEAVE_PACKAGES: "hr/remuneration-maintenance/leave-package/update",
		CREATE_CLAIM_PACKAGES: "hr/remuneration-maintenance/claim-package/create",
		UPDATE_CLAIM_PACKAGES: "hr/remuneration-maintenance/claim-package/update",
		APPROVE_PENDING_LEAVE: "hr/employee-leave-management/application/approve",
		REJECT_PENDING_LEAVE: "hr/employee-leave-management/application/reject",
		APPROVE_PENDING_CLAIM: "hr/employee-claim-management/application/approve",
		REJECT_PENDING_CLAIM: "hr/employee-claim-management/application/reject",
		DELETE_LEAVE_PACKAGES: "hr/remuneration-maintenance/leave-package/leave-benefit/{leave-package-id}/delete",
		DELETE_CLAIM_PACKAGES: "hr/remuneration-maintenance/claim-package/claim-benefit/{claim-package-id}/delete",
		ATTENDANCE_EMPLOYEE_DETAILS: "hr/attendance-management/employee/{employee-id}/details",
		ATTENDANCE_EMPLOYEE_DIRECTORY: "hr/attendance-management/employee-directory",
		ATTENDANCE_SUMMARY_EMPLOYEE: "hr/attendance-management/{user-id}/attendance-summary",
		SCHEDULED_ATTENDANCE_EMPLOYEE: "hr/attendance-management/scheduled-attendance",
		CORRECTIVE_ATTENDANCE_HISTORY: "hr/attendance-management/{user-id}/corrective-attendance-history",
		PENDING_CORRECTIVE_ATTENDANCE: "hr/attendance-management/{user-id}/pending-corrective-attendance",
		CORRECTIVE_ATTENDANCE_HISTORY_GENERAL: "hr/attendance-management/corrective-attendance-history",
		PENDING_CORRECTIVE_ATTENDANCE_GENERAL: "hr/attendance-management/pending-corrective-attendance",
		UPDATE_SCHEDULED_ATTENDANCE_EMPLOYEE: "hr/attendance-management/scheduled-attendance/update",
		DELETE_SCHEDULED_ATTENDANCE_EMPLOYEE: "hr/attendance-management/{shift-id}/scheduled-attendance/delete",
		SET_AS_REST_DAY_SCHEDULED_ATTENDANCE_EMPLOYEE: "hr/attendance-management/{scheduled-id}/scheduled-attendance/set-as-rest-day",
		SET_AS_WORKING_DAY_SCHEDULED_ATTENDANCE_EMPLOYEE: "hr/attendance-management/{scheduled-id}/scheduled-attendance/set-as-working-day",
		UPDATE_CORRECTIVE_ATTENDANCE_STATUS: "hr/attendance-management/corrective-attendance/status/update",
		CREATE_SCHEDULE_ATTENDANCE: "hr/attendance-management/schedule-attendance",
		UPDATE_SCHEDULE_ATTENDANCE: "hr/attendance-management/schedule-attendance/update",
		GET_EMPLOYEE_SCHEDULE_ATTENDANCE: "hr/attendance-management/{employee-id}/schedule-attendance",
		GET_SCHEDULE_ATTENDANCE: "hr/attendance-management/schedule-attendance/{id}",
		PERMISSION_OVERRIDE_WORK_ORDER: "hr/employee/maintenance/{user-id}/override-work-order",
		GET_CURRENT_SHIFT: "hr/attendance-management/{employee-id}/scheduled-attendance/current-shift",
		UPDATE_MAINTAIN_CUSTOM_SHIFT: "hr/employee/maintenance/update-maintain-custom-shift"
	},
	WORK_INSPECTION_REPORT: {
		REPORT: "work-order/inspection/",
		REPORT_LITE: "work-order/inspection/{work-inspection-id}/view-lite",
		REPORTS: "work-order/inspection/get-wir",
		CREATE: "work-order/inspection/create",
		UPDATE: "work-order/inspection/update",
		SIGNATURE_UPLOAD: "work-order/inspection/signature/upload",
		ASSETS: "work-order/inspection-asset/set",
		VIEW_ASSET: "work-order/inspection-asset/{asset-id}/view",
		VIEW_FINDING: "work-order/inspection-asset/{finding-id}/view-finding",
		ASSET_LISTING: "work-order/inspection-asset/{work-inspection-report-id}/getAsset-listing",
		ASSET_FINDINGS: "work-order/inspection-asset/{asset-id}/get-findings",
		CREATE_ASSET_FINDING: "work-order/inspection-asset/add-finding",
		UPDATE_ASSET_FINDING: "work-order/inspection-asset/update-finding",
		UPLOAD_ASSET_FINDING_ATTACHMENT: "work-order/inspection-asset/attachments/upload",
		DELETE_ASSET_FINDING: "work-order/inspection-asset/{finding-id}/delete-finding",
		ASSET_LISTING_DROPDOWN: "work-order/inspection-asset/{work-inspection-id}/getAsset-listing",
		DELETE_FINDING_ATTACHMENT: "work-order/inspection-asset/{doc-id}/attachments/delete",
		DOWNLOAD_WIR_DOC: "work-order/inspection-asset/img/{doc-id}/download",
		TEAM_MEMBERS: "work-order/inspection-member/{work-inspection-id}/view",
		ADD_TEAM_MEMBER: "work-order/inspection-member/add",
		DELETE_TEAM_MEMBER: "work-order/inspection-member/delete",
		AUTO_CONVERT: "work-order/inspection/work-order/auto-convert",
		WORK_ORDER_REPORTS: "work-order/inspection/{work-inspection-id}/get-wo",
		GENERATE_WORK_INSPECTION_REPORT_PDF: "work-order/inspection/{work-inspection-id}/pdf"
	},
	WORK_ORDER: {
		WORK_ORDER: "work-order/order/",
		WORK_ORDERS: "work-order/order/get-wo",
		UNASSIGNED_WORK_COMPLETION: "work-order/order/get-wo-unassigned-work-completion",
		UNASSIGNED_SALES_ORDER: "work-order/order/get-wo-unassigned-sales-order",
		CREATE: "work-order/order/create",
		UPDATE: "work-order/order/update",
		SIGNATURE_UPLOAD: "work-order/order/signature/upload",
		TEAM_MEMBERS: "work-order/order-member/{work-order-id}/view",
		ADD_TEAM_MEMBERS: "work-order/order-member/add",
		DELETE_TEAM_MEMBER: "work-order/order-member/delete",
		SERVICE: "work-order/order-service/set",
		SERVICE_LISTING: "work-order/order-service/{work-order-id}/getOrder-listing",
		RECORD_IDS: "work-order/order-record/{work-order-asset-id}/get-records",
		CREATE_RECORD: "work-order/order-record/add-record",
		UPDATE_RECORD: "work-order/order-record/update-record",
		UPLOAD_RECORD_ATTACHMENT: "work-order/order-record/images/upload",
		DELETE_RECORD: "work-order/order-record/{record-id}/delete-record",
		DELETE_RECORD_ATTACHMENT: "work-order/order-record/{attachment-id}/delete-record-attachment",
		DOWNLOAD_ATTACHMENT: "work-order/order-record/img/{attachment-id}/download",
		VIEW_RECORD: "work-order/order-record/{record-id}/view-record",
		INVENTORIES: "work-order/order-inventory/{work-order-id}/search",
		INVENTORY: "work-order/order-inventory/",
		CREATE_INVENTORY: "work-order/order-inventory/create",
		UPDATE_INVENTORY: "work-order/order-inventory/update",
		DELETE_INVENTORY: "work-order/order-inventory/{work-order-inventory-id}/delete",
		GENERATE_WORK_ORDER_PDF: "work-order/order/{work-order-id}/pdf"
	},
	WORK_COMPLETION_REPORT: {
		REPORTS: "work-order/completion/get-wcr",
		REPORT: "work-order/completion/",
		REPORT_WITHOUT_QUOTATION: "work-order/completion/get-wcr-without-quotation",
		CREATE_WORK_COMPLETION_REPORT: "work-order/completion/create",
		UPDATE_WORK_COMPLETION_REPORT: "work-order/completion/update",
		SIGNATURE_UPLOAD: "work-order/completion/signature/upload",
		GENERATE_WORK_COMPLETION_REPORT_PDF: "work-order/completion/{work-completion-id}/pdf"
	},
	INVENTORY: {
		INVENTORY: "inventory/inventory/{inventory-id}",
		INVENTORIES: "inventory/inventory/search",
		INVENTORY_REQUEST: "inventory/Inventory-history-request/",
		INVENTORY_REQUESTS: "inventory/Inventory-history-request/search",
		UPLOAD_DOC: "inventory/Inventory-history-request/{request-id}/docs/upload",
		UPDATE_REQUEST: "inventory/Inventory-history-request/update",
		CREATE_INVENTORY: "inventory/inventory/create",
		UPDATE_INVENTORY: "inventory/inventory/update",
		RETIRE_INVENTORY: "inventory/inventory/retired/{inventory-id}",
		INVENTORY_MOVEMENT: "inventory/Inventory-history-movement/",
		INVENTORY_MOVEMENTS: "inventory/Inventory-history-movement/search",
		UPDATE_STOCK: "inventory/inventory/stock/update",
		TRANSFER_INVENTORY: "inventory/inventory/transfer",
		CONFIGURATIONS: "inventory/location-config/search",
		OPTION_LOCATION: "inventory/inventory/{inventory-id}/location/options",
		OPTION_CONFIGURATION: "inventory/location-config/options",
		CREATE_CONFIGURATION: "inventory/location-config/create",
		UPDATE_CONFIGURATION: "inventory/location-config/update",
		OPTIONS_CONFIGURATION: "inventory/location-config/options"
	},
	MOBILE: {
		USER: "customer/customer-user/",
		USERS: "customer/customer-user/search",
		APPROVAL: "customer/customer-user/approval",
		CHANGE_PASSWORD: "customer/customer-user/change-password",
		CHANGE_USER_STATUS: "customer/customer-user/{id}/status/{user-status}"
	},
	CUSTOMER_CONFIGURATION: {
		BULDING_TYPE: "customer/config/building-type/",
		BULDING_TYPES: "customer/config/building-type/search",
		CREATE_BUILDING_TYPE: "customer/config/building-type/create",
		UPDATE_BUILDING_TYPE: "customer/config/building-type/update",
		WORK_SPACE: "customer/config/workspace/",
		WORK_SPACES: "customer/config/workspace/search",
		CREATE_WORKSPACE: "customer/config/workspace/create",
		UPDATE_WORKSPACE: "customer/config/workspace/update",
		ASSET_TYPE: "customer/config/asset-type/",
		ASSET_TYPES: "customer/config/asset-type/search",
		CREATE_ASSET_TYPE: "customer/config/asset-type/create",
		UPDATE_ASSET_TYPE: "customer/config/asset-type/update"
	},
	SPARE_PART: {
		SPARE_PART: "general/config/spare-part/",
		SPARE_PARTS: "general/config/spare-part/search",
		CREATE_SPARE_PART: "general/config/spare-part/create",
		UPDATE_SPARE_PART: "general/config/spare-part/update",
		DOCS: "general/config/spare-part/{spare-part-id}/docs",
		UPLOAD_DOC: "general/config/spare-part/{spare-part-id}/docs/upload",
		DOWNLOAD_DOC: "general/config/spare-part/docs/{doc-id}/download",
		SERVICE_CONFIGS: "general/config/spare-part/service-config/search",
		SERVICE_CONFIGS_LIST: "general/config/spare-part/service-config/list",
		SERVICE_CONFIG_ADD_ALL: "general/config/spare-part/service-config/add-all",
		EXCLUSIVE_SERVICE_LISTINGS: "general/config/spare-part/service-config/exclusive/search",
		REMOVE_SERVICE_CONFIG: "general/config/spare-part/service-config/delete"
	},
	INPUT: {
		INPUT: "general/config/input/builders/",
		INPUTS: "general/config/input/builders/search",
		CREATE_INPUT: "general/config/input/createBuilder",
		UPDATE_INPUT: "general/config/input/updateBuilder"
	},
	SPARE_PART_CATEGORY: {
		SPARE_PART_CATEGORY: "general/config/spare-part-category/",
		SPARE_PART_CATEGORIES: "general/config/spare-part-category/search",
		CREATE_SPARE_PART_CATEGORY: "general/config/spare-part-category/create",
		UPDATE_SPARE_PART_CATEGORY: "general/config/spare-part-category/update"
	},
	SPARE_PART_SUB_CATEGORY: {
		SPARE_PART_SUB_CATEGORY: "general/config/spare-part-sub-category/",
		SPARE_PART_SUB_CATEGORIES: "general/config/spare-part-sub-category/search",
		CREATE_SPARE_PART_SUB_CATEGORY: "general/config/spare-part-sub-category/create",
		UPDATE_SPARE_PART_SUB_CATEGORY: "general/config/spare-part-sub-category/update"
	},
	SERVICE_LISTING: {
		SERVICE_LISTING: "general/config/service/",
		SERVICE_LISTINGS: "general/config/service/search",
		CREATE_SERVICE_LISTING: "general/config/service/create",
		UPDATE_SERVICE_LISTING: "general/config/service/update",
		EXCLUSIVE_ID_SEARCH: "general/config/service/exclusive-id/search",
		SUBTASK: "general/config/service-subtask/",
		SUBTASKS: "general/config/service-subtask/search",
		SUBTASK_LIST: "general/config/service-subtask/list/",
		CREATE_SUBTASK: "general/config/service-subtask/create",
		UPDATE_SUBTASK: "general/config/service-subtask/update",
		REORDER_SUBTASK: "general/config/service/{service-id}/subtask-reorder",
		DELETE_SUBTASK: "general/config/service-subtask/delete"
	},
	SERVICE_CHECKLIST: {
		SERVICE_CHECKLIST: "general/config/service-checklist/",
		SERVICE_CHECKLISTS: "general/config/service-checklist/search",
		CREATE_SERVICE_CHECKLIST: "general/config/service-checklist/create",
		UPDATE_SERVICE_CHECKLIST: "general/config/service-checklist/update",
		SERVICE_CONFIGS: "general/config/service-checklist/service-config/search",
		SERVICE_CONFIGS_LIST: "general/config/service-checklist/service-config/list",
		SERVICE_CONFIG_ADD_ALL: "general/config/service-checklist/service-config/add-all",
		EXCLUSIVE_SERVICE_LISTINGS: "general/config/service-checklist/service-config/exclusive/search",
		REMOVE_SERVICE_CONFIG: "general/config/service-checklist/service-config/delete",
		REORDER_SERVICE_CONFIG: "general/config/service-checklist/service-config/reorder"
	},
	COST_CENTER: {
		UPDATE_WORKING_SHIFT: "general/cost-center/{working-shift-id}/update",
		DELETE_WORKING_SHIFT: "general/cost-center/{working-shift-id}/delete",
		CREATE_WORKING_SHIFT: "general/cost-center/{cost-center-id}/working-shift/create",
		UPDATE_COST_CENTER: "general/cost-center/update",
		CREATE_COST_CENTER: "general/cost-center/create",
		GET_COST_CENTER: "general/cost-center/{id}",
		SEARCH_WORKING_SHIFT: "general/cost-center/{cost-center-id}/working-shift/search",
		OPTION_WORKING_SHIFT: "general/cost-center/{cost-center-id}/working-shift/options",
		SEARCH_TEAM_MEMBER_LISTING: "general/cost-center/{cost-center-id}/team-member-listing/search",
		GET_WORKING_SHIFT: "general/cost-center/working-shift/{id}",
		SEARCH_COST_CENTER: "general/cost-center/search",
		OPTION_COST_CENTER: "general/cost-center/options"
	},
	CONTRACT: {
		CONTRACT: "general/config/contract/",
		CONTRACTS: "general/config/contract/search",
		CREATE_CONTRACT: "general/config/contract/create",
		UPDATE_CONTRACT: "general/config/contract/update"
	},
	QUOTATION: {
		QUOTATIONS: "payment/quotation/search",
		QUOTATION: "payment/quotation/{quotation-id}",
		QUOTATION_ITEM: "payment/quotation/item/{item-id}",
		CREATE_QUOTATION: "payment/quotation/create",
		CREATE_ITEM: "payment/quotation/create/item",
		CREATE_SUB_ITEM: "payment/quotation/create/sub-item",
		UPDATE_QUOTATION: "payment/quotation/update",
		UPDATE_ITEM: "payment/quotation/update/item",
		UPDATE_SUB_ITEM: "payment/quotation/update/sub-item",
		REMOVE_ITEM: "payment/quotation/remove/item/{item-id}",
		REMOVE_SUB_ITEM: "payment/quotation/remove/sub-item/{subItem-id}",
		CLONE: "payment/quotation/clone",
		CLOSE: "payment/quotation/close/{close-id}",
		GENERATE_QUOTATION_PDF: "payment/quotation/{quotation-id}/pdf"
	},
	SALES_ORDERS: {
		LISTINGS: "payment/sale-order/search",
		SALES_ORDER: "payment/sale-order/{id}",
		CONVERT: "payment/sale-order/convert-to-sales-order",
		UPDATE: "payment/sale-order/update",
		SALES_ORDER_DROP_OFF: "payment/sale-order/drop-off/",
		REMOVE_ITEM: "payment/sale-order/remove/item/{item-id}",
		REMOVE_SUB_ITEM: "payment/sale-order/remove/sub-item/{subItem-id}",
		CREATE_ITEM: "payment/sale-order/create/item",
		CREATE_SUB_ITEM: "payment/sale-order/create/sub-item",
		UPDATE_ITEM: "payment/sale-order/update/item",
		UPDATE_SUB_ITEM: "payment/sale-order/update/sub-item",
		SALES_ORDER_ITEM: "payment/sale-order/item/{item-id}",
		GENERATE_SALES_ORDER_PDF: "payment/sale-order/{sale-order-id}/pdf"
	},
	DELIVERY_ORDERS: {
		DELIVERY_ORDER: "payment/delivery-order/",
		DELIVERY_ORDERS: "payment/delivery-order/search",
		CREATE_DELIVERY_ORDER: "payment/delivery-order/create",
		UPDATE_DELIVERY_ORDER: "payment/delivery-order/update",
		UPDATE_DELIVERY_ORDER_STATUS: "payment/delivery-order/update-status",
		DELIVERY_ORDER_SEARCH: "payment/delivery-order/{id}/search",
		DELIVERY_ORDER_ITEM: "payment/delivery-order/item/{item-id}",
		GENERATE_DELIVERY_ORDER_PDF: "payment/delivery-order/{delivery-order-id}/pdf"
	},
	INVOICE: {
		INVOICE: "payment/invoice/",
		INVOICES: "payment/invoice/search",
		INVOICE_DOC: "payment/invoice/doc/",
		SALES_ORDER_SEARCH: "payment/invoice/{sales-order-id}/search",
		BASE_ITEMS: "payment/invoice/base-item/{invoice-id}",
		CONFIRMED_ITEMS: "payment/invoice/confirmed-item/{invoice-id}",
		CREATE_INVOICE: "payment/invoice/create/",
		UPDATE_INVOICE: "payment/invoice/update",
		INVOICE_PAID: "payment/invoice/paid/{invoice-id}",
		INVOICE_VOID: "payment/invoice/void/",
		DOWNLOAD_PAID_INVOICE: "payment/invoice/paid/download/{invoice-id}",
		GENERATE_INVOICE_PDF: "payment/invoice/{invoice-id}/pdf"
	},
	OVERTIME: {
		DELETE_OT_RATE: "hr/overtime-management/{ot-rate-id}/delete",
		EXCEL_WORK_ORDER_OVERTIME: "hr/overtime-management/{employee-id}/work-order-overtime/excel",
		UPDATE_OVERTIME: "hr/overtime-management/update",
		CREATE_OVERTIME: "hr/overtime-management/create",
		GET_WORK_ORDER_OVERTIME: "hr/overtime-management/{employee-id}/work-order-overtime",
		GET_ACCUMULATED_OVERTIME: "hr/overtime-management/{employee-id}/accumulated-overtime",
		GET_OT_RATE: "hr/overtime-management/ot-rate",
		GET_EMPLOYEE_DIRECTORY_OVERTIME: "hr/overtime-management/employee-directory-overtime"
	}
};

export default ENDPOINT_PATH;
