import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import { Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

import AppButton from "components/app-button";

export const AppWorkOrderHoldOnModal = (props, ref) => {
	const navigate = useNavigate();
	const [visible, setVisible] = useState(false);
	const [workOrderType, setWorkOrderType] = useState({});

	const onHandleConfirm = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback((params) => {
		setWorkOrderType(params);
		
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	const onHandleBack = useCallback(() => {
		navigate(pathnames.workOrder.workOrder + workOrderType.type);
	}, [navigate, workOrderType]);

	return (
		<Modal classes={{ root: "app-work-order-hold-on-modal" }} open={visible}>
			<div className="work-order-hold-on-modal">
				<h1 className="work-order-hold-on-modal__title">Hold On!</h1>

				<p className="work-order-hold-on-modal__description">Are you sure you would like to leave this page?</p>

				<p className="work-order-hold-on-modal__description">By leaving, the changes that you made will not be saved.</p>

				<div className="work-order-hold-on-modal__button-container">
					<AppButton outline type="button" label="Leave Page" onClick={onHandleBack} />

					<AppButton type="button" label="Stay in Page" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppWorkOrderHoldOnModal));
