import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getStateListing from "services/get-state-listing";
import getWeekdaysListing from "services/get-week-days-listing";
import getUserStatusListing from "services/get-user-status-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppSelectInput from "components/app-select-input";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppInputMultiSelect from "components/app-input-select-multiple";
import AppAddWorkingShiftModal from "components/pages/operations/general-configuration/app-add-working-shift-modal";
import AppCustomerConfirmMobileAccountModal from "components/pages/customer/app-customer-confirm-mobile-account-modal";
import AppWorkingShiftsDeleteModal from "components/pages/operations/general-configuration/app-working-shifts-delete-modal";

import editIcon from "assets/images/edit-icon.png";
import trashIcon from "assets/images/trash-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";
import faqIcon from "assets/images/pages/general-configuration/faq-icon.svg";
import verticalBreadcrumbsIcon from "assets/images/vertical-breadcrumbs-icon.png";

const PageCostCentreCreateEdit = (props) => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();
	const [searchPic, setSearchPic] = useState("");
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Create Cost Centre" : "Edit Cost Centre"), [isCreate]);
	const submitLabel = useMemo(() => (!isCreate ? "Update" : "Add"), [isCreate]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CONTRACT_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const tableNumber = useMemo(() => (isCreate ? "number" : "paginationNumbers"), [isCreate]);
	const queryParams = useRef({ page: 0, keyword: "", sort: "", status: [STATUS.ACTIVE, STATUS.INACTIVE] });
	const defaultKeyword = useMemo(() => (isCreate ? searchPic : queryParams.current.keyword), [isCreate, searchPic]);
	const [data, setData] = useState(convertPaginationTableData());
	const [teamMemberData, setTeamMemberData] = useState(convertPaginationTableData());
	const selectedItem = useRef();
	const workingShiftAddItemModalRef = useRef();
	const workingShiftDeleteItemModalRef = useRef();
	const confirmMobileAccountModalRef = useRef();
	const [itemTableAnchor, setItemTableAnchor] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [stateListing, setStateListing] = useState([]);
	const [searchTeamMember, setSearchTeamMember] = useState("");

	// prettier-ignore
	const initialValues = useMemo(() => ({ costCentreName: "", status: "", configCostCenterStates: [], configCostCenterWorkingDays: [], latitudeAndLongitude: "", openLocation: false, configCostCenterWorkingShifts: [], teamMemberListing: [], originalStateData: [], originalWorkingDaysData: [] }), []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			costCentreName: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			configCostCenterStates: yup.array().min(1, ERRORS.REQUIRED).required(ERRORS.REQUIRED),
			configCostCenterWorkingDays: yup.array().min(1, ERRORS.REQUIRED).required(ERRORS.REQUIRED),
			latitudeAndLongitude: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const localWorkingShiftTableData = useMemo(() => {
		const sanitizedSearch = searchPic.trim().toLowerCase();

		const filtered = formik.values.configCostCenterWorkingShifts.filter((o) => {
			const sanitizedShiftName = o.shiftName.trim().toLowerCase();

			return sanitizedShiftName.includes(sanitizedSearch);
		});

		return filtered;
	}, [formik.values.configCostCenterWorkingShifts, searchPic]);

	const configCostCenterWorkingShiftsTableData = useMemo(() => {
		if (isCreate) {
			return localWorkingShiftTableData;
		} else {
			return data.content;
		}
	}, [data.content, isCreate, localWorkingShiftTableData]);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Operations", path: pathnames.operations.generalConfiguration + "?tab=COST_CENTRE" },
			{ label: "General Configuration", path: pathnames.operations.generalConfiguration + "?tab=COST_CENTRE" }
		];

		if (isCreate) {
			data.push({ label: "Create Cost Centre", path: pathnames.operations.costCentreCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: "Edit Cost Centre", path: pathnames.operations.costCentreCreateEdit + id });
		}

		return data;
	}, [isCreate, id]);

	useBreadcrumb({ breadCrumb });

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const onHandleGetWorkingShifts = useCallback(async (uniqueId) => {
		let response = null;

		try {
			const params = sanitizeObject({
				"cost-center-id": uniqueId,
				queryParams: { ...queryParams.current, size: 10 }
			});

			response = await api.get.costCenter.searchWorkingShift(params);
		} catch (error) {
			promptLayoutAlertMessage(error);
		}

		if (response) {
			response.content = response.content.sort((a, b) => a.seq - b.seq);

			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	const onHandleGetTeamMemberListing = useCallback(async (uniqueId) => {
		let response = null;

		try {
			const params = sanitizeObject({
				"cost-center-id": uniqueId,
				queryParams: { ...queryParams.current, size: 10 }
			});

			response = await api.get.costCenter.searchTeamMemberListing(params);
		} catch (error) {
			promptLayoutAlertMessage(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setTeamMemberData(obj);
		}
	}, []);

	// prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		let params = {"id": uniqueId}

		try {
			response = await api.get.costCenter.getCostCenter(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const stateValues = response.configCostCenterStates.map(state => state.value);
			const workingDayValues = response.configCostCenterWorkingDays.map(day => day.value);

			memoSetFormValues({
				costCentreName: response.name,
				latitudeAndLongitude: response.latitudeAndLongitude,
				openLocation: response.openLocation,
				status: response.status,
				configCostCenterStates: stateValues,
				configCostCenterWorkingDays: workingDayValues,
				originalStateData: response.configCostCenterStates,
				originalWorkingDaysData: response.configCostCenterWorkingDays,
				configCostCenterWorkingShifts: response.configCostCenterWorkingShifts.map(shift => ({
					shiftName: shift.shiftName,
					startTime: shift.startTime,
					endTime: shift.endTime,
					status: shift.status,
					id: shift.id,
					seq: shift.seq
				}))
			});

			onHandleGetWorkingShifts(response.id);
			onHandleGetTeamMemberListing(response.id);
		}
	}, [memoSetFormValues, onHandleGetWorkingShifts, onHandleGetTeamMemberListing]);

	// prettier-ignore
	const onHandleCancel = useCallback(() => {
		navigate(pathnames.operations.generalConfiguration + "?tab=COST_CENTRE");
	}, [navigate]);

	// prettier-ignore
	const onHandleOpenLocation = useCallback((value) => {
		formik.setFieldValue("openLocation", value);
	}, [formik]);

	// prettier-ignore
	const onHandleAddEditCustomerItem = useCallback((values) => {
		workingShiftAddItemModalRef.current.onHandleShow(values);
	}, []);

	// prettier-ignore
	const onHandleShowConfirmAccountModal = useCallback((values) => {
		confirmMobileAccountModalRef.current.onHandleShow(values);
	}, []);

	// prettier-ignore
	const onHandleAddUpdateWorkingShift = useCallback(async (values) => {
		const isEditPic = values.id;
		let response = null;

		try {
			let payload = {
				shiftName: values.shiftName,
				status: values.status,
				startTime: dayjs(values.startTime).format(DATE_TIME.HH_MM_SS),
				endTime: dayjs(values.endTime).format(DATE_TIME.HH_MM_SS),
			};

			if (!isEditPic) {
				payload = { ...payload, "cost-center-id": id };
				response = await api.post.costCenter.createWorkingShift(payload);
			}

			if (isEditPic) {
				payload = { ...payload, "working-shift-id": values.id };

				response = await api.post.costCenter.updateWorkingShift(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (response.requireConfirmation) {
				return onHandleShowConfirmAccountModal(values);
			}

			if (isEditPic) {
				dispatch(promptLayoutAlertMessage({ message: "Working Shift was edited successfully!" }));
			}

			if (!isEditPic) {
				dispatch(promptLayoutAlertMessage({ message: "Working Shift was added successfully!" }));
			}

			onHandleGetDetails(id);

			onHandleGetWorkingShifts(id);
		}
	}, [dispatch, formik, id, onHandleGetDetails, onHandleGetWorkingShifts, onHandleShowConfirmAccountModal]);

	// prettier-ignore
	const onHandleEditWorkingShift = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setItemTableAnchor(event.currentTarget);
	}, [data]);

	// prettier-ignore
	const onHandleCreateItem = useCallback((values) => {
		const nextItems = [...formik.values.configCostCenterWorkingShifts, values].map((o, i) => ({ number: i + 1, ...o }));

		formik.setFieldValue("configCostCenterWorkingShifts", nextItems);
		
	}, [formik]);

	//prettier-ignore
	const onHandleEditItem = useCallback((values) => {
		const nextItems = [...formik.values.configCostCenterWorkingShifts];
		nextItems[values.number - 1] = values;

		formik.setFieldValue("configCostCenterWorkingShifts", nextItems);
	}, [formik]);

	const onHandleFormatTime = (timeString) => {
		if (!timeString) return "-";

		const timeParts = timeString?.split(":");
		const hours = parseInt(timeParts[0], 10);
		const minutes = timeParts[1];

		const period = hours >= 12 ? "PM" : "AM";
		const hours12 = hours % 12 || 12;

		return `${hours12}:${minutes} ${period}`;
	};

	// prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: tableNumber,
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "shiftName",
			label: "Shift Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "startTime",
			label: "Start Time",
			options: {
				sort: false,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					if (isCreate) {
						return dayjs(value).format(DATE_TIME.HH_MM_A);
					} else {
						return onHandleFormatTime(value);

					}
				}
			}
		},
		{
			name: "endTime",
			label: "End Time",
			options: {
				sort: false,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					if (isCreate) {
						return dayjs(value).format(DATE_TIME.HH_MM_A);
					} else {
						return onHandleFormatTime(value);

					}
				}
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "action",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const status = tableMeta.rowData[4];

					return status !== STATUS.DELETED && (
						<button type="button" className="table__action" onClick={(event) => onHandleEditWorkingShift(event, tableMeta)}>
							<img src={verticalBreadcrumbsIcon} alt="vertical-breadcrumbs-icon" />
					  	</button>
					);
				}
			}
		}
	], [onHandleEditWorkingShift, tableNumber, isCreate]);

	// prettier-ignore
	const onHandleRouteToShift = useCallback((id) => {
		navigate(pathnames.humanResources.employeeMaintenanceCreateEdit + id);
	}, [navigate]);

	// prettier-ignore
	const teamMemberColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "employeeId",
			label: "Employee Id",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "fullName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "employmentStatus",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => {
					return value ? <AppStatus status={value} /> : "-";
				}
			}
		},
		{
			name: "id",
			label: "Action",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleRouteToShift(value)}>
							<AppIcon src={chevronIcon} />
						</button>
					);
				}
			}
		}
	], [onHandleRouteToShift]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetWorkingShifts(id);
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetWorkingShifts(id);
					break;
				default:
					break;
			}
		}
	}), [id, data, onHandleGetWorkingShifts]);

	const teamMemberTableOptions = useMemo(
		() => ({
			count: teamMemberData.totalElements,
			page: teamMemberData.page,
			serverSide: true,
			onTableChange: (action, tableState) => {
				switch (action) {
					case "changePage":
						queryParams.current.page = tableState.page;
						onHandleGetTeamMemberListing(id);
						break;
					case "sort":
						queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };
						onHandleGetTeamMemberListing(id);
						break;
					default:
						break;
				}
			}
		}),
		[id, teamMemberData, onHandleGetTeamMemberListing]
	);

	const onHandleSearchTeamMembers = useCallback(
		(event) => {
			const value = event.target.value;

			queryParams.current.keyword = value;
			setSearchTeamMember(value);

			onHandleGetTeamMemberListing(id);
		},
		[id, onHandleGetTeamMemberListing]
	);

	const teamMemberEmptyState = useMemo(() => {
		if (teamMemberData.content && teamMemberData.content.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={teamMemberColumns.length} align="center">
						<p className="table__text">No Team Members Found.</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [teamMemberData.content, teamMemberColumns.length]);

	const onHandleCloseMenu = useCallback(() => {
		setItemTableAnchor(false);
	}, []);

	//prettier-ignore
	const onHandleSearchWorkingShifts = useCallback((event) => {
		const value = event.target.value;

		if (isCreate) {
			setSearchPic(value);
		} else {
			queryParams.current.keyword = value;

			onHandleGetWorkingShifts(id);
		}
	}, [id, isCreate, onHandleGetWorkingShifts]);

	const onHandleAddWorkingShiftItem = useCallback(() => {
		setItemTableAnchor(false);

		workingShiftAddItemModalRef.current.onHandleShow();
	}, []);

	const onHandleEditWorkingShiftItem = useCallback(() => {
		setItemTableAnchor(false);

		workingShiftAddItemModalRef.current.onHandleShow(selectedItem.current, formik.values.configCostCenterWorkingShifts);
	}, [formik.values.configCostCenterWorkingShifts]);

	const onHandleConfirmRemoveWorkingShiftItem = useCallback(() => {
		setItemTableAnchor(false);

		workingShiftDeleteItemModalRef.current.onHandleShow(selectedItem.current);
	}, []);

	const onHandleRemoveWorkingShiftItem = useCallback(async () => {
		const rowIndex = selectedItem.current.rowIndex;

		if (isCreate) {
			let nextItems = [...formik.values.configCostCenterWorkingShifts];

			nextItems = nextItems.filter((_, i) => i !== rowIndex);

			formik.setFieldValue("configCostCenterWorkingShifts", nextItems);
		} else {
			let response = null;

			try {
				const workingShiftId = formik.values.configCostCenterWorkingShifts[rowIndex].id;
				const payload = { "working-shift-id": workingShiftId };

				response = await api.post.costCenter.deleteWorkingShift(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			} finally {
				formik.setSubmitting(false);
			}

			if (response) {
				dispatch(promptLayoutAlertMessage({ message: "Working Shift was removed successfully!" }));

				onHandleGetDetails(id);

				onHandleGetWorkingShifts(id);
			}

			onHandleCloseMenu();
		}
	}, [isCreate, formik, onHandleCloseMenu, dispatch, id, onHandleGetDetails, onHandleGetWorkingShifts]);

	const onHandleMenuOpen = useCallback((event) => {
		setAnchorEl(event.currentTarget);
	}, []);

	const onHandleMenuClose = () => {
		setAnchorEl(null);
	};

	const renderMenuContent = useCallback(() => {
		const enabledContent = "= Allows employee to clock in from any location.";
		const disabledContent = "= Employee must clock in from Cost Centre location.";

		return (
			<Fragment>
				<div className="location-menu__menu-item">
					<span>Enabled</span>

					{enabledContent}
				</div>

				<div className="location-menu__menu-item">
					<span>Disabled</span>

					{disabledContent}
				</div>
			</Fragment>
		);
	}, []);

	const onHandleFetchStates = useCallback(async () => {
		try {
			const states = await getStateListing();

			const stateValues = states.content.map((state) => state.value);

			setStateListing(stateValues);
		} catch (error) {
			console.error("Error fetching states:", error);
		}
	}, []);

	useEffect(() => {
		onHandleFetchStates();
	}, [onHandleFetchStates]);

	// prettier-ignore
	const onHandleSetStateListing = useCallback((event) => {
		let selectedValues = [];

		if (event.target.value.includes("All")) {
			const isCurrentlyAllSelected = formik.values.configCostCenterStates.length === stateListing.length;

			selectedValues = isCurrentlyAllSelected ? [] : stateListing;
		} else {
			selectedValues = event.target.value;
		}

		formik.setFieldValue("configCostCenterStates", selectedValues);
	}, [stateListing, formik]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const stateObjects = values.originalStateData || [];
			const workingDayObjects = values.originalWorkingDaysData || [];

			const stateMap = {};
            stateObjects.forEach(obj => {
                stateMap[obj.value] = obj;
            });

			const workingDayMap = {};
            workingDayObjects.forEach(obj => {
                workingDayMap[obj.value] = obj;
            });

			const payload = {
				name: values.costCentreName,
				latitudeAndLongitude: values.latitudeAndLongitude,
				openLocation: values.openLocation,
				status: values.status,
				configCostCenterStates: values.configCostCenterStates.map(stateValue => ({
                    id: stateMap[stateValue]?.id || null,
                    label: stateValue,
                    value: stateValue
                })),
				configCostCenterWorkingDays: values.configCostCenterWorkingDays.map(dayValue => ({
					id: workingDayMap[dayValue]?.id || null,
					label: dayValue,
					value: dayValue
                })),
				configCostCenterWorkingShifts: values.configCostCenterWorkingShifts.map(shift => ({
                    shiftName: shift.shiftName,
                    startTime: isCreate ? dayjs(shift.startTime).format(DATE_TIME.HH_MM) : shift.startTime,
                    endTime: isCreate ? dayjs(shift.endTime).format(DATE_TIME.HH_MM) : shift.endTime,
                    status: shift.status,
                    ...(shift.id && { id: shift.id })
                }))
			};

			if (isCreate) {
				response = await api.post.costCenter.createCostCenter(payload);
			}

			if (!isCreate) {
				payload.id = id;

				response = await api.post.costCenter.updateCostCenter(payload);
			}

			if (response) {
				if (isCreate) {
					dispatch(promptLayoutAlertMessage({ message: "Cost Centre was created successfully!" }));
				}

				if (!isCreate) {
					dispatch(promptLayoutAlertMessage({ message: "Cost Centre was updated successfully!" }));
				}

				navigate(pathnames.operations.generalConfiguration + "?tab=COST_CENTRE");
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}
	}, [navigate, dispatch, formik, id, isCreate]);

	useEffect(() => {
		if (!isCreate) onHandleGetDetails(id);

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.SPARE_PART_SUB_CATEGORY.SPARE_PART_SUB_CATEGORY);
		};
	}, [isCreate, id, memoCancelRequest, onHandleGetDetails]);

	const emptyState = useMemo(() => {
		if (formik.values.configCostCenterWorkingShifts.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Working Shifts Added.{" "}
							<span className="table__link" onClick={onHandleAddWorkingShiftItem}>
								Add Working Shift?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [formik.values.configCostCenterWorkingShifts.length, onHandleAddWorkingShiftItem, tableColumns.length]);

	return (
		<div className="page-cost-centre-create-edit">
			<div className="cost-centre-create-edit">
				<div className="cost-centre-create-edit__header">
					<h1 className="cost-centre-create-edit__title">{title}</h1>

					{!isCreate && (
						<p className="cost-centre-create-edit__last-update">
							<span>Last Updated By</span> {formik.values.lastModifiedBy}, {formik.values.lastModifiedDate}
						</p>
					)}
				</div>

				<form className="cost-centre-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="cost-centre-create-edit__container">
						<div className="cost-centre-create-edit__label">Details</div>

						<div className="cost-centre-create-edit__row">
							<div className="cost-centre-create-edit__column">
								{/* prettier-ignore */}
								<AppInput required name="costCentreName" label="Cost Centre Name" type="text" placeholder="Enter Cost Centre Name" value={formik.values.costCentreName} error={formik.errors.costCentreName} touched={formik.touched.costCentreName} onChange={formik.handleChange} />
							</div>

							{/* prettier-ignore */}
							<AppSelectInput searchable={false} required disabled={restricted} type="text" name="status" label="Status" placeholder="Select..." loadOptions={getUserStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<div className="cost-centre-create-edit__row">
							{/* prettier-ignore */}
							<AppInputMultiSelect searchable={false} selectAll={true} required disabled={restricted} type="text" name="configCostCenterStates" label="States" placeholder="Select..." loadOptions={getStateListing} value={formik.values.configCostCenterStates} error={formik.errors.configCostCenterStates} touched={formik.touched.configCostCenterStates} onChange={onHandleSetStateListing} />

							<AppInputMultiSelect searchable={false} required disabled={restricted} type="text" name="configCostCenterWorkingDays" label="Working Days(s)" placeholder="Select..." loadOptions={getWeekdaysListing} value={formik.values.configCostCenterWorkingDays} error={formik.errors.configCostCenterWorkingDays} touched={formik.touched.configCostCenterWorkingDays} onChange={formik.handleChange} />
						</div>

						<div className="cost-centre-create-edit__row">
							{/* prettier-ignore */}
							<AppInput required name="latitudeAndLongitude" label="Latitude and Longitude" type="text" placeholder="Enter Latitude and Longitude" value={formik.values.latitudeAndLongitude} error={formik.errors.latitudeAndLongitude} touched={formik.touched.latitudeAndLongitude} onChange={formik.handleChange} />

							<div className="cost-centre-create-edit__location-checkbox">
								<AppCheckbox name="openLocation" label="Open Location" onClick={onHandleOpenLocation} value={formik.values.openLocation} />

								<img src={faqIcon} alt="chevron icon" className="cost-centre-create-edit__location-checkbox--faq-icon" onClick={(event) => onHandleMenuOpen(event)} />
							</div>
						</div>
					</div>

					<div className="cost-centre-create-edit__container">
						<p className="cost-centre-create-edit__label">Working Shift</p>

						<div className="cost-centre-create-edit__table">
							{!!formik.values.configCostCenterWorkingShifts.length && <AppTableFilterHeader searchPlaceholder="Search by Shift Name" searchDefaultValue={defaultKeyword} restricted={restricted} onHandleSearch={onHandleSearchWorkingShifts} onHandleAdd={onHandleAddWorkingShiftItem} />}

							<AppTable data={configCostCenterWorkingShiftsTableData} columns={tableColumns} options={tableOptions} components={emptyState} />
						</div>
					</div>

					<div className="cost-centre-create-edit__container">
						<div className="cost-centre-create-edit__label">Team Member Listing</div>

						<div className="cost-centre-create-edit__table">
							<AppTableFilterHeader searchPlaceholder="Search by Name or Email" searchDefaultValue={searchTeamMember} restricted={restricted} onHandleSearch={onHandleSearchTeamMembers} hideAddButton={true} />

							<AppTable data={teamMemberData.content} columns={teamMemberColumns} options={teamMemberTableOptions} components={teamMemberEmptyState} />
						</div>
					</div>

					<div className="cost-centre-create-edit__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleCancel} />

						<AppButton type="submit" disabled={formik.isSubmitting || restricted} label={submitLabel} />
					</div>
				</form>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "working-shift-create-edit-table-menu" }} anchorEl={itemTableAnchor} open={!!itemTableAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleEditWorkingShiftItem}><img src={editIcon} alt="customer-edit" />Edit</MenuItem>

				<MenuItem onClick={onHandleConfirmRemoveWorkingShiftItem}><img src={trashIcon} alt="customer-transfer" />Remove</MenuItem>
			</Menu>

			<AppAddWorkingShiftModal ref={workingShiftAddItemModalRef} isCreateCustomer={isCreate} onConfirm={onHandleCreateItem} onHandleEditItem={onHandleEditItem} onHandleGetDetails={onHandleGetDetails} onHandleAddUpdatePic={onHandleAddUpdateWorkingShift} onHandleShowConfirmAccountModal={onHandleShowConfirmAccountModal} />

			<AppWorkingShiftsDeleteModal ref={workingShiftDeleteItemModalRef} onConfirm={onHandleRemoveWorkingShiftItem} />

			<AppCustomerConfirmMobileAccountModal ref={confirmMobileAccountModalRef} onConfirm={onHandleAddUpdateWorkingShift} onHandleAddEditCustomerItem={onHandleAddEditCustomerItem} />

			<Menu classes={{ root: "location-menu" }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onHandleMenuClose}>
				{renderMenuContent()}
			</Menu>
		</div>
	);
};

export default memo(PageCostCentreCreateEdit);
