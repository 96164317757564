import React, { useCallback, useMemo, useRef, useState } from "react";

import Menu from "@mui/material/Menu";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";

import api from "services/api";

import useBeforeUnload from "hooks/use-before-unload";

import serveRequestErrors from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import STATUS from "constants/status";
import SIGNATURE_TYPE from "constants/signature-type";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppWorkOrderServices from "components/pages/work-order/app-work-order-services";
import AppWorkOrderInventory from "components/pages/work-order/app-work-order-inventory";
import AppWorkOrderSiteDetails from "components/pages/work-order/app-work-order-site-details";
import AppWorkOrderWorkDetails from "components/pages/work-order/app-work-order-work-details";
import AppConfirmChangeWorkOrderStatusModal from "components/pages/work-order/app-confirm-change-work-order-status-modal";

import exportIcon from "assets/images/export-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";
import servicesIcon from "assets/images/pages/work-order/services-icon.svg";
import inventoryIcon from "assets/images/pages/work-order/inventory-icon.svg";
import siteDetailsIcon from "assets/images/pages/work-order/site-details-icon.svg";
import workDetailsIcon from "assets/images/pages/work-order/work-details-icon.svg";
import servicesActiveIcon from "assets/images/pages/work-order/services-active-icon.svg";
import inventoryActiveIcon from "assets/images/pages/work-order/inventory-active-icon.svg";
import siteDetailsActiveIcon from "assets/images/pages/work-order/site-details-active-icon.svg";
import workDetailsActiveIcon from "assets/images/pages/work-order/work-details-active-icon.svg";

const PageWorkOrderCreateEdit = () => {
	useBeforeUnload();
	const { id } = useParams();
	const tabRef = useRef();
	const [woInfo, setWoInfo] = useState({});
	const siteDetailsRef = useRef();
	const [menuAnchor, setMenuAnchor] = useState(null);
	const [states, setStates] = useState({ siteDetails: null, services: null, inventory: null, workDetails: null });
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Create Work Order" : "Edit Work Order"), [isCreate]);
	const confirmChangeStatusModalRef = useRef();
	const canReschedule = useMemo(() => woInfo.status === STATUS.OVERDUE || woInfo.status === STATUS.PENDING_RESCHEDULE, [woInfo]);
	const canCancel = useMemo(() => [STATUS.OVERDUE].includes(woInfo.status), [woInfo]);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.WORK_ORDER_INVENTORY], [profile]);
	const restricted = useMemo(() => !accessible?.view, [accessible]);
	
	const onHandleSetWoInfo = useCallback((data) => {
		setWoInfo(data);
	}, []);

	//prettier-ignore
	const tabs = useMemo(() => {
		const onHandleUpdateSiteDetails = (values) => {
			setStates((prev) => ({ ...prev, siteDetails: values }));

			if (values) tabRef.current.onHandleSelectTab(data[1]);
		};

		const onHandleUpdateServices = (values) => {
			setStates((prev) => ({ ...prev, services: values }));

			if (values && !isCreate) tabRef.current.onHandleSelectTab(data[1]);
		};

		const onHandleUpdateInventory = (values) => {
			setStates((prev) => ({ ...prev, inventory: values }));

			if (values && !isCreate) tabRef.current.onHandleSelectTab(data[1]);
		};

		const onHandleUpdateWorkDetails = (values) => {
			setStates((prev) => ({ ...prev, workDetails: values }));

			if (values && !isCreate) tabRef.current.onHandleSelectTab(data[1]);
		};

		const data = [{ label: "Site Details", name: "SITE_DETAILS", icon: siteDetailsIcon, activeIcon: siteDetailsActiveIcon, component: <AppWorkOrderSiteDetails ref={siteDetailsRef} values={states.siteDetails} onHandleSetWoInfo={onHandleSetWoInfo} onHandleTabSubmit={onHandleUpdateSiteDetails} />, accessible: true }];

		if (!isCreate) {
			data.push(
				{ label: "Services", name: "SERVICES", icon: servicesIcon, activeIcon: servicesActiveIcon, component: <AppWorkOrderServices values={states.services} siteName={woInfo.siteName} status={woInfo?.status} customerContractSiteAssetId={woInfo.customerContractSiteAssetId} onHandleTabSubmit={onHandleUpdateServices} />, accessible: true }, 
				{ label: "Inventory", name: "INVENTORY", icon: inventoryIcon, activeIcon: inventoryActiveIcon, component: <AppWorkOrderInventory values={states.inventory} status={woInfo?.status} onHandleTabSubmit={onHandleUpdateInventory} />, accessible: !restricted },
				{ label: "Work Details", name: "WORK_DETAILS", icon: workDetailsIcon, activeIcon: workDetailsActiveIcon, component: <AppWorkOrderWorkDetails values={states.workDetails} status={woInfo?.status} assetId={woInfo.assetId} onHandleTabSubmit={onHandleUpdateWorkDetails} />, accessible: true }
			);
		}

		return data;
	}, [states.siteDetails, states.services, states.inventory, states.workDetails, onHandleSetWoInfo, woInfo.siteName, woInfo?.status, woInfo.customerContractSiteAssetId, woInfo.assetId, isCreate, restricted]);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onHandleSetSignature = useCallback(() => {
		confirmChangeStatusModalRef.current.onHandleShow();
	}, []);

	const onHandleSetRescheduled = useCallback(() => {
		setMenuAnchor(null);

		confirmChangeStatusModalRef.current.onHandleShow(SIGNATURE_TYPE.RESCHEDULED_BY);
	}, []);

	const onHandleSetCancelled = useCallback(() => {
		setMenuAnchor(false);

		confirmChangeStatusModalRef.current.onHandleShow(SIGNATURE_TYPE.CANCELLED_BY);
	}, []);

	// TODO: When send for signature feature is ready
	// const onHandleSendForSignature = () => {
	// 	setMenuAnchor(false);
	// };

	const onHandleExportPDF = useCallback(async () => {
		let response = null;
		let fileName = "";

		try {
			const payload = { "work-order-id": id };

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("inline; filename=")?.[1]?.split(";")?.[0];

				if (fileName) return data;

				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));

					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			response = await api.post.workOrder.generateWorkOrderPdf(payload, { transformResponse });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	}, [id]);

	//prettier-ignore
	const HeaderLinkButton = useCallback((obj) => {
		if (!obj.isCreate)
			return (
				<div className="work-order-create-edit__header-button">
					{(obj.canCancel || obj.canReschedule) && <AppButton className="work-order-create-edit__action-button" type="button" label="Actions" icon={chevronIcon}  onClick={obj.onToggleFilterMenu} />}

					<AppButton type="button" label="Export as PDF" icon={exportIcon} onClick={obj.onHandleExportPDF} />
				</div>
			);
	}, []);

	return (
		<div className="page-work-order-create-edit">
			<div className="work-order-create-edit">
				<div className="work-order-create-edit__header">
					<h1 className="work-order-create-edit__title">{title}</h1>

					<HeaderLinkButton isCreate={isCreate} canReschedule={canReschedule} canCancel={canCancel} onToggleFilterMenu={onToggleFilterMenu} onHandleExportPDF={onHandleExportPDF} />
				</div>

				<div className="work-order-create-edit__body">
					{!isCreate && (
						<p className="work-order-create-edit__last-update">
							<span>Last Updated By </span> {woInfo.lastModifiedBy}, {woInfo.lastModifiedDate}
						</p>
					)}

					<AppTabs ref={tabRef} tabs={tabs} />
				</div>
			</div>

			<Menu classes={{ root: "work-order-create-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				{canReschedule && <MenuItem onClick={onHandleSetRescheduled}>Set As Rescheduled</MenuItem>}

				{canCancel && <MenuItem onClick={onHandleSetCancelled}>Set As Cancelled</MenuItem>}

				{/* <MenuItem onClick={onHandleSendForSignature}>Send for Signature</MenuItem> */}
			</Menu>

			<AppConfirmChangeWorkOrderStatusModal ref={confirmChangeStatusModalRef} onConfirm={onHandleSetSignature} onHandleGetDetails={siteDetailsRef.current?.onHandleGetDetails} />
		</div>
	);
};

export default PageWorkOrderCreateEdit;
