import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";

import closeIcon from "assets/images/close-icon.png";

const AppDeleteCustomerCostCentreModal = (props, ref) => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const isClone = useMemo(() => props.isClone, [props.isClone]);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT_PIC], [profile]);
	const restricted = useMemo(() => !accessible?.delete, [accessible]);
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(null);

	const onHandleConfirm = useCallback(async () => {
		if (isCreate || isClone) {
			props.onHandleRemoveContactCostCenter(data.id);
		} else {
			let response = null;

			const params = {
				// "contract-id": id,
				"contract-cost-center-id": data.id
			};

			try {
				await api.post.customer.deleteContractCostCentre(params);

				response = true;
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				props.onHandleRefreshPicTable(id);

				props.onHandleRefreshAddPicModal(id);

				dispatch(promptLayoutAlertMessage({ message: "Cost Centre was removed successfully!" }));
			}
		}

		setVisible(false);
	}, [id, data, dispatch, isCreate, props, isClone]);

	const onHandleShow = useCallback((values) => {
		setData(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-delete-customer-cost-centre-modal" }} open={visible}>
			<div className="delete-customer-cost-centre-modal">
				<button type="button" className="delete-customer-cost-centre-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="delete-customer-cost-centre-modal__title">Are you sure?</h1>

				<p className="delete-customer-cost-centre-modal__description">
					After clicking confirm, the Cost Centre <b>{data?.configCostCenter.name}</b> will be <span>removed</span> from this Contract.
				</p>

				<div className="delete-customer-cost-centre-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" disabled={restricted} label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppDeleteCustomerCostCentreModal));

AppDeleteCustomerCostCentreModal.propTypes = {
	ref: PropTypes.object.isRequired
};
